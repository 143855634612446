<template>
  <q-page id="relatorios">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoRelatorios"></sem-permissao>

        <div class="col-12 col-md-12 text-center" v-if="relatoriosCarregamento">
          <q-spinner color="blue-7" size="4em" />
        </div>

        <div class="col-12 text-right" v-if="hasVideo">
          <q-btn
            dense
            flat
            color="bg-primary text-blue-7"
            icon="ondemand_video"
            @click="modalIntro"
          >
            <template v-slot:default>
              <q-tooltip>Introdução aos Relatórios</q-tooltip>
            </template>
          </q-btn>
        </div>

        <div class="col-12 col-md-6" v-if="isPermissoRelatorios">
          <div v-show="!relatoriosCarregamento" class="row q-col-gutter-md">
            <div v-for="(grupo, index) in grupos" :key="index" class="col-12 col-lg-6">
              <q-card :id="'relatorioCard' + (index + 1)" class="full-height">
                <q-card-section class="q-pa-sm">
                  <q-list class="rounded-borders">
                    <q-item-label class="q-pa-sm">
                      <div class="q-table__title">{{ grupo.nome }}</div>
                    </q-item-label>
                    <q-item
                      dense
                      v-ripple
                      clickable
                      style="min-height: 40px"
                      :class="{
                        active_status: isRotaAtual(
                          '/relatorios/' + (grupo.gerencial ? 'gerenciais/' : '') + relatorio.id
                        ),
                        'q-px-sm': true,
                      }"
                      @click="
                        irParaRota(
                          '/relatorios/' + (grupo.gerencial ? 'gerenciais/' : '') + relatorio.id
                        )
                      "
                      v-for="relatorio in grupo.relatorios"
                      :key="relatorio.id"
                    >
                      <q-item-section>{{ relatorio.nome }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6" v-if="!relatoriosCarregamento">
          <div v-if="!$route.params.id && isPermissoRelatorios">
            <q-table
              id="ultimosRelatoriosExportados"
              dense
              title="Últimos Relatórios Exportados"
              :data="arquivos"
              :columns="columns"
              row-key="id"
              :loading="carregamentoHistorico"
              :no-data-label="'Nenhum relatório exportado.'"
              :no-results-label="'Nenhum resultado obtido'"
              :loading-label="'Carregando relatórios exportados'"
              hide-bottom
              virtual-scroll
              :pagination.sync="pagination"
              :rows-per-page-options="[0]"
            >
              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="nome" class="text-left">
                    {{ props.row.nome }}
                  </q-td>
                  <q-td key="created" class="text-center">
                    {{ props.row.created | formatDate }} às {{ props.row.created | formatTime }}
                  </q-td>
                  <q-td class="text-center">
                    <q-btn
                      @click="baixaArquivo(props.row.id)"
                      size="12px"
                      class="bg-primary text-white"
                      style="width: 75px"
                      icon="file_download"
                      :label="props.row.download"
                    />
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
          <div>
            <router-view />
          </div>
        </div>
      </div>

      <!-- Menu lateral -->
      <q-page-sticky position="top-right" :offset="[0, 90]">
        <q-btn
          id="relatoriosOpcoesAvancadas"
          :color="openOpcoesAvancadas ? 'red-7' : 'blue-7'"
          :icon="openOpcoesAvancadas ? 'close' : 'settings'"
          class="rotate-0"
          style="border-top-left-radius: 50px; border-bottom-left-radius: 50px"
          @click="openOpcoesAvancadas = !openOpcoesAvancadas"
        >
          <q-tooltip
            :content-class="
              openOpcoesAvancadas
                ? 'bg-negative text-white text-center'
                : 'bg-secondary text-white text-center'
            "
            >{{ openOpcoesAvancadas ? 'Fechar Opções Avançadas' : 'Opções Avançadas' }}</q-tooltip
          >
        </q-btn>
      </q-page-sticky>
      <q-drawer
        side="right"
        v-model="openOpcoesAvancadas"
        bordered
        :width="300"
        :breakpoint="500"
        behavior="default"
        content-class="bg-white"
      >
        <q-scroll-area class="fit">
          <div class="q-pa-sm">
            <div class="q-table__title">Opções Avançadas</div>

            <div>
              <q-list
                class="rounded-borders"
                style="max-width: 350px; margin-left: -10px; width: 300px"
              >
                <q-item-label header></q-item-label>

                <q-item id="relatoriosAgendados" clickable v-ripple @click.exact="openRelatoriosAgendas()">
                  <q-item-section>
                    <q-item-label lines="1">Relatórios Agendados</q-item-label>
                    <q-item-label caption>Agende envio de relatórios todo mês</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-scroll-area>
      </q-drawer>
    </div>

    <modal-relatorios-agendas />
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@/axios-auth';
import { Notify } from 'quasar';
import { bus } from '@/main';

export default {
  name: 'relatorios',
  meta: {
    title: 'DSB | Relatórios',
  },
  data() {
    return {
      arquivos: [],
      carregamentoHistorico: true,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [
        {
          name: 'nome',
          align: 'center',
          label: 'Nome',
          field: 'nome',
          sortable: false,
        },
        {
          name: 'created',
          align: 'center',
          label: 'Criado em',
          field: 'created',
          sortable: false,
        },
      ],
      openOpcoesAvancadas: false,
      hasVideo: false
    };
  },
  methods: {
    getHistoricoRelatorios() {
      this.arquivos = [];

      axios
        .get('/arquivos?tipo=relatorios')
        .then(res => {
          this.arquivos = this.arquivos.concat(res.data.arquivos);
          this.carregamentoHistorico = false;
        })
        .catch(error => {
          Notify.create({
            message: 'Não foi possivel listar histórico de relatórios.',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    baixaArquivo(id) {
      axios
        .get('/arquivos/get-url/' + id)
        .then(res => {
          window.location.href = res.data.downloadUrl;
        })
        .catch(error => {
          Notify.create({
            message: 'Não foi possivel listar histórico de relatórios.',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    isRotaAtual(path) {
      return path === this.$route.fullPath;
    },
    irParaRota(path) {
      if (this.isRotaAtual(path)) {
        return;
      }

      this.$router.push(path);
    },
    openRelatoriosAgendas() {
      this.$store.dispatch('controlDialogRelatoriosAgendas', true);
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "relatorios");

      if (!this.videosManuais.video_relatorios) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "relatorios");
    },
  },
  computed: {
    ...mapGetters([
      'relatorios',
      'relatoriosGerenciais',
      'relatoriosCarregamento',
      'isPermissoRelatorios',
      'videosManuais',
      'manuaisDisponiveis'
    ]),
    grupos() {
      const grupos = {};

      this.relatorios.forEach(relatorio => {
        const nomeGrupo =
          relatorio.origem && String(relatorio.origem).trim() ? relatorio.origem : 'Outros';

        if (!grupos[nomeGrupo]) {
          grupos[nomeGrupo] = {
            nome: nomeGrupo,
            relatorios: [],
            gerencial: false,
          };
        }

        grupos[nomeGrupo].relatorios.push(relatorio);
      });

      const gruposArray = Object.values(grupos).sort((grupo1, grupo2) => {
        return grupo1.nome.localeCompare(grupo2.nome);
      });

      gruposArray.push({
        nome: 'Relatórios Gerenciais',
        relatorios: this.relatoriosGerenciais,
        gerencial: true,
      });

      return gruposArray;
    },
  },
  beforeMount() {
    bus.$off('getHistoricoRelatorios');
  },
  mounted() {
    this.getHistoricoRelatorios();
    this.verificarPrimeiroVideo();
    this.$store.dispatch('relatorios');

    bus.$on('getHistoricoRelatorios', () => this.getHistoricoRelatorios());
  },
  destroyed() {
    bus.$off('getHistoricoRelatorios');
  },
};
</script>

<style lang="stylus"></style>
