var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"responsive",attrs:{"id":"cabecalhoCaixa"}},[_c('q-btn-group',{attrs:{"flat":"","square":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._l((_vm.tiposSaldos),function(key,val){return _c('div',{key:val},[(key.visible)?_c('q-btn',{class:_vm.acaoCaixa === key.tipo ? _vm.returnColor(key) : 'bg-white text-black',staticStyle:{"border-bottom-right-radius":"0px","border-bottom-left-radius":"0px"},attrs:{"flat":"","disable":_vm.isSaldoFinal,"id":_vm.getId(key.label),"label":key.label},on:{"click":function($event){return _vm.callAction(key)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v(_vm._s(_vm.returnTooltip(key.ordem)))])]},proxy:true}],null,true)}):_vm._e()],1)}),_c('q-btn',{staticClass:"bg-white text-black",attrs:{"flat":"","id":_vm.getId('Fechar Caixa'),"label":_vm.movcaixa.status === 'F' ? 'Reabrir Caixa' : 'Fechar Caixa',"disable":_vm.fechaCaixa || _vm.fechandoCaixa || _vm.movcaixa.status === 'E',"clickable":""},on:{"click":_vm.fecharCaixa},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('q-tooltip',[_vm._v("CTRL + SHIFT + 9")])]},proxy:true}])})]},proxy:true}])}),_c('div',{staticClass:"colors",class:{
      'bg-teal-5 text-white': _vm.acaoCaixa === 1,
      'bg-primary text-white': _vm.acaoCaixa === 2,
      'bg-deep-orange text-white': _vm.acaoCaixa === 3,
      'bg-orange text-white': _vm.acaoCaixa === 4,
      'bg-green-5 text-white': _vm.acaoCaixa === 5,
      'bg-cyan text-white': _vm.acaoCaixa === 6,
      'bg-red-5 text-white': _vm.acaoCaixa === 7,
      'bg-grey-7 text-white': _vm.acaoCaixa === 8,
      'bg-lime-8 text-white': _vm.acaoCaixa === 10
    }}),(_vm.isModal.isModalCompras)?_c('modal-compra-produtos'):_vm._e(),(_vm.isModal.isModalRecebimentoFiado)?_c('modal-caixa-recebimento-fiado'):_vm._e(),(_vm.isModal.isModalRecebimentoFiadoMultiplo)?_c('modal-caixa-recebimento-fiado-multiplo'):_vm._e(),(_vm.isModal.isModalQuitacaoContas)?_c('modal-caixa-quitacao-contas'):_vm._e(),(_vm.isModal.isModalReforco)?_c('modal-caixa-reforco'):_vm._e(),(_vm.isModal.isModalSangria)?_c('modal-caixa-sangria'):_vm._e(),(_vm.isModal.isModalSaldoFinal)?_c('modal-caixa-saldo-final'):_vm._e(),(_vm.isModal.isModalVenda)?_c('modal-caixa-venda'):_vm._e(),_c('modal-caixa-venda-detalhada'),(_vm.isModal.isModalSaldoInicial)?_c('modal-caixa-saldo-inicial'):_vm._e(),_c('modal-caixa-venda-qrcode'),_c('modal-caixa-compra-detalhada')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }