<template>
  <div id="reguaCobrancasParametros">
    <q-table
      title
      :data="reguaDeCobranca"
      :columns="columns"
      row-key="name"
      :separator="separator"
      :loading="loadContas"
      no-data-label="Nenhum resultado encontrado."
       :pagination.sync="pagination"
    >
      <template v-slot:bottom>
        <div></div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="tipo" class="text-center">{{printTipo(props.row.tipo)}}</q-td>
          <q-td key="quando" class="text-center">{{props.row.quando}}</q-td>
          <q-td key="unidade" class="text-center">{{props.row.unidade}}</q-td>
          <q-td key="horario" class="text-center">{{ `0${props.row.horario}`.substr(-2) }}:00</q-td>
          <q-td key="titulo">{{props.row.descricao}}</q-td>
          <q-td key="created" class="text-center">{{props.row.created | formatDate}}</q-td>
          <q-td class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="exclui(props.row)"
                  size="12px"
                  class="bg-negative text-white"
                  icon="delete_forever"
                  :loading="excluindo"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Dialog, Notify } from "quasar";
import axios from "@/axios-auth";
export default {
  name: "regua-cobranca-parametros",
  data() {
    return {
      separator: "horizontal",
      pagination: {
        rowsPerPage: 0
      },
      columns: [
        {
          name: "tipo",
          required: true,
          label: "Tipo",
          align: "center",
          field: row => row.tipo,
          sortable: true
        },
        {
          name: "quando",
          required: true,
          label: "Quando",
          align: "center",
          field: row => row.quando,
          sortable: true
        },
        {
          name: "unidade",
          label: "Unidade",
          field: row => row.unidade,
          sortable: true,
          align: "center"
        },
        {
          name: "horario",
          label: "Horário",
          field: row => row.horario,
          sortable: true,
          align: "center"
        },
        {
          name: "descricao",
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "created",
          label: "Criado em",
          field: row => row.created,
          sortable: true,
          align: "center"
        }
      ],
      loadContas: false,
      excluindo: false
    };
  },
  methods: {
    printTipo(tipo) {
      let text;
      switch (tipo) {
        case 1:
          text = "Email";
          break;
        case 2:
          text = "WhatsApp";
          break;
        case 3:
          text = "Webhook";
          break;
        default:
          text = "";
      }
      return text;
    },
    exclui(value) {
      Dialog.create({
        title: "Tem certeza que deseja excluir esta régua de mensagens",
        html: true,
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {;
        this.excluindo = true;

        axios.post('/regua-cobrancas/delete/' + value.id)
          .then(res => {
            if (res.data.success) {
              Notify.create({
                message: res.data.message,
                color: "green",
                position: "top-right"
              });
            } else {
              Notify.create({
                message: res.data.message,
                color: "orange",
                position: "top-right"
              });
            }
            this.excluindo = false;
            this.$store.dispatch("reguaDeCobranca");
          })
          .catch(() => {
              this.excluindo = false;
              Notify.create({
                message: 'Não foi possível excluir a régua de cobrança, ou você não possui permissão para isto!',
                color: "orange",
                position: "top-right"
              });
          });
      });
    }
  },
  computed: {
    ...mapGetters(["reguaDeCobranca", "valueQuando"])
  },
  mounted() {
    this.$store.dispatch("reguaDeCobranca");
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
