<template>
  <div id="cabecalhoCaixa" class="responsive">
    <q-btn-group flat square>
      <template v-slot:default>
        <div v-for="(key, val) in tiposSaldos" :key="val">
          <q-btn
            flat
            v-if="key.visible"
            :disable="isSaldoFinal"
            :id="getId(key.label)"
            :label="key.label"
            @click="callAction(key)"
            :class="
              acaoCaixa === key.tipo ? returnColor(key) : 'bg-white text-black'
            "
            style="border-bottom-right-radius: 0px;border-bottom-left-radius: 0px"
          >
            <template v-slot:default>
              <q-tooltip>{{ returnTooltip(key.ordem) }}</q-tooltip>
            </template>
          </q-btn>
        </div>
        <q-btn
          flat
          class="bg-white text-black"
          :id="getId('Fechar Caixa')"
          :label="movcaixa.status === 'F' ? 'Reabrir Caixa' : 'Fechar Caixa'"
          :disable="fechaCaixa || fechandoCaixa || movcaixa.status === 'E' "
          clickable
          @click="fecharCaixa"
        >
          <template v-slot:default>
            <q-tooltip>CTRL + SHIFT + 9</q-tooltip>
          </template>
        </q-btn>
      </template>
    </q-btn-group>
    <div
      class="colors"
      :class="{
        'bg-teal-5 text-white': acaoCaixa === 1,
        'bg-primary text-white': acaoCaixa === 2,
        'bg-deep-orange text-white': acaoCaixa === 3,
        'bg-orange text-white': acaoCaixa === 4,
        'bg-green-5 text-white': acaoCaixa === 5,
        'bg-cyan text-white': acaoCaixa === 6,
        'bg-red-5 text-white': acaoCaixa === 7,
        'bg-grey-7 text-white': acaoCaixa === 8,
        'bg-lime-8 text-white': acaoCaixa === 10
      }"
    ></div>
    <modal-compra-produtos
      v-if="isModal.isModalCompras"
    ></modal-compra-produtos>
    <modal-caixa-recebimento-fiado
      v-if="isModal.isModalRecebimentoFiado"
    ></modal-caixa-recebimento-fiado>
    <modal-caixa-recebimento-fiado-multiplo
      v-if="isModal.isModalRecebimentoFiadoMultiplo" >
    </modal-caixa-recebimento-fiado-multiplo>
    <modal-caixa-quitacao-contas
      v-if="isModal.isModalQuitacaoContas"
    ></modal-caixa-quitacao-contas>
    <modal-caixa-reforco v-if="isModal.isModalReforco"></modal-caixa-reforco>
    <modal-caixa-sangria v-if="isModal.isModalSangria"></modal-caixa-sangria>
    <modal-caixa-saldo-final
      v-if="isModal.isModalSaldoFinal"
    ></modal-caixa-saldo-final>
    <modal-caixa-venda v-if="isModal.isModalVenda"></modal-caixa-venda>
    <modal-caixa-venda-detalhada />
    <modal-caixa-saldo-inicial
      v-if="isModal.isModalSaldoInicial"
    ></modal-caixa-saldo-inicial>
    <modal-caixa-venda-qrcode />
    <modal-caixa-compra-detalhada />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "cabecalho-caixa",
  methods: {
    fecharCaixa() {
      if (this.movcaixa.status === "A") {
        this.$store.dispatch("fecharCaixa");
      } else {
        this.$store.dispatch("reativarCaixa", this.movcaixa);
      }
    },
    newCadastro() {
      this.$store.dispatch("cadastro");
    },
    newReceita() {
      this.$store.dispatch("modalReceitas");
    },
    newDespesa() {
      this.$store.commit("callDespesa", true);
      this.$store.dispatch("modalReceitas");
    },
    callAction(value) {
      this.$store.commit("acaoCaixa", value.tipo);
      switch (value.ordem) {
        case 1:
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 49
          });
          break;
        case 2:
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 50
          });
          break;
        case 3:
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 51
          });
          break;
        case 4:
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 52
          });
          break;
        case 5:
          if(this.dataEmpresa.assinatura.plano === "Essencial"){
            this.$store.dispatch("toggleUpgradePlano", true);
            return;
          }
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 53
          });
          break;
        case 7:
          if(this.dataEmpresa.assinatura.plano === "Essencial"){
            this.$store.dispatch("toggleUpgradePlano", true);
            return;
          }
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 54
          });
          break;
        case 8:
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 55
          });
          break;
        case 9:
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 56
          });
          break;
        case 10:
          this.$store.dispatch("selecionaTecla", {
            shift: true,
            ctrl: true,
            tecla: 57
          });
          break;
      }
    },
    returnColor(color) {
      if (color.tipo === 1) {
        return "bg-teal-5 text-white";
      } else if (color.tipo === 2) {
        return "bg-primary text-white";
      } else if (color.tipo === 3) {
        return "bg-deep-orange text-white";
      } else if (color.tipo === 4) {
        return "bg-orange text-white";
      } else if (color.tipo === 5) {
        return "bg-green-5 text-white";
      } else if (color.tipo === 6) {
        return "bg-cyan text-white";
      } else if (color.tipo === 7) {
        return "bg-red-5 text-white";
      } else if (color.tipo === 8) {
        return "bg-grey-7 text-white";
      } else if (color.tipo === 10) {
        return "bg-lime-7 text-white";
      }
    },
    returnTooltip(tipo) {
      let value = "";
      switch (tipo) {
        case 1:
          value = "Ctrl + Shift + 1";
          break;
        case 2:
          value = "Ctrl + Shift + 2";
          break;
        case 3:
          value = "Ctrl + Shift + 3";
          break;
        case 4:
          value = "Ctrl + Shift + 4";
          break;
        case 5:
          value = "Ctrl + Shift + 5";
          break;
        case 6:
          value = "Ctrl + Shift + 5";
          break;
        case 7:
          value = "Ctrl + Shift + 6";
          break;
        case 8:
          value = "Ctrl + Shift + 7";
          break;
        case 9:
          value = "Ctrl + Shift + 8";
          break;
      }
      return value;
    },
    // Retorna a label em camelCase
    getId(label) {
      const withoutAccents = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const cleanedStr = withoutAccents.replace(/[^a-zA-Z\s]/g, '');
      const words = cleanedStr.trim().split(/\s+/);

      return words.map((word, index) => {
        return index === 0 
          ? word.toLowerCase() 
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join('');
    }
  },
  computed: {
    ...mapGetters([
      "dataEmpresa",
      "tiposSaldos",
      "isSaldoFinal",
      "acaoCaixa",
      "isSaldoInicial",
      "movcaixa",
      "fechaCaixa",
      "isModal",
      "fechandoCaixa"
    ])
  }
};
</script>

<style lang="stylus" scoped>
.colors {
  height: 8px;
  width: 100%;
}
.responsive{
  overflow: auto;
}
</style>
