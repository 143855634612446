import Vue from "vue";

Vue.mixin({
  data() {
    return {
      langDate: {
        days: "Domingo_Segunda-Feira_terça-Feira_Quarta-Feira_Quinta-Feira_Sexta_Sábado".split(
          "_"
        ),
        daysShort: "Dom_Seg_Ter_Qua_Qui_Sex_Sáb".split("_"),
        months: "Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro".split(
          "_"
        ),
        monthsShort: "Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez".split(
          "_"
        ),
        firstDayOfWeek: 1
      },
      estadosShort: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO"
      ],
      tiposRegua: [
        "Email",
        "WhatsApp",
        "Webhook"
      ],
      origemWpp:[
        { descricao: "Cobranças", tipo: 'movcontas' },
        { descricao: "Cadastros", tipo: 'cadastros' },
        { descricao: "Agenda", tipo: 'calendario' },
        { descricao: "Cobranças Liquidados", tipo: 'faturas-liquidadas' },
      ],
      origemEmail:[
        { descricao: "Boleto, Pix em Aberto", tipo: 'boleto' },
        { descricao: "Boleto, Pix Liquidados", tipo: 'faturas-liquidadas' },
        { descricao: "Cartão em Aberto", tipo: 'cartao' },
      ],
      unidadeTempo: [
        "Dias"
      ],
      unidadesTempo: [
        { descricao: "Dias", value: "dias" },
        { descricao: "Horas",value: "horas" },
        { descricao: "Minutos",value: "minutos" }
      ],
      mesShort: [
        "Todos",
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ]
    };
  }
});
