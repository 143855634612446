<template>
  <div id="searchGlobal">
    <q-input
      rounded
      class="GNL__toolbar-input col col-md-3"
      outlined
      dense
      v-model="busca"
      color="bg-white"
      :placeholder="getPlaceholder"
      v-on:keyup.enter="buscar()"
      @input="onChange"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      autocomplete="off"
      style="width: 350px"
    >
      <template v-slot:prepend>
        <q-icon v-if="busca === '' && !isLoading" name="search" />
        <q-icon
          v-else-if="busca && busca.length > 0 && !isLoading"
          name="clear"
          class="cursor-pointer"
          @click="busca = '', limpaBuscar()"
        />
        <q-spinner v-if="isLoading" color="grey-5" size=".9em" />
      </template>
    </q-input>
    <input style="display:none" type="search" name="fakesearchremembered" />
    <div
      style="position: absolute; width: 350px; max-height: 600px; top: 64px;overflow: auto"
      class="bg-white text-black shadow-7 style_scroll"
    >
      <div v-for="(item, nome) in autocompleteFound" :key="nome">
        <q-list
          bordered
          separator
          v-show="isOpen"
          v-if="autocompleteFound[nome] && autocompleteFound[nome].length > 0"
          class="text-white"
          :class="{'bg-blue-5': nome === 'Cadastros', 'bg-green-5': nome === 'Receitas', 'bg-orange-5': nome === 'Despesas', 'bg-purple-5': nome === 'Licenças', 'bg-indigo-5': nome === 'Caixas'}"
        >
          <q-item-label header class="text-white">{{nome}}</q-item-label>
          <q-item
            :class="{'is-active': i === arrowCounter}"
            clickable
            v-ripple
            v-for="(result, i) in item"
            :key="i"
          >
            <q-item-section
              @click="setResult(result, nome)"
              v-html="formattedDisplay(result, nome)"
            ></q-item-section>
            <q-item-section side top v-if = 'nome === "Cadastros" && !result.ativo'>
              <q-badge color="red">Inativo</q-badge>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios-auth";
const moment = require("moment");
import { bus } from "@/main";

export default {
  name: "search-global",
  data() {
    return {
      busca: "",
      isOpen: false,
      arrowCounter: -1,
      isLoading: false,
      autocompleteFound: {
        Cadastros: [],
        Receitas: [],
        Despesas: [],
        Comissoes: [],
        Licenças: [],
        Caixas: []
      },
      timer: null
    };
  },
  methods: {
    formattedDisplay(result, nome) {
      switch (nome) {
        case "Cadastros":
          return (
            "<strong>" + result.id + "</strong>" + this.returnName(result.nome)
          );
          // eslint-disable-next-line
          break;
        case "Receitas":
          return (
            "<strong>" +
            result.id +
            "</strong>" +
            result.cadastro.nome +
            "<strong>" +
            this.formatValor(result.valor) +
            "</strong>"
          );
          // eslint-disable-next-line
          break;
        case "Despesas":
          return (
            "<strong>" +
            result.id +
            "</strong>" +
            result.cadastro.nome +
            "Valor  " +
            "<strong>" +
            this.formatValor(result.valor) +
            "</strong>"
          );
          // eslint-disable-next-line
          break;
        case "Comissoes":
          return (
            "<strong>" +
            result.id +
            "</strong>" +
            result.cadastro.nome +
            "Valor  " +
            "<strong>" +
            this.formatValor(result.valor) +
            "</strong>"
          );
          // eslint-disable-next-line
          break;
        case "Licenças":
          return (
            "<span style='font-weight: bold'>" +
            result.id +
            "</span>" +
            result.cadastro.nome +
            "Plano  " +
            "<strong>" +
            result.plano +
            "</strong>" +
            "Expiração  " +
            "<strong>" +
            this.formatDate(result.bloquear_em) +
            "</strong>"
          );
          // eslint-disable-next-line
          break;
        case "Caixas":
          return (
            "<strong>" +
            result.id +
            "</strong>" +
            result.caixa.nome +
            " Número  " +
            result.caixa.numero
          );
          // eslint-disable-next-line
          break;
      }
    },
    returnName(value) {
      if (value.length > 45) {
        return value.substring(0, 45) + "...";
      }
      return value;
    },
    formatValor(value) {
      if (!value) return "R$ 0,00";
      var val = (value / 1).toFixed(2).replace(".", ",");
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(value) {
      if (!value) return "";
      return moment.utc(value).format("DD/MM/YYYY");
    },
    onArrowDown() {
      const route = this.$route.path;
      if (
        route === "/cadastros" ||
        route === "/despesas" ||
        route === "/comissoes" ||
        route === "/receitas" ||
        route === "/licencas"
      )
        return;
      if (this.arrowCounter < this.autocompleteFound.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      const route = this.$route.path;
      if (
        route === "/cadastros" ||
        route === "/despesas" ||
        route === "/comissoes" ||
        route === "/receitas" ||
        route === "/licencas"
      )
        return;
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      const route = this.$route.path;
      if (
        route === "/cadastros" ||
        route === "/despesas" ||
        route === "/comissoes" ||
        route === "/receitas" ||
        route === "/licencas"
      ) {
        if (route === '/cadastros') {
          this.$store.dispatch('resetPag');
          this.$store.commit("filtrosCadastros", {
            ativo: this.filtrosCadastros.ativo,
            cliente: this.filtrosCadastros.cliente,
            funcionario: this.filtrosCadastros.funcionario,
            fornecedor: this.filtrosCadastros.fornecedor,
            dependente: this.filtrosCadastros.dependente,
            busca: this.busca,
            page: 0
          });
          this.$store.dispatch('listarCadastros');
        }
      }else {
        this.setResult(this.autocompleteFound[this.arrowCounter]);
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onChange() {
      const route = this.$route.path;

      //Caso esteja em uma das rotas a seguir
      //realiza a busca das proprias rotas
      if (
        route === "/cadastros" ||
        route === "/despesas" ||
        route === "/comissoes" ||
        route === "/receitas" ||
        route === "/licencas" ||
        route === "/notas-fiscais" ||
        route === "/despesas-recorrentes" ||
        route === "/assinaturas"
      ){
        return;
      }

      //caso contrário realiza a busca global
      this.$emit("input", this.busca);

      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.searchResult();
      }, 400);
    },
    setResult(result, nome) {
      switch (nome) {
        case "Cadastros":
          this.$router.push("/cadastros/" + result.id);
          break;
        case "Receitas":
          this.filtrosReceitas.busca = result.id;
          this.$router.push("/receitas");
          this.$store.dispatch("filtrosReceitas", this.filtrosReceitas);
          break;
        case "Despesas":
          this.filtrosDespesas.busca = result.id;
          this.$router.push("/despesas");
          this.$store.dispatch("filtrosDespesas", this.filtrosDespesas);
          break;
        case "Comissoes":
          this.filtrosComissoes.busca = result.id;
          this.$router.push("/comissoes");
          this.$store.dispatch("filtrosComissoes", this.filtrosComissoes);
          break;
        case "Licenças":
          this.filtrosLicencas.cadastro_id = result.cadastro.id;
          this.$router.push("/licencas");
          this.$store.dispatch("dadosLicencas", this.filtrosLicencas);
          break;
        case "Caixas":
          this.filtrosCaixas.busca = result.numero;
          this.$router.push("/caixas");
          this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
          break;
      }

      this.$emit("searchFinished");
    },
    searchResult() {
      if (this.busca.length < 3 && this.busca.length > 0) return;
      if (this.busca.length === 0) {
        this.limpaBuscar();
        return;
      }
      this.isLoading = true;
      axios
        .get("/busca?s=" + this.busca)
        .then(res => {
          if (res.data.status) {
            this.autocompleteFound["Receitas"] = res.data["Receitas"]
              ? res.data["Receitas"]
              : null;
            this.autocompleteFound["Despesas"] = res.data["Despesas"]
              ? res.data["Despesas"]
              : null;
            this.autocompleteFound["Comissoes"] = res.data["Comissoes"]
              ? res.data["Comissoes"]
              : null;
            this.autocompleteFound["Cadastros"] = res.data["Cadastros"]
              ? res.data["Cadastros"]
              : null;
            this.autocompleteFound["Caixas"] = res.data["Caixas"]
              ? res.data["Caixas"]
              : null;
            this.autocompleteFound["Licenças"] = res.data["Licenças"]
              ? res.data["Licenças"]
              : null;
            this.isOpen = true;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.autocompleteFound["Receitas"] = [];
          this.autocompleteFound["Despesas"] = [];
          this.autocompleteFound["Comissoes"] = [];
          this.autocompleteFound["Cadastros"] = [];
          this.autocompleteFound["Caixas"] = [];
          this.autocompleteFound["Licenças"] = [];
          this.isOpen = true;
          this.isLoading = false;
        });
    },
    buscar() {
      let route = this.$route.path;
      if (
        route !== "/cadastros" ||
        route !== "/despesas" ||
        route !== "/comissoes" ||
        route !== "/receitas" ||
        route !== "/licencas" ||
        route !== "/notas-fiscais" ||
        route !== "/despesas-recorrentes" ||
        route !== "/assinaturas"
      )
        switch (route) {
          case "/licencas":
            this.filtrosLicencas.busca = this.busca;
            this.$router.push("/licencas");
            this.$store.dispatch("dadosLicencas", this.filtrosLicencas);
            break;
          case "/despesas":
            this.filtrosDespesas.busca = this.busca;
            this.$router.push("/despesas");
            this.$store.dispatch("filtrosDespesas", this.filtrosDespesas);
            break;
          case "/comissoes":
            this.filtrosComissoes.busca = this.busca;
            //this.$router.push("/comissoes");
            this.$store.dispatch("filtrosComissoes", this.filtrosComissoes);
            break;
          case "/caixas":
            this.filtrosCaixas.busca = this.busca;
            this.$router.push("/caixas");
            this.$store.dispatch("filtrosCaixas", this.filtrosCaixas);
            break;
          case "/receitas":
            this.filtrosReceitas.busca = this.busca;
            this.$router.push("/receitas");
            this.$store.dispatch("filtrosReceitas", this.filtrosReceitas);
            break;
          case "/notas-fiscais":
            this.filtrosNotasFiscais.busca = this.busca;
            this.$router.push("/notas-fiscais");
            this.$store.dispatch("resetNotasFiscais");
            this.$store.dispatch("loadNotasFiscais");
            break;
          case "/despesas-recorrentes":
            bus.$emit("buscaDespesasRecorrencias", this.busca);
            break;
          case "/assinaturas":
            bus.$emit("buscaAssinaturas", this.busca);
            break;

          default:
            this.filtrosCadastros.busca = this.busca;
            this.$store.dispatch("filtrosCadastros", this.filtrosCadastros);
            this.$router.push("/cadastros");
            this.$store.dispatch('listarCadastros');
            break;
        }

        this.$emit("searchFinished");
    },
    limpaBuscar() {
      if (
        this.$route.path === "/receitas" ||
        this.$route.path === "/cadastros" ||
        this.$route.path === "/licencas" ||
        this.$route.path === "/despesas" ||
        this.$route.path === "/comissoes" ||
        this.$route.path === "/caixas" ||
        this.$route.path === "/notas-fiscais" ||
        this.$route.path === "/despesas-recorrentes" ||
        this.$route.path === "/assinaturas"
      ) {
        if (this.$route.path === "/licencas") {
          this.$store.commit("dadosLicencas", {
            page: 0,
            busca: null,
            ativo: "ativo",
            estado: null,
            uuid: null,
            cadastro_id: null,
            servico_id: null,
            version_id: null
          });
        }
        if (this.$route.path === "/cadastros") {
          this.$store.dispatch('resetBuscaCadastros');
          this.$store.dispatch('listarCadastros');
        }
        if (this.$route.path === "/notas-fiscais") {
          this.filtrosNotasFiscais.busca = '';
          this.$store.dispatch("resetNotasFiscais");
          this.$store.dispatch("loadNotasFiscais");
        }
        if (this.$route.path === "/despesas-recorrentes") {
          bus.$emit("buscaDespesasRecorrencias", '');
        }
        if (this.$route.path === "/assinaturas") {
          bus.$emit("buscaAssinaturas", '');
        }
        this.busca = "";
        this.buscar();
      } else {
        this.busca = "";
      }
      this.autocompleteFound = {
        Cadastros: [],
        Receitas: [],
        Despesas: [],
        Comissoes: [],
        Licenças: []
      };
      this.busca = "";
      this.isOpen = false;
      this.isLoading = false;
      this.arrowCounter = -1;
    },
    handleClickOutside(evt) {
      const route = this.$route.path;
      if (
        !this.$el.contains(evt.target) &&
        (route === "/cadastros" ||
          route === "/despesas" ||
          route === "/comissoes" ||
          route === "/receitas" ||
          route === "/licencas" ||
          route === "/caixas" ||
          route === "/notas-fiscais" ||
          route === "/despesas-recorrentes" ||
          route === "/assinaturas")
      ) {
        this.isOpen = false;
        this.arrowCounter = -1;
      } else if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
        this.limpaBuscar();
      }
    }
  },
  computed: {
    getPlaceholder() {
      return this.$route.path === "/receitas"
        ? "Busque em Receitas, ID..."
        : "Busque por Nome, ID...";
    },
    filtrosNotasFiscais: {
      get() {
        return this.$store.getters.filtrosNotasFiscais;
      },
      set(value) {
        this.$store.commit('filtrosNotasFiscais', value);
      }
    },
    filtrosCadastros() {
      return this.$store.getters.filtrosCadastros;
    },
    filtrosReceitas() {
      return this.$store.getters.filtrosReceitas;
    },
    filtrosLicencas() {
      return this.$store.getters.dadosLicencas;
    },
    filtrosDespesas() {
      return this.$store.getters.filtrosDespesas;
    },
    filtrosComissoes() {
      return this.$store.getters.filtrosComissoes;
    },
    filtrosCaixas() {
      return this.$store.getters.filtrosCaixas;
    }
  },
  watch: {
    '$route.path'(value) {
      this.busca = '';
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style lang="stylus" scoped>
.class380 {
  width: 380px !important;
}

.class100 {
  width: 100% !important;
  height: 65px !important;
  position: relative;
  top: -5px !important;
}

.resultsTitle.is-active {
  background-color: #4285f4;
  color: white;
}

.resultsBar {
  max-width: 100%;
  max-height: 650px;
  background-color: #fafafa;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
  z-index: 9999;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.resultsTitle1 {
  float: inherit;
  width: 100%;
}

.resultsTitle1 > a {
  color: black;
  cursor: pointer;
  line-height: 2.5rem;
  /* padding: 3px; */
  /* margin-left: 15px; */
}

.resultsTitle2 {
  float: inherit;
  width: 100%;
}

.resultsTitle2 > a {
  color: black;
  cursor: pointer;
  line-height: 2rem;
  /* padding: 3px; */
  /* margin-left: 15px; */
}

.resultsTitle1 > a:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

.resultsTitle2 > a:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

.titleResult {
  color: #616161;
  font-weight: bolder;
  margin-left: 10px;
  height: 20px;
  position: relative;
  top: -20px;
  padding: 5px;
}

.boxshadow {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.style_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  /* border-radius: 10px; */
}

.style_scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
  border-radius: 10px;
}

.style_scroll::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}
</style>
