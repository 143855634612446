<template>
  <q-page id="cofre-inteligente">
    <div class="q-pa-md">

      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoCofreInteligente"></sem-permissao>
        <div class="col-12" v-else>
          <q-table
            title="Cofre Inteligente"
            :data="cofreDepositos"
            :columns="columns"
            row-key="id"
            :loading="loadingCofreDepositos"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
          >
            <template v-slot:bottom></template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td align="center" key="importado">
                  <q-icon
                    name="check"
                    color="green"
                    size="sm"
                    v-if="props.row.importado"
                  />
                  <q-icon
                    name="close"
                    color="red"
                    size="sm"
                    v-else
                  />
                </q-td>
                <q-td align="left" key="cadastro">
                  <span v-if="props.row.cadastro" class="cadastro-link">
                    <router-link :to="'/cadastros/' + props.row.cadastro.id">
                      {{ props.row.cadastro.nome }}
                    </router-link>
                  </span>
                  <span v-else>
                    {{ props.row.id_usuario }}
                  </span>
                </q-td>
                <q-td align="left" key="data">
                  {{ new Date(props.row.data) | formatDateTime }}
                  <br>{{ props.row.numero_transacao }}
                </q-td>
                <q-td align="right" key="descricao_evento">
                  <div v-if="props.row.detalhamento" v-html="breakLineBar(props.row.detalhamento)">
                  </div>
                </q-td>
                <q-td align="right" key="total_manual">
                  R$ {{ props.row.total_automatico + props.row.total_manual | currency }}
                </q-td>
                <q-td class="tdfora" style="width: 10px;">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="detalhar(props.row)"
                        size="12px"
                        class="bg-primary text-white"
                        icon="info"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top>
              <div class="q-table__title">Cofre Inteligente</div>

              <q-badge v-if="opcoes !== null" class="q-ml-sm q-mb-md" rounded :color="colorCofreStatus">
                <q-tooltip >
                  <span
                    v-if="opcoes.cofreinteligente_message !== ''"
                  >
                    {{ opcoes.cofreinteligente_message }} <br />
                  </span>
                  Última sicronização: {{ opcoes.cofreinteligente_ultimasicronizacao }}
                </q-tooltip>
              </q-badge>

              <q-space />

              <div id="cofresFiltros" class="relative-position row items-center">
                <q-btn
                  flat
                  dense
                  no-wrap
                  v-ripple
                  color="primary"
                  no-caps
                  :label="filtroImportadoLabel"
                  class="q-ml-sm q-px-md bg-white text-blue-7"
                  >
                  <q-menu anchor="top right" self="top right">
                    <q-list class="text-grey-8" style="min-width: 100px">
                      <q-item
                        :class="{'active_status': filtrosCofreDepositos.importado === ''}"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        @click="handleImportadoFiltro()"
                      >
                        <q-item-section>Todos</q-item-section>
                      </q-item>
                      <q-item
                        :class="{'active_status': filtrosCofreDepositos.importado === 'true'}"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        @click="handleImportadoFiltro('true')"
                      >
                        <q-item-section>Importados</q-item-section>
                      </q-item>
                      <q-item
                        :class="{'active_status': filtrosCofreDepositos.importado === 'false'}"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        @click="handleImportadoFiltro('false')"
                      >
                        <q-item-section>Não Importados</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
                <q-btn
                  flat
                  dense
                  no-wrap
                  v-ripple
                  color="primary"
                  no-caps
                  :label="funcionarioSelecionado === null ? 'Funcionário' : funcionarioSelecionado.nome"
                  class="q-ml-sm q-px-md bg-white text-blue-7"
                  style="margin-right: 10px"
                  >
                  <q-menu anchor="top right" self="top right">
                    <q-list class="text-grey-8" style="min-width: 100px">
                      <q-item
                        v-for="funcionario in funcionarios"
                        :key="funcionario.id"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        :class="{'active_status': funcionarioSelecionado && funcionarioSelecionado.id === funcionario.id}"
                        @click="handleFuncionarioFiltro(funcionario)"
                      >
                        <q-item-section>{{ funcionario.nome }}</q-item-section>
                      </q-item>
                      <q-item
                        :class="{'active_status': funcionarioSelecionado === null}"
                        @click="handleFuncionarioFiltro()"
                        v-close-popup
                        clickable
                        aria-hidden="true"
                      >
                        <q-item-section>Todos</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
                <date-picker-cofre-deposito />
              </div>

              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                icon="ondemand_video"
                @click="modalIntro"
              >
                <template v-slot:default>
                  <q-tooltip>Introdução ao Mapa de Resgates</q-tooltip>
                </template>
              </q-btn>
            </template>
          </q-table>

          <div class="text-center q-mt-lg" v-if="loadingCofreDepositos">
            <q-spinner color="blue-7" size="3em" />
          </div>

          <div>
            <q-page-sticky position="top-right" :offset="[0, 90]">
              <q-btn
                id="cofresOpcoesAvancadas"
                :color="openMenuLateral ? 'red-7' : 'blue-7'"
                :icon="openMenuLateral ? 'close' : 'settings'"
                class="rotate-0"
                style="border-top-left-radius: 50px;border-bottom-left-radius: 50px;"
                @click="openMenuLateral = !openMenuLateral"
              >
                <q-tooltip
                  :content-class="
                    openMenuLateral
                      ? 'bg-negative text-white text-center'
                      : 'bg-secondary text-white text-center'
                  "
                  >{{
                    openMenuLateral ? "Fechar Opções Avançadas" : "Opções Avançadas"
                  }}</q-tooltip
                >
              </q-btn>
            </q-page-sticky>

            <q-drawer
              side="right"
              v-model="openMenuLateral"
              bordered
              :width="300"
              :breakpoint="500"
              behavior="default"
              content-class="bg-white"
            >
              <q-scroll-area class="fit">
                <div class="q-pa-sm">
                  <div class="q-table__title">Opções Avançadas</div>
                  <div>
                    <q-list
                      class="rounded-borders"
                      style="max-width: 350px; margin-left: -10px;width: 300px;"
                    >
                      <q-item-label header></q-item-label>

                      <q-item clickable v-ripple @click.exact="abrirOpcoes()">
                        <q-item-section>
                          <q-item-label lines="1">Opções</q-item-label>
                          <q-item-label caption>Configurações do cofre inteligente</q-item-label>
                        </q-item-section>
                      </q-item>

                    </q-list>
                  </div>
                </div>
              </q-scroll-area>
            </q-drawer>
          </div>

        </div>
      </div>
    </div>

    <modal-cofre-inteligente-opcoes @loadOpcoes="loadOpcoes" />
    <modal-detalhar-cofre-deposito />
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '@/axios-auth';

export default {
  name: "cofre-inteligente",
  meta: {
    title: "DSB | Cofre Inteligente",
  },
  data() {
    return {
      openMenuLateral: false,
      columns: [
        {
          name: "importado",
          label: "No caixa",
          align: "center",
          field: row => row.importado,
          sortable: true,
        },
        {
          name: "cadastro",
          label: "Cadastro",
          align: "center",
          field: row => row.cadastro_id,
          sortable: true,
        },
        {
          name: "data",
          label: "Data",
          align: "center",
          field: row => row.data,
          sortable: true,
        },
        {
          name: "descricao_evento",
          label: "Detalhamento",
          align: "center",
          field: row => row.descricao_evento,
          sortable: true,
        },
        {
          name: "total_manual",
          label: "Total",
          align: "center",
          field: row => row.total_manual,
          sortable: true,
        },
        { name: "tdFora" },
      ],
      pagination: {
        sortBy: "data",
        descending: true,
        rowsPerPage: 0,
        page: 1,
      },
      funcionarios: [],
      funcionarioSelecionado: null,
      hasVideo: false,
      opcoes: null
    };
  },
  methods: {
    ...mapActions([
      "toggleDialogOpcoesCofreInteligente",
      "toggleDialogDetalharCofreDeposito",
      "updateFiltrosCofreDepositos",
    ]),
    detalhar(movconta) {
      this.$store.commit("setCofreDeposito", movconta);
      this.toggleDialogDetalharCofreDeposito();
    },
    abrirOpcoes() {
      this.toggleDialogOpcoesCofreInteligente();
    },
    handleScroll() {
      if (window.scrollY >= document.body.clientHeight - window.innerHeight - 10) {
        this.$store.dispatch("loadCofreDepositos");
      }
    },
    handleImportadoFiltro(importado) {
      this.updateFiltrosCofreDepositos({ importado });
    },
    handleFuncionarioFiltro(funcionario = null) {
      this.funcionarioSelecionado = funcionario;

      const cadastroId = funcionario ? funcionario.id : '';

      this.updateFiltrosCofreDepositos({ cadastroId });
    },
    loadFuncionarios() {
      axios.get("/cadastros?funcionario=1&ativo=1").then(res => {
        if (res.data && res.data.success) {
          this.funcionarios = res.data.data.cadastros;
        }
      });
    },
    loadOpcoes() {
      axios
        .get("cofre-inteligente/opcoes")
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }

          this.opcoes = res.data.opcoes;
          this.opcoes.cofreinteligente_usuarios = JSON.parse(this.opcoes.cofreinteligente_usuarios);
        });
    },
    breakLineBar(value) {
      if (!value) return "";
      return value.replace(/\n/g, "<br>").replace("/", "<br>");
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "cofre_inteligente");

      if (!this.videosManuais.video_cofre_inteligente) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "cofre_inteligente");
    },
  },
  computed: {
    ...mapGetters([
      "isPermissoCofreInteligente",
      "cofreDepositos",
      "loadingCofreDepositos",
      "filtrosCofreDepositos",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    filtroImportadoLabel() {
      if (this.filtrosCofreDepositos.importado === "true") {
        return "Importados";
      }
      if (this.filtrosCofreDepositos.importado === "false") {
        return "Não Importados";
      }
      return "Todos";
    },
    colorCofreStatus() {
      switch (this.opcoes.cofreinteligente_statuscode) {
        case "1":
          return 'green';
        case "2":
          return 'yellow-8';
        case "3":
          return 'red-10';
        default:
          return 'yellow-8';
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.$store.dispatch("resetCofreDepositos");
    this.$store.dispatch("loadCofreDepositos");

    this.loadFuncionarios();
    this.loadOpcoes();
    this.verificarPrimeiroVideo();
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
};
</script>

<style lang="stylus" scoped>
.cadastro-link a {
  color: inherit;
  text-decoration: none;
}
</style>
