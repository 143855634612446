<template>
  <div class="q-pa-md">
    <div v-if="loading" class="text-center">
      <q-spinner color="blue-7" size="3em" />
    </div>

    <div v-else class="row">
      <div class="col col-6">
        <q-list padding class="q-mb-lg">
          <q-item tag="label" class="q-px-none">
            <q-item-section side>
              <q-toggle v-model="limitExpedient" true-value="1" false-value="0" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Limitar horário de expediente</q-item-label>
              <q-item-label caption>
                Ao ativar essa opção, os usuários selecionados só poderão acessar o sistema nos dias e horários configurados.
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <div v-if="limitExpedient == '1' ">
        <div v-for="(day, index) in days" :key="day.value" :id="`controleHorarioItem${index + 1}`" class="row items-center">
          <q-toggle v-model="day.show" :label="day.label" style="width: 150px"  />

          <div class="q-ml-auto items-center row" v-if="day.show">
            <q-input dense v-model="day.start_time" mask="time" class="q-pa-none" input-class="text-right">
              <template v-slot:append>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy transition-show="scale" transition-hide="scale">
                    <q-time dense v-model="day.start_time" >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>

            <span class="q-px-md">-</span>

            <q-input dense v-model="day.end_time" mask="time" class="q-pa-none"  input-class="text-right">
              <template v-slot:append>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy transition-show="scale" transition-hide="scale">
                    <q-time dense v-model="day.end_time" >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </div>
        </div>
      </div>
      <div class="col col-4 q-pl-xl">
        <autocomplete
          title="Buscar Usuários"
          :filter="filterFn"
          :carregando="loadingAutoComplete"
          :options="options"
          :formatDisplay="formatDisplay"
          :create="createValue"
          @resultAutocomplete="resultEmitRecebe"
          :display="displayMontar"
          tipoConsulta="cadastro"
          :newCadastro="cadastroReturn"
        />

        <p class="q-mt-lg q-mb-sm">Usuários selecionados: </p>
        <div v-if="selectedUsers.length > 0">
          <q-chip
            v-for="user in selectedUsers"
            :key="user.id"
            v-model="user.show"
            removable
            @remove="remove(user)"
            color="primary"
            text-color="white"
          >
            {{ user.nome }}
          </q-chip>
        </div>
        <div v-else>
          <p class="q-mt-sm text-italic text-grey-7">Não há usuários selecionados.</p>
        </div>
      </div>
    </div>
    <div class="flex justify-end q-mt-md q-mr-auto">
      <q-btn color="green" label="Salvar" :loading="loadingSave" @click="save" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";

export default {
  data() {
    return {
      time: null,
      loadingSave: false,
      days: [
        { label: 'Segunda-Feira', value: "1", show: false, start_time: null, end_time: null },
        { label: 'Terça-Feira', value: "2", show: false, start_time: null, end_time: null },
        { label: 'Quarta-Feira', value: "3", show: false, start_time: null, end_time: null },
        { label: 'Quinta-Feira', value: "4", show: false, start_time: null, end_time: null },
        { label: 'Sexta-Feira', value: "5", show: false, start_time: null, end_time: null },
        { label: 'Sábado', value: "6", show: false, start_time: null, end_time: null },
        { label: 'Domingo', value: "0", show: false, start_time: null, end_time: null },
      ],
      options: [],
      loadingAutoComplete: false,
      loading: false,
      autocompleteResult: null,
      selectedUsers: [],
      deletedUsers: [],
      limitExpedient: "0"
    };
  },
  methods: {
    getHorarios() {
      if (this.loading) return;
      this.loading = true;

      axios.get("/users/horarios")
        .then(res => {
          this.loading = false;
          if (!res.data.success) {
            Notify.create({
              message: "Não foi possível buscar os horários.",
              color: "negative",
              position: "top-right"
            });
            return;
          }

          if (res.data.users) {
            this.selectedUsers = res.data.users;
          }

          if (res.data.horarios) {
            const horarios = JSON.parse(JSON.stringify(res.data.horarios));
            for (const day of this.days) {
              const horario = horarios.find((obj) => obj.day === day.value);

              if (horario) {
                day.show = true;
                day.start_time = horario.start;
                day.end_time = horario.end;
              }
            }
          }

          if (res.data.limitar_expediente) {
            this.limitExpedient = res.data.limitar_expediente;
          }

        })
        .catch(err => {
          this.loading = false;
          Notify.create({
            message: "Erro ao buscar horários.",
            color: "negative",
            position: "top-right"
          });
        });
    },
    save() {
      if (this.loadingSave) return;

      if (this.days.filter(day => day.show).length === 0) {
        Notify.create({
          message: "Selecione ao menos um dia.",
          color: "negative",
          position: "top-right"
        });
        return;
      }

      this.loadingSave = true;

      const horarios = this.days.filter(day => day.show).map(day => {
        return {
          day: day.value,
          start: day.start_time ?? "",
          end: day.end_time ?? ""
        };
      });

      let dados = {
        horarios,
        users: this.selectedUsers.map(user => user.id),
        limite_expediente: this.limitExpedient
      };

      if (this.deletedUsers.length > 0) {
        dados.deleted_users = this.deletedUsers;
      }

      axios.post("/users/horarios", dados)
        .then(res => {
          this.loadingSave = false;

          Notify.create({
            message: res.data.message,
            color: res.data.success ? "positive" : "negative",
            position: "top-right"
          });
        }).catch(err => {
          this.loadingSave = false;
          Notify.create({
            message: "Erro ao salvar horários.",
            color: "negative",
            position: "top-right"
          });
        });
    },
    remove(user) {
      this.deletedUsers.push(user.id);
      this.selectedUsers = this.selectedUsers.filter(item => item.id !== user.id);
    },
    // Auto Complete Methods
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.loadingAutoComplete = true;
        axios.get("/users").then(res => {
          this.loadingAutoComplete = false;
          this.options = res.data.data.cadastros;
        });
      });
    },
    formatDisplay(result) {
      return result.user.nome;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = result;
      this.$store.commit("cadastroReturn", null);

      if (this.autocompleteResult && this.autocompleteResult.id != null) {
        Notify.create({
          message: this.autocompleteResult.user.nome + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
      }
    },
    displayMontar(value) {
      return value ? value.user.nome : "";
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val.user.nome
      };
      done(newCadastro, "add-unique");
    },
  },
  computed: {
    ...mapGetters(["cadastroReturn"])
  },
  watch: {
    autocompleteResult: {
      handler: function (val) {
        if (val && val.user != null) {
          if (this.selectedUsers.find(item => item.id === val.user.id)) {
            Notify.create({
              message: val.user.nome + " já foi selecionado.",
              color: "warning",
              position: "top-right"
            });
            this.autocompleteResult = null;
            return;
          }

          if (val.user.id == null) {
            Notify.create({
              message: val.user.nome + " não é um funcionário.",
              color: "warning",
              position: "top-right"
            });
            this.autocompleteResult = null;
            return;
          }

          this.selectedUsers.push({
            id: val.user.id,
            nome: val.user.nome,
            show: true
          });

          this.autocompleteResult = null;
        }
      },
      deep: true
    },
  },
  mounted() {
    this.getHorarios();
  }
};
</script>
