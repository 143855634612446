<template>
  <q-dialog v-model="openDialogRelatoriosAgendas" persistent>
    <q-card style="width: 90%; max-width: 1000px;">

      <q-card-section class="row items-center">
        <q-btn
          id="addRelatorioAgendado"
          flat
          round
          icon="add"
          color="primary"
          @click="openModalRelatoriosAgendado()"
        />
        <div class="text-h6">Relatórios Agendados</div>
        <q-space />
        <q-btn
          class="q-ml-lg"
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="closeModal()"
        />
      </q-card-section>

      <q-separator />

      <q-card-section>
        <div class="q-mb-md">
          <q-input
            clearable
            label="Buscar"
            v-model="busca"
          >
            <template v-slot:prepend>
              <q-icon name="search"/>
            </template>
          </q-input>
        </div>

        <div>
          <q-table
            flat
            row-key="id"
            no-data-label="Nenhum dado"
            no-results-label="Nenhum resultado obtido"
            loading-label="Carregando dados"
            :data="relatoriosAgendasFilter"
            :columns="columns"
            :loading="loading"
            :pagination.sync="pagination"
          >
            <template v-slot:bottom></template>

            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">

                <q-td key="ativo" class="text-left">
                  <q-toggle
                    color="green"
                    checked-icon="check"
                    unchecked-icon="clear"
                    v-model="props.row.ativo"
                    @input="editarRelatoriosAgenda(props.row, true)"
                    :disable="props.row.salvando"
                  />
                </q-td>

                <q-td key="periodo" class="text-left">
                  {{ getLabelPeriodo(props.row.periodo) }}
                </q-td>

                <q-td v-if="!props.row.whatsapp" key="assunto_email" class="text-left" style="white-space: normal;">
                  {{ props.row.id }} - {{ props.row.nome }}
                  <br>
                  Para: {{ props.row.emails }}
                  <br>
                  Assunto:
                  {{
                    props.row.assunto_email && props.row.assunto_email.length > 40
                    ? props.row.assunto_email.substr(0, 40) + "..."
                        : props.row.assunto_email
                  }}
                  <q-tooltip
                      v-if="
                        props.row.assunto_email && props.row.assunto_email.length > 40
                      "
                    >
                      {{ props.row.assunto_email }}
                    </q-tooltip>
                  <br>
                  Filtros: {{ getRules(props.row.settings) }}
                </q-td>

                <q-td v-if="props.row.whatsapp" key="assunto_whatsapp" class="text-left" style="white-space: normal;">
                  {{ props.row.id }} - {{ props.row.nome }} - Via Whatsapp{{ props.row.whatsapp_anexo ? " - Como anexo":"" }}
                  <br>
                  Para: {{ props.row.whatsapps }}
                  <br>
                  Filtros: {{ getRules(props.row.settings) }}
                </q-td>

                <q-td key="proximo_envio" class="text-center">
                  {{ props.row.proximo_envio | formatDate}} {{ `${props.row.hora}:00` }}
                </q-td>

                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px;">
                      <q-btn
                        @click="openModalRelatoriosAgendado(props.row, true)"
                        color="primary"
                        icon="edit"
                        size="12px"
                      />

                      <q-btn
                        @click="excluirRelatoriosAgenda(props.row)"
                        color="negative"
                        icon="delete_forever"
                        size="12px"
                      />
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                        @click="openModalRelatoriosAgendado(props.row, true)"
                        color="primary"
                        icon="edit"
                        size="12px"
                      />
                      <q-btn
                        @click="excluirRelatoriosAgenda(props.row)"
                        color="negative"
                        icon="delete_forever"
                        size="12px"
                      />
                  </div>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-card-section>

    </q-card>

    <!-- Dialog Add Relatório Agendado -->
    <q-dialog
      v-model="dialogRelatoriosAgendado"
      persistent
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card style="width: 100%; max-width: 850px" class="q-py-md">
        <q-card-section class="row items-center q-pt-none">
          <div class="text-h6">
            {{ editModal ? "Editar" : "Novo" }} Relatório Agendado
            <q-toggle
              v-model="relatoriosAgenda.ativo"
              color="positive"
              label="Ativo"
            />
          </div>
          <q-space />
          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            class="q-ml-lg"
            @click="closeModalRelatoriosAgendado()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-x-lg">
            <div class="col-12 col-md-6 row q-col-gutter-x-md" style="height: fit-content;">

              <div class="col-12">
                <q-select
                  bottom-slots
                  label="Relatório"
                  :option-value="relatorio => relatorio ? `${relatorio.id}${relatorio.nome}` : null"
                  option-label="nome"
                  :options="relatoriosOptions"
                  :error="Boolean(errors.relatorio_id) || Boolean(errors.relatorio_gerencial_id)"
                  v-model="relatoriosAgenda.relatorio"
                  v-if="!editModal"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.relatorio_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                    <span
                      v-for="(error, i) in errors.relatorio_gerencial_id"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-select
                  emit-value
                  map-options
                  bottom-slots
                  label="Período"
                  :options="periodos"
                  :error="Boolean(errors.periodo)"
                  v-model="relatoriosAgenda.periodo"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.periodo"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>

              <div class="col-6">
                <q-input
                  bottom-slots
                  label="Próximo Envio"
                  @click="$refs.qProximoEnvio.show()"
                  :error="Boolean(errors.proximo_envio)"
                  v-model="relatoriosAgenda.proximo_envio"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qProximoEnvio"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          minimal
                          today-btn
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          @input="$refs.qProximoEnvio.hide()"
                          v-model="relatoriosAgenda.proximo_envio"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.proximo_envio"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

              <div class="col-6">
                <q-select
                  emit-value
                  map-options
                  bottom-slots
                  label="Hora"
                  :options="horarios"
                  :error="Boolean(errors.hora)"
                  v-model="relatoriosAgenda.hora"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.hora"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>

              <div class="col-12">
                <q-checkbox v-model="relatoriosAgenda.whatsapp" label="Enviar por WhatsApp" color="green" />
              </div>

              <!-- <div class="col-12" v-if="relatoriosAgenda.whatsapp">
                <q-checkbox v-model="relatoriosAgenda.whatsapp_anexo" label="Enviar como anexo" color="green" />

                <div class="q-px-sm text-caption">
                  {{ (relatoriosAgenda.whatsapp_anexo?"A Mensagem vai conter um link para o Relatório.":"Cada linha do relatório será uma mensagem.") }}
                </div>

              </div> -->

              <div class="col-12" v-if="relatoriosAgenda.whatsapp">
                <autocomplete
                  bottom-slots
                  title="Selecionar Destinatário"
                  tipoConsulta="cadastro"
                  :create="createValue"
                  :disable="!canAddWhatsapp"
                  :filter="filterCadastroWhatsapp"
                  :display="displayMontar"
                  :cadastro="abreCadastro"
                  :options="optionsCadastros"
                  :formatDisplay="formatDisplay"
                  :carregando="carregandoCadastro"
                  @resultAutocomplete="resultEmitRecebeWhatsapp"
                />
              </div>

              <div class="col-12" v-if="relatoriosAgenda.whatsapp">
                <q-select
                  use-input
                  use-chips
                  multiple
                  bottom-slots
                  hide-dropdown-icon
                  label="WhatsApps"
                  input-debounce="0"
                  hint="O limite de números é 3"
                  :error="Boolean(errors.whatsapps)"
                  v-model="relatoriosAgenda.whatsapps"
                  :new-value-mode="canAddWhatsapp ? 'add-unique' : undefined"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.whatsapps"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>

              <div class="col-12" v-if="!relatoriosAgenda.whatsapp">
                <q-input
                  bottom-slots
                  label="Assunto do Email"
                  :error="Boolean(errors.assunto_email)"
                  v-model="relatoriosAgenda.assunto_email"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.assunto_email"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

              <div class="col-12" v-if="!relatoriosAgenda.whatsapp">
                <autocomplete
                  bottom-slots
                  title="Selecionar Destinatário"
                  tipoConsulta="cadastro"
                  :create="createValue"
                  :disable="!canAddEmail"
                  :filter="filterCadastro"
                  :display="displayMontar"
                  :cadastro="abreCadastro"
                  :options="optionsCadastros"
                  :formatDisplay="formatDisplay"
                  :carregando="carregandoCadastro"
                  @resultAutocomplete="resultEmitRecebe"
                />
              </div>

              <div class="col-12" v-if="!relatoriosAgenda.whatsapp">
                <q-select
                  use-input
                  use-chips
                  multiple
                  bottom-slots
                  hide-dropdown-icon
                  label="Emails"
                  input-debounce="0"
                  hint="O limite de emails é 5"
                  :error="Boolean(errors.emails)"
                  v-model="relatoriosAgenda.emails"
                  :new-value-mode="canAddEmail ? 'add-unique' : undefined"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.emails"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-select>
              </div>

              <div class="col-12 q-mt-lg" v-if="!relatoriosAgenda.whatsapp">
                <q-input
                  v-model="relatoriosAgenda.observacao"
                  label="Observação"
                  type="textarea"
                  :error="Boolean(errors.observacao)"
                  bottom-slots
                >
                  <template v-slot:hint>Esse texto será enviado no corpo do e-mail, na tag {mensagem}</template>
                </q-input>
              </div>
            </div>

            <div class="col-12 col-md-6 row">
              <div class="col-12">

                <p class="text-subtitle2">Parâmetros
                  <q-btn icon="help_outline" flat dense round>
                    <q-tooltip>Alguns filtros podem ser incompatíveis com seu relatório. Entre em contato caso tenha dúvidas!</q-tooltip>
                  </q-btn>
                  <q-btn icon="info_outline" flat dense round>
                    <q-tooltip>As datas apresentadas são apenas exemplos. Elas serão calculas no momento da impressão do relatório!</q-tooltip>
                  </q-btn>
                </p>
                <div v-if="relatoriosAgenda.relatorio">
                  <div
                    v-for="(parametro, index) in parametros"
                    :key="index"
                  >
                    <div v-if="parametro.autocomplete && parametro.autocomplete.isAtivo">
                      <q-select
                        v-model="parametro.value"
                        use-input
                        emit-value
                        map-options
                        clearable
                        input-debounce="500"
                        :options="options"
                        :option-value="value => value"
                        :option-label="parametro.autocomplete.option_label"
                        :label="parametro.labelUser"
                        @filter="
                          (val, update, abort, url) => {
                            autocompletePersonalizar(val, update, abort, parametro.autocomplete.url);
                          }
                        "
                      >
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey"> Nenhum resultado encontrado! </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>
                    <q-select
                      v-else-if="parametro.type != 'option'"
                      emit-value
                      map-options
                      v-model="parametro.value"
                      :label="parametro.labelUser"
                      :options="parametro.parseOptions"
                    />
                    <q-select
                      v-else
                      v-model="parametro.value"
                      emit-value
                      clearable
                      use-input
                      input-debounce="500"
                      :display-value="returnLabel(parametro)"
                      :options="parametro.parseOptions"
                      :multiple="parametro.multiple"
                      :label="parametro.labelUser"
                      @clear="parametro.value = []"
                      @filter="(val, update, abort) => filterFn(val, update, abort, parametro)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Salvar"
            color="green"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingAdd"
            @click="salvarRelatorio()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-dialog>
</template>

<script>
import moment from 'moment';
import axios from '@/axios-auth';
import { mapGetters } from 'vuex';
import { Loading, Notify } from 'quasar';
import { copyToClipboard } from 'quasar';

export default {
  name: 'modal-relatorios-agendas',
  data() {
    return {
      loading: false,
      editModal: false,
      relatoriosAgendas: [],
      columns: [
        {
          name: 'ativo',
          label: 'Ativo',
          field: row => row.ativo,
          align: 'left',
          sortable: true,
        },
        {
          name: 'periodo',
          label: 'Período',
          field: row => row.periodo,
          align: 'left',
          sortable: true,
        },
        {
          name: 'assunto_email',
          label: 'Relatório',
          field: row => row.assunto_email,
          align: 'left',
          sortable: true,
        },
        {
          name: 'proximo_envio',
          label: 'Próximo Envio',
          field: row => `${row.proximo_envio} ${row.hora}`,
          align: 'center',
          sortable: true,
        },
        { name: 'tdfora' },
      ],
      pagination: {
        sortBy: 'id',
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      busca: '',
      dialogRelatoriosAgendado: false,
      loadingAdd: false,
      relatoriosAgenda: {
        relatorio: null,
        relatorio_id: null,
        relatorio_gerencial_id: null,
        ativo: true,
        whatsapp: false,
        whatsapps: [],
        assunto_email: '',
        dia: '',
        hora: '',
        emails: [],
        settings: '',
        periodo: null,
        proximo_envio: moment().add(1, 'day').format('DD/MM/YYYY'),
        observacao: '',
      },
      errors: {},
      parametros: [],
      variaveis: [
        {
          label: '01 - Ontem (' + moment().subtract(1, 'day').format('DD/MM/YYYY') + ')',
          value: '{ontem}'
        },
        {
          label: '02 - Hoje (' + moment().format('DD/MM/YYYY') + ')',
          value: '{hoje}'
        },
        {
          label: '03 - Amanhã ('+ moment().add(1, 'day').format('DD/MM/YYYY') +')',
          value: '{amanha}',
        },
        {
          label: '04 - Início da Semana Anterior ('+ moment().subtract(1, 'week').startOf('week').format('DD/MM/YYYY') +')',
          value: '{iniSemanaPassada}',
        },
        {
          label: '05 - Fim da Semana Anterior ('+ moment().subtract(1, 'week').endOf('week').format('DD/MM/YYYY') +')',
          value: '{fimSemanaPassada}',
        },
        {
          label: '06 - Início da Semana Atual ('+ moment().startOf('week').format('DD/MM/YYYY') +')',
          value: '{iniSemana}',
        },
        {
          label: '07 - Fim da Semana Atual ('+ moment().endOf('week').format('DD/MM/YYYY') +')',
          value: '{fimSemana}',
        },
        {
          label: '08 - Início da Próxima Semana ('+ moment().add(1, 'week').startOf('week').format('DD/MM/YYYY') +')',
          value: '{iniSemanaProxima}',
        },
        {
          label: '09 - Fim da Próxima Semana ('+ moment().add(1, 'week').endOf('week').format('DD/MM/YYYY') +')',
          value: '{fimSemanaProxima}',
        },
        {
          label: '10 - Início do Mês Passado ('+ moment().subtract(1, 'month').startOf('month').format('DD/MM/YYYY') +')',
          value: '{iniMesPassado}',
        },
        {
          label: '11 - Fim do Mês Passado ('+ moment().subtract(1, 'month').endOf('month').format('DD/MM/YYYY') +')',
          value: '{fimMesPassado}',
        },
        {
          label: '12 - Início do Mês Atual ('+ moment().startOf('month').format('DD/MM/YYYY') +')',
          value: '{iniMes}',
        },
        {
          label: '13 - Fim do Mês Atual ('+ moment().endOf('month').format('DD/MM/YYYY') +')',
          value: '{fimMes}',
        },
        {
          label: '14 - Início do Próximo Mês ('+ moment().add(1, 'month').startOf('month').format('DD/MM/YYYY') +')',
          value: '{iniMesProximo}',
        },
        {
          label: '15 - Fim do Próximo Mês ('+ moment().add(1, 'month').endOf('month').format('DD/MM/YYYY') +')',
          value: '{fimMesProximo}',
        },
        {
          label: '16 - Início do Ano Passado ('+ moment().subtract(1, 'year').startOf('year').format('DD/MM/YYYY') +')',
          value: '{iniAnoAnterior}',
        },
        {
          label: '17 - Fim do Ano Passado ('+ moment().subtract(1, 'year').endOf('year').format('DD/MM/YYYY') +')',
          value: '{fimAnoAnterior}',
        },
        {
          label: '18 - Início do Ano ('+ moment().startOf('year').format('DD/MM/YYYY') +')',
          value: '{iniAno}',
        },
        {
          label: '19 - Fim do Ano ('+ moment().endOf('year').format('DD/MM/YYYY') +')',
          value: '{fimAno}',
        },
        {
          label: '20 - Início do Próximo Ano ('+ moment().add(1, 'year').startOf('year').format('DD/MM/YYYY') +')',
          value: '{iniAnoProximo}',
        },
        {
          label: '21 - Fim do Próximo Ano ('+ moment().add(1, 'year').endOf('year').format('DD/MM/YYYY') +')',
          value: '{fimAnoProximo}',
        },
        {
          label: '22 - Referência do Mês Anterior ('+ moment().subtract(1, 'month').format('MM/YYYY') +')',
          value: '{referenciaUltimoMes}',
        },
        {
          label: '23 - Referência do Mês Atual ('+ moment().format('MM/YYYY') +')',
          value: '{referenciaEsteMes}',
        },
        {
          label: '24 - Referência do Próximo Mês ('+ moment().add(1, 'month').format('MM/YYYY') +')',
          value: '{referenciaMesProximo}',
        },
        {
          label: '25 - Mês Passado ('+ moment().subtract(1, 'month').format('MM') +')',
          value: '{ultimoMes}',
        },
        {
          label: '26 - Mês Atual ('+ moment().format('MM') +')',
          value: '{mesAtual}',
        },
        {
          label: '27 - Próximo Mês ('+ moment().add(1, 'month').format('MM') +')',
          value: '{mesProximo}',
        },
        {
          label: '28 - Ano Passado ('+ moment().subtract(1, 'year').format('YYYY') +')',
          value: '{referenciaUltimoAno}',
        },
        {
          label: '29 - Ano Atual ('+ moment().format('YYYY') +')',
          value: '{referenciaEsteAno}',
        },
        {
          label: '30 - Próximo Ano ('+ moment().add(1, 'year').format('YYYY') +')',
          value: '{referenciaAnoProximo}',
        },
      ],
      periodos: [
        {
          value: 0,
          label: 'Diário',
        },
        {
          value: 1,
          label: 'Semanal',
        },
        {
          value: 2,
          label: 'Mensal',
        },
      ],
      // Autocomplete
      carregandoCadastro: false,
      optionsCadastros: [],
      options: [],
    };
  },
  methods: {
    init() {
      this.loadRelatoriosAgendas();
    },
    closeModal() {
      this.$store.dispatch('controlDialogRelatoriosAgendas', false);
    },
    resetModal() {
      this.relatoriosAgenda = {
        relatorio: null,
        relatorio_id: null,
        relatorio_gerencial_id: null,
        ativo: true,
        whatsapp: false,
        whatsapps: [],
        assunto_email: '',
        dia: '',
        hora: '',
        emails: [],
        settings: '',
        periodo: null,
        proximo_envio: moment().add(1, 'day').format('DD/MM/YYYY'),
        observacao: '',
      };
    },
    loadRelatoriosAgendas() {
      this.loading = true;

      axios
        .get('/relatorios/relatorios-agendas')
        .then(res => {
          this.loading = false;

          if (res.error && res.error.response.status === 403) {
            this.$q.notify({
              message: res.error.response.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }
          this.relatoriosAgendas = res.data.relatorios_agendas;
        })
        .catch(() => {
          this.loading = false;
          this.$q.notify({
            message: 'Houve um erro ao recuperar os relatórios agendados',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    getRules(jsonFiltros){
      if (!jsonFiltros) return 'Nenhum';

      try {
        let filtros = JSON.parse(jsonFiltros);
        let rules = '';
        Object.keys(filtros).forEach(function(key) {
          rules += key + ': ' + filtros[key] + '; \n';
        });


        return rules;
      } catch (error) {
        return 'Nenhum';
      }
    },
    editarRelatoriosAgenda(relatorioAgenda, toggleAtivar = false) {
      let data = {...relatorioAgenda};
      let url = `/relatorios/editar-relatorios-agendas/${relatorioAgenda.id}?toggle_ativar=${toggleAtivar ? "1" : "0"}`;

      if (toggleAtivar) {
        data = {
          ativo: !data.ativo,
        };
      } else {
        data.salvando = true;
        data.emails = data.emails.join(',');
        data.whatsapps = data.whatsapps.join(',');
        data.settings = this.getSettings();
      }

      axios
        .post(url, data)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "negative",
              position: "top-right"
            });

            return;
          }

          if (!toggleAtivar) {
            Notify.create({
              message: res.data.message,
              color: "positive",
              position: "top-right"
            });

            this.closeModalRelatoriosAgendado();
          }

          this.loadRelatoriosAgendas();
        }).catch(error => {
          data.salvando = false;
            this.$q.notify({
              message: res.error.response.data.message,
              color: 'orange',
              position: 'top-right',
            });
        });

    },
    excluirRelatoriosAgenda(relatoriosAgenda) {
      this.$q.dialog({
        title: relatoriosAgenda.assunto_email,
        message: 'Deseja excluir o relatório agendado?',
        cancel: 'Cancelar',
        persistent: true,
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post(`/relatorios/delete-relatorios-agendas/${relatoriosAgenda.id}`)
            .then(res => {
              this.loadRelatoriosAgendas();
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Houve um erro ao excluir o relatório agendado',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    async openModalRelatoriosAgendado(relatorioAgenda, editRelatorio = false) {
      if (editRelatorio) {
        let relatorioID = relatorioAgenda.relatorio_id;
        let isGerencial = false;

        if (relatorioID == null) {
          relatorioID = relatorioAgenda.relatorio_gerencial_id;
          isGerencial = true;
        }

        await axios
          .get("/relatorios/relatorios-agendas/" + relatorioID, { params: { is_gerencial: isGerencial ? 1 : 0 }})
          .then(res => {
            if (!res.data.success) {
              this.$q.notify({
                message: res.data.message,
                color: 'orange',
                position: 'top-right',
              });
              return;
            }

            relatorioAgenda = {...relatorioAgenda};
            relatorioAgenda.relatorio = res.data.relatorio;

            relatorioAgenda.emails = typeof relatorioAgenda.emails == 'string' ? relatorioAgenda.emails.split(",") : [];
            relatorioAgenda.whatsapps = typeof relatorioAgenda.whatsapps == 'string' ? relatorioAgenda.whatsapps.split(",") : [];

            relatorioAgenda.proximo_envio = moment(relatorioAgenda.proximo_envio).format("DD/MM/YYYY");

            this.relatoriosAgenda = relatorioAgenda;
            this.editModal = true;
          });
      };

      this.dialogRelatoriosAgendado = true;
    },
    closeModalRelatoriosAgendado() {
      this.dialogRelatoriosAgendado = false;
      this.editModal = false;
      this.resetModal();
    },
    salvarRelatorio() {
      if (this.editModal) this.editarRelatoriosAgenda(this.relatoriosAgenda, false);
      else this.salvar();
    },
    salvar() {
      this.loadingAdd = true;

      const data = JSON.parse(JSON.stringify(this.relatoriosAgenda));
      data.emails = data.emails.join(',');
      data.whatsapps = data.whatsapps.join(',');

      if (data.relatorio) {
        if (data.relatorio.gerencial) {
          data.relatorio_gerencial_id = data.relatorio.id;
          data.relatorio_id = null;
        } else {
          data.relatorio_id = data.relatorio.id;
          data.relatorio_gerencial_id = null;
        }
        delete data.relatorio;
      }

      data.settings = this.getSettings();

      axios
        .post('/relatorios/add-relatorios-agendas', data)
        .then(res => {
          this.loadingAdd = false;

          if (res.error && res.error.response.status === 403) {
            this.$q.notify({
              message: res.error.response.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errors = res.data.errors || {};
            return;
          }

          this.loadRelatoriosAgendas();
          this.closeModalRelatoriosAgendado();
        })
        .catch(() => {
          this.loadingAdd = false;

          this.$q.notify({
            message: 'Houve um erro ao adicionar o relatório agendado',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    getSettings() {
      const settings = {};
      this.parametros.forEach(parametro => {
        // Retorna o label dos values, para ser mostrado no relatório
        if (parametro && parametro.autocomplete) {
          if (parametro.autocomplete.option_label) {
            settings[parametro.autocomplete.option_label] =
            parametro.value[parametro.autocomplete.option_label];
          }
          if (parametro.autocomplete.option_value) {
            settings[parametro.label] = parametro.value[parametro.autocomplete.option_value];
          }
        }
        else {
          if (parametro.datasource && parametro.options) {
            if (parametro.multiple && Array.isArray(parametro.value)) {
              settings[`${parametro.label}_label`] =
              parametro.options.filter(opcao => parametro.value.includes(opcao.value))
              .map(opcao => opcao.label);
            } else {
              settings[`${parametro.label}_label`] =
              parametro.options.filter(opcao => opcao.value == parametro.value)
              .map(opcao => opcao.label);
            }
        }
        settings[parametro.label] = parametro.value;
      }
      });

      return JSON.stringify(settings);
    },
    copyText(text) {
      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: 'Texto copiado',
            color: 'green',
            position: 'top-right',
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao tentar copiar seu texto',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    getLabelPeriodo(periodo) {
      const periodos = this.periodos.map(periodoObj => periodoObj.label);

      return periodos[periodo];
    },
    returnLabel(filtro) {
      if (!filtro.options) return;

      let option = filtro.options.filter(result => {
        return result.value === filtro.value;
      });

      if (option.length > 0) return option[0].label;

      let label = '';

      if (Array.isArray(filtro.value)) {
        const labels = [];

        filtro.value.forEach(value => {
          const optionFiltro = filtro.options.find(option => option.value === value);

          labels.push(optionFiltro.label);
        });

        label = labels.join(', ');
      } else {
        label = filtro.value;
      }

      return label;
    },
    filterFn(val, update, abort, filtro) {
      update(() => {
        const valor = val.toLowerCase();

        filtro.parseOptions = filtro.options.filter(
          option => option.label.toLowerCase().indexOf(valor) > -1
        );
        this.$forceUpdate();
      });
    },
    toggle(input, tipo = 'show') {
      if (input.type != 'date') return;
      let label = 'input' + input.labelUser + '';

      if (tipo === 'show') {
        return this.$refs[label][0].show();
      }
      return this.$refs[label][0].hide();
    },
    // Autocomplete methods
    filterCadastro(val, update, abort) {
      update(() => {
        if (val === null) return abort();

        this.carregandoCadastro = true;

        axios
          .get(`/cadastros?ativo=1&busca=${val}`)
          .then(res => {
            const cadastros = res.data.data.cadastros
              .filter(cadastro => cadastro.email && !this.relatoriosAgenda.emails.includes(cadastro.email));

            this.carregandoCadastro = false;
            this.optionsCadastros = cadastros;
          });
      });
    },
    filterCadastroWhatsapp(val, update, abort) {
      update(() => {
        if (val === null) return abort();

        this.carregandoCadastro = true;

        axios
          .get(`/cadastros?ativo=1&busca=${val}`)
          .then(res => {
            const cadastros = res.data.data.cadastros
              .filter(cadastro => cadastro.whatsapp && !this.relatoriosAgenda.whatsapps.includes(cadastro.whatsapp));

            this.carregandoCadastro = false;
            this.optionsCadastros = cadastros;
          });
      });
    },
    autocompletePersonalizar(val, update, abort, url) {
      update(() => {
        if (val === null) return abort();
        axios.get(url + val).then(res => {
          this.options = res.data;
        });
      });
    },
    formatDisplay(result) {
      return result.nome;
    },
    createValue(val, done) {
      this.$q.notify({
        message: 'Cadastro não foi encontrado.',
        color: 'orange',
        position: 'top-right',
      });
    },
    resultEmitRecebe(result) {
      if (!result.email || this.relatoriosAgenda.emails.includes(result.email)) return;

      this.relatoriosAgenda.emails.push(result.email);
      this.$root.$emit('limpar');
    },
    resultEmitRecebeWhatsapp(result) {
      if (!result.whatsapp || this.relatoriosAgenda.whatsapps.includes(result.whatsapp)) return;

      this.relatoriosAgenda.whatsapps.push(result.whatsapp);
      this.$root.$emit('limparWhatsapp');
    },
    displayMontar(value) {
      return !value.nome ? value.name : value.nome;
    },
    abreCadastro(value) {
      this.$store.dispatch('cadastro', {
        nome: value.name,
        noSearch: true,
      });
    },
  },
  computed: {
    ...mapGetters([
      'openDialogRelatoriosAgendas',
      'relatorios',
      'relatoriosGerenciais',
    ]),
    canAddEmail() {
      return this.relatoriosAgenda.emails.join(',').split(',').length < 5;
    },
    canAddWhatsapp() {
      return this.relatoriosAgenda.whatsapps.join(',').split(',').length < 3;
    },
    relatoriosAgendasFilter() {
      const busca = String(this.busca).toLowerCase();
      return this.relatoriosAgendas.filter(relatoriosAgenda => (
        String(relatoriosAgenda.assunto_email).toLowerCase().includes(busca) ||
        String(relatoriosAgenda.nome).toLowerCase().includes(busca) ||
        relatoriosAgenda.dia == busca ||
        relatoriosAgenda.hora == busca
      ));
    },
    relatoriosOptions() {
      return [
        ...this.relatorios,
        ...this.relatoriosGerenciais,
      ];
    },
    horarios() {
      const horarios = [];

      for (let i = 0; i < 24; i++) {
        const hora = `0${i}`.slice(-2);

        horarios.push({
          label: `${hora}:00`,
          value: i,
        });
      }

      return horarios;
    },
  },
  watch: {
    openDialogRelatoriosAgendas(value) {
      if (value) {
        this.init();
      }
    },
    'relatoriosAgenda.relatorio'(value) {
      this.parametros = [];

      if (value && value.filtros) {
        if (value.gerencial) {
          this.relatoriosAgenda.relatorio_gerencial_id = value.id;
        } else {
          this.relatoriosAgenda.relatorio_id = value.id;
        }

        let filtros = value.filtros;

        if (typeof filtros !== 'object') {
          filtros = JSON.parse(filtros);
        }

        if (filtros) {
          filtros.forEach((filtro, idx) => {
            if(filtro.options === null) {
              axios.get(`relatorios/view/${value.id}`)
                .then((res) => {
                  if (filtro.label === res.data.relatorio.filtros[idx].label) {
                    filtro.options = res.data.relatorio.filtros[idx].options;
                  }
                }).then(() => {
                  this.parametros[idx].options = filtro.options;
                });
            }
          });

          this.parametros = filtros
              .filter(filtro => (filtro.dinamico === true || filtro.dinamico === 'true'))
              .map(filtro => ({
                ...filtro,
                parseOptions: filtro.options ? (filtro.options || this.variaveis) : this.variaveis,
              }));
        }
      }
    },
    'relatoriosAgenda.settings'(value) {
      if (value == "") return;

      let filtros = value;
      if (typeof value !== "object") {
        filtros = JSON.parse(value);
      }

      Object.keys(filtros).forEach((key) => {
        const param = this.parametros.find(param => param.label == key);
        if (param) {
          param.value = filtros[key];
        }
      });
    }
  },
};
</script>

<style lang="stylus" scoped></style>
