<template>
  <div id="timelineGlobal">
    <q-page-sticky position="top-right" :offset="[0, 90]">
      <q-btn
        :color="OpenDrawerTimelineGlobal ? 'red-7' : 'blue-7'"
        :icon="OpenDrawerTimelineGlobal ? 'close' : 'timeline'"
        class="rotate-0"
        style="border-top-left-radius: 50px; border-bottom-left-radius: 50px"
        @click="toggleTimeline"
        :loading="timelineAction.loadingTimeline"
        id="timeline"
      >
        <q-tooltip
          v-if="!OpenDrawerTimelineGlobal"
          :content-class="
            OpenDrawerTimelineGlobal
              ? 'bg-negative text-white text-center'
              : 'bg-secondary text-white text-center'
          "
        >
          Linha do Tempo Global
        </q-tooltip>
        <template v-slot:loading>
          <q-spinner color="white" size="1em" />
        </template>
      </q-btn>
    </q-page-sticky>
    <q-drawer
      overlay
      side="right"
      v-model="OpenDrawerTimelineGlobal"
      bordered
      :width="$q.screen.lt.sm ? 380 : 550"
      :breakpoint="500"
      behavior="default"
      content-class="bg-white"
    >
      <q-scroll-area class="fit">
        <div class="q-px-lg q-pb-md">
          <q-timeline>
            <q-timeline-entry heading id="timeline-global-heading">
              Linha do Tempo

              <q-btn
                flat
                round
                dense
                icon="add"
                color="primary"
                @click="add()"
                id="observacaoTimeline"
              >
                <q-tooltip>Adicionar Observação</q-tooltip>
              </q-btn>
              <q-btn
                dense
                flat
                round
                icon="refresh"
                color="primary"
                @click="chamaTodos()"
              >
                <q-tooltip>Listar Todos</q-tooltip>
              </q-btn>
              <q-btn-group rounded flat no-wrap no-caps class="q-ml-md">
                <q-btn
                  id="dataFiltroTimelineIni"
                  class="white text-blue-7"
                  rounded
                  dense
                  :label="filtersTimeline.dtini"
                >
                  <template v-slot:default>
                    <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                      <q-date
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        v-model="filtersTimeline.dtini"
                        @input="alterarData"
                      />
                    </q-popup-proxy>
                  </template>
                </q-btn>
                <q-btn
                  id="dataFiltroTimelineFim"
                  class="white text-blue-7"
                  rounded
                  dense
                  :label="filtersTimeline.dtfim"
                >
                  <template v-slot:default>
                    <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                      <q-date
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        v-model="filtersTimeline.dtfim"
                        @input="alterarData"
                      />
                    </q-popup-proxy>
                  </template>
                </q-btn>
              </q-btn-group>
              <q-btn dense flat round icon="close" class="float-right" @click="toggleTimeline" />
            </q-timeline-entry>
            <div class="q-col-gutter-md row q-pb-lg">
              <div class="col col-md-6">
                <q-select
                  label="Filtrar por Ação"
                  @input="selected => setTimelineGroup(selected)"
                  use-input
                  clearable
                  input-debounce="20"
                  map-options
                  option-label="label"
                  option-value="value"
                  emit-value
                  @filter="timelineFilterResult"
                  v-model="selected"
                  :options="timelineFilter"
                />
              </div>
              <div class="col col-md-6">
                <autocomplete
                  id="userFiltroTimeline"
                  title="Filtrar por Usuário"
                  :filter="filterFn"
                  :carregando="loadingAutoComplete"
                  :options="usersOptions"
                  :formatDisplay="formatDisplay"
                  :create="() => {}"
                  :display="displayMontar"
                  tipoConsulta="cadastro"
                  :newCadastro="cadastroReturn"
                  @resultAutocomplete="setTimelineUser"
                />
              </div>
            </div>

            <div class="col-12 col-md-12 text-center" v-if="timelineAction.loadingTimeline">
              <q-spinner color="blue-7" size="4em" />
            </div>

            <div v-else-if="timelines.length === 0" class="text-center">Nenhuma timeline encontrada.</div>

            <q-timeline-entry
              v-else
              v-for="timeline in timelines"
              :key="timeline.id"
              :icon="getIcone(timeline.icone)"
              :subtitle="returnSubtitle(timeline)"
            >
              <template v-slot:title>
                <h6 class="q-timeline__title">
                  {{ timeline.nome }}
                  <router-link
                    v-if="timeline.cadastro_id !== null"
                    :to="'/cadastros/' + timeline.cadastro_id"
                    style="color: rgba(0, 0, 0, 0.87); text-decoration: underline"
                    target="_blank"
                  >
                      {{ acaoFiltroTimeline(timeline.tipo) }}
                  </router-link>
                  <div v-else>{{ acaoFiltroTimeline(timeline.tipo) }}</div>
                </h6>
              </template>
              <div class="q-timeline__body" v-html="timeline.corpo"></div>
            </q-timeline-entry>
          </q-timeline>
          <q-btn
            v-if="timelines && timelines.length > 0"
            @click="loadmore()"
            :label="'Carregar mais ' + paging.page + '/' + paging.pageCount"
            class="text-white bg-primary float-right"
            style="margin: 0 25px 15px 0"
            :disable="btnDisabled || paging.page >= paging.pageCount"
            :loading="timelineAction.loadingTimeline"
          >
            <template v-slot:loading>
              <q-spinner color="white" size="1em" />
            </template>
          </q-btn>
        </div>
      </q-scroll-area>
    </q-drawer>
  </div>
</template>

<script>
import axios from "@/axios-auth";
const moment = require('moment');

export default {
  name: 'timeline-global',
  data() {
    return {
      btnDisabled: false,
      timelineFilter: [],
      icones: {
        table: 'list',
        key: 'vpn_key',
        upload: 'cloud_upload',
        trash: 'delete_forever',
        envelope: 'mail_outline',
        plus: 'add',
        pencil: 'mode_edit',
        money: 'monetization_on',
        print: 'print',
        file: 'description',
        ticket: 'local_activity',
        receipt: 'receipt',
        box: 'fa fa-archive',
        money_off: 'money_off',
        like: 'thumb_up_alt',
        redeem: 'redeem',
        search: 'search',
        phone: 'phone',
        error: 'error',
      },
      selected: 'Todos',
      edit: false,
      editor: {
        corpo: '',
      },
      erroEditor: false,
      usersOptions: [],
      loadingAutoComplete: false,
      userIdFilter: null
    };
  },
  computed: {
    paging() {
      return this.$store.getters.pagingTimeline;
    },
    timelineAction() {
      return this.$store.getters.timelineAction;
    },
    timelines() {
      return this.$store.getters.timelines;
    },
    filtersTimeline() {
      return this.$store.getters.filtersTimeline;
    },
    cadastroReturn() {
      return this.$store.getters.cadastroReturn;
    },
    OpenDrawerTimelineGlobal: {
      get() {
        return this.$store.getters.OpenDrawerTimelineGlobal;
      },
      set() {},
    },
    dtini: {
      get() {
        return this.filtersTimeline.dtini.replace(/-/g, '/');
      },
    },
    dtfim: {
      get() {
        return this.filtersTimeline.dtfim.replace(/-/g, '/');
      },
    },
  },
  methods: {
    timelineFilterResult(value, update) {
      update(() => {
        const groupsKeys = Object.keys(this.timelineAction.groups);
        this.timelineFilter = groupsKeys
          .filter(group => group.toLowerCase().indexOf(value.toLowerCase()) > -1)
          .map(group => {
            return {
              label: this.timelineAction.groups[group],
              value: group
            };
          });
      });
    },
    returnSubtitle(timeline) {
      const date = (
        'em ' +
        moment(timeline.created).format('DD/MM/YYYY') +
        ' às ' +
        moment(timeline.created).format('HH:mm') +
        'h'
      );

      const timelineGroupTitle = this.timelineGroupTitle(timeline.tipo);
      if (timelineGroupTitle) {
        return date + ' | ' + timelineGroupTitle;
      }

      return date;
    },
    toggleTimeline() {
      if (this.OpenDrawerTimelineGlobal) {
        this.$store.commit('OpenDrawerTimelineGlobal', false);
        this.resetFilters();
        return;
      }
      this.openTimeline();
    },
    chamaTodos() {
      this.resetFilters();
      this.$store.dispatch('fetchTimelines');
      this.selected = 'Todos';
    },
    getIcone(value) {
      return this.icones[value] ?? value;
    },
    openTimeline() {
      this.filtersTimeline.dtini = moment().startOf('month').subtract(1, 'year').format('DD/MM/YYYY');
      this.filtersTimeline.dtfim = moment().endOf('month').format('DD/MM/YYYY');

      this.$store.dispatch('fetchTimelines');
    },
    loadmore() {
      if (this.paging.page >= this.paging.pageCount) {
        this.btnDisabled = true;
        return;
      }
      this.paging.page += 1;
      this.$store.dispatch('fetchTimelines', this.paging.page);
    },
    acaoFiltroTimeline(tipo) {
      if (this.timelineAction.tipos[tipo] !== undefined) {
        return this.timelineAction.tipos[tipo].label;
      }
    },
    timelineGroupTitle(timelineType) {
      const types = Object.values(this.timelineAction.tipos);
      const type = types.find(type => type.value === timelineType);
      if (!type) return false;

      return this.timelineAction.groups[type.group];
    },
    setTimelineUser(cadastro) {
      this.filtersTimeline.userId = cadastro ? cadastro.user.id : null;
      this.$store.dispatch('fetchTimelines');
    },
    setTimelineGroup(group) {
      this.filtersTimeline.group = group;
      this.$store.dispatch('fetchTimelines');

      this.selected = group ?? 'Todos';
    },
    add() {
      this.$store.dispatch('openObs', true);
      this.$store.dispatch('isTimeGlobal', true);
    },
    alterarData() {
      if (this.timelineAction.loadingTimeline) return;

      this.$refs.qDateDtini.hide();
      this.$refs.qDateDtfim.hide();

      this.$store.dispatch('fetchTimelines');
    },
    resetFilters() {
      this.$root.$emit('limpar');
      this.$store.commit('filtersTimeline', {
        dtini: moment().startOf('month').subtract(1, 'year').format('DD/MM/YYYY'),
        dtfim: moment().endOf('month').format('DD/MM/YYYY'),
        userId: null,
        group: null,
      });
    },
    // Autocomplete methods
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.loadingAutoComplete = true;
        axios.get("/users").then(res => {
          this.loadingAutoComplete = false;
          this.usersOptions = res.data.data.cadastros;
        });
      });
    },
    formatDisplay(result) {
      return result.user.nome;
    },
    displayMontar(value) {
      return value ? value.user.nome : "";
    },
  },
};
</script>
