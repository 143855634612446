<template>
  <q-page id="nota-fiscal">
    <div class="q-pa-md">

      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoNotaFiscal"></sem-permissao>
        <div class="col-12" v-if="isPermissoNotaFiscal">
          <q-table
            row-key="id"
            selection="multiple"
            title="Notas Fiscais"
            no-data-label="Nenhum dado"
            loading-label="Carregando dados"
            no-results-label="Nenhum resultado obtido"
            :bottom="false"
            :data="notasFiscais"
            :columns="columnsTable"
            :pagination.sync="pagination"
            :loading="loadingNotasFiscais"
            :selected.sync="notasFiscaisSelecionadas"
          >
            <template v-slot:bottom></template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td auto-width>
                  <q-checkbox v-model="props.selected" color="secondary" />
                </q-td>
                <q-td align="left" key="movconta_id" v-show="visibleColumns.includes('movconta_id')">
                  {{ props.row.movconta.id }}
                </q-td>
                <q-td align="left" key="dtmovimento" v-show="visibleColumns.includes('dtmovimento')">
                  {{ new Date(props.row.dtmovimento) | formatDate }}
                </q-td>
                <q-td align="left" key="dtemissao" v-show="visibleColumns.includes('dtemissao')">
                  <span v-if="props.row.dtemissao">{{ new Date(props.row.dtemissao) | formatDate }}</span>
                </q-td>
                <q-td align="left" key="status" v-show="visibleColumns.includes('status')">
                    <span class="text-red" v-if="!props.row.ativo && props.row.status !== 5">
                      (Cancelada)
                    </span>
                    {{ statusNotaList[props.row.status] }}<br/>
                    <span v-if="props.row.observacao && props.row.status == 6" class="bg-negative text-white q-pa-xs">
                     {{ props.row.observacao && props.row.observacao.length > 35
                          ? props.row.observacao.substr(0, 35) + "..."
                          : props.row.observacao
                      }}
                    </span>
                    <q-tooltip
                      v-if=" props.row.observacao && props.row.observacao.length > 35"
                      >
                      {{ props.row.observacao }}
                    </q-tooltip>

                </q-td>
                <q-td align="left" key="cadastro_nome" v-show="visibleColumns.includes('cadastro_nome')">
                  <router-link
                    style="text-decoration: none;color: #000"
                    :to="props.row.cadastro ? '/cadastros/' + props.row.cadastro.id : ''"
                  >
                    {{
                      props.row.cadastro
                        ? props.row.cadastro.nome.length > 40
                          ? props.row.cadastro.nome.substr(0, 40) + "..."
                          : props.row.cadastro.nome
                        : "Sem Cadastro"
                    }}
                    <q-tooltip
                      v-if=" props.row.cadastro && props.row.cadastro.nome && props.row.cadastro.nome.length > 40"
                      >
                      {{ props.row.cadastro.nome }}
                    </q-tooltip>
                  </router-link>
                </q-td>
                <q-td align="left" key="descricao" v-show="visibleColumns.includes('descricao')">
                  {{
                    props.row.movconta &&
                    props.row.movconta.descricao &&
                     props.row.movconta.descricao.length > 40
                          ? props.row.movconta.descricao.substr(0, 40) + "..."
                          : props.row.movconta.descricao
                    }}
                  <q-tooltip
                      v-if=" props.row.movconta && props.row.movconta.descricao && props.row.movconta.descricao.length > 40"
                      >
                      {{ props.row.movconta.descricao }}
                    </q-tooltip>
                </q-td>
                <q-td align="right" key="valor" v-show="visibleColumns.includes('valor')">
                  R$ {{ props.row.valor_nfse | currency }}
                </q-td>
                <q-td align="right" key="numero_nfse" v-show="visibleColumns.includes('numero_nfse')">
                  {{ props.row.numero_nfse }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora" key="tdFora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="detalhar(props.row)"
                        size="12px"
                        color="primary"
                        text-color="white"
                        icon="info"
                      ></q-btn>
                      <q-btn
                        :disable="!props.row.url_aol"
                        color="white"
                        text-color="black"
                        icon="print"
                        size="12px"
                        type="a"
                        :href="props.row.url_aol"
                        target="_blank"
                      ></q-btn>
                      <q-btn
                        :disable="!props.row.url_xml"
                        color="white"
                        text-color="black"
                        icon="fa fa-file-code-o"
                        size="12px"
                        type="a"
                        :href="props.row.url_xml"
                        target="_blank"
                      ></q-btn>
                      <q-btn
                        @click="editar(props.row)"
                        size="12px"
                        color="green"
                        text-color="white"
                        icon="edit"
                      ></q-btn>
                      <q-btn
                        :disable="!props.row.ativo"
                        @click="cancelar(props.row)"
                        color="negative"
                        text-color="white"
                        icon="delete_forever"
                        size="12px"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="detalhar(props.row)"
                      size="12px"
                      color="primary"
                      text-color="white"
                      icon="info"
                    />
                    <q-btn
                      :disable="!props.row.url_aol"
                      color="white"
                      text-color="black"
                      icon="print"
                      size="12px"
                      type="a"
                      :href="props.row.url_aol"
                      target="_blank"
                    />
                    <q-btn
                      :disable="!props.row.url_xml"
                      color="white"
                      text-color="black"
                      icon="fa fa-file-code-o"
                      size="12px"
                      type="a"
                      :href="props.row.url_xml"
                      target="_blank"
                    />
                    <q-btn
                      @click="editar(props.row)"
                      size="12px"
                      color="green"
                      text-color="white"
                      icon="edit"
                    />
                    <q-btn
                      :disable="!props.row.ativo"
                      @click="cancelar(props.row)"
                      color="negative"
                      text-color="white"
                      icon="delete_forever"
                      size="12px"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top>
              <!-- <q-btn
                flat
                round
                dense
                :icon="'add'"
                class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0"
                @click="modal()"
                /> -->
              <div class="col-1 q-table__title">Notas Fiscais</div>
              <q-btn
                id="registrarNotas"
                label="Registrar Notas"
                no-caps
                outline
                dense
                color="blue"
                class="q-ml-sm"
                @click="gerarRemessa()"
              />
              <q-btn
                v-if="allNotaFiscal"
                flat
                dense
                no-wrap
                v-ripple
                color="secondary"
                no-caps
                :label="'Selecionar todas as ' + pagingNotasFiscais.count + ' notas fiscais'"
                class="q-ml-sm q-px-md"
                :class="{'bg-white text-blue-7': !allNotasFiscais, 'bg-green-7 text-white': allNotasFiscais}"
                clickable
                @click="setAllNotasFiscais"
              />
              <q-space />

              <div id="notasFiltros" class="relative-position row items-center">
              <q-btn
                flat
                dense
                no-wrap
                no-caps
                v-ripple
                color="primary"
                style="margin-right: 10px"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                :label="parseInt(filtrarPorEmissao) ? 'Emissão' : 'Vencimento'"
                v-if="$q.screen.gt.xs"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Data de</q-item-section
                      >
                    </q-item>
                    <q-item
                      :class="{ active_status: !Boolean(parseInt(filtrarPorEmissao)) }"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="filtrarPorEmissao = '0'"
                    >
                      <q-item-section>Vencimento</q-item-section>
                    </q-item>
                    <q-item
                      :class="{ active_status: Boolean(parseInt(filtrarPorEmissao)) }"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="filtrarPorEmissao = '1'"
                    >
                      <q-item-section>Emissão</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-btn
                v-if="hasVideo"
                dense
                flat
                color="bg-primary text-blue-7"
                @click="modalIntro"
                icon="ondemand_video"
                style="margin-right: 10px"
                >
                <template v-slot:default>
                  <q-tooltip>Introdução às Notas Fiscais</q-tooltip>
                </template>
              </q-btn>
              <date-picker-notas-fiscais />
              <q-btn
                v-if="statusNotaList"
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="statusNotaList[filtroStatusNotaFiscal]"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Status</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': filtroStatusNotaFiscal == key}"
                      v-for="(val, key) in statusNotaList"
                      :key="key"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="changeFilterStatus(key)"
                    >
                      <q-item-section>{{val}} | {{ totaisStatusNotaList[`status_${key}`] || 0 }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <q-select
                dense
                multiple
                borderless
                emit-value
                map-options
                options-dense
                option-value="name"
                display-value="Colunas"
                style="min-width: 100px"
                :options="optionsVisibleColumns"
                v-model="visibleColumns"
              />
            </div>
            </template>
          </q-table>

          <div>
            <q-page-sticky position="top-right" :offset="[0, 90]">
              <q-btn
                id="notasOpcoesAvancadas"
                :color="openOpcoesAvancadasNotaFiscal ? 'red-7' : 'blue-7'"
                :icon="openOpcoesAvancadasNotaFiscal ? 'close' : 'settings'"
                class="rotate-0"
                style="border-top-left-radius: 50px;border-bottom-left-radius: 50px;"
                @click="openOpcoesAvancadasNotaFiscal = !openOpcoesAvancadasNotaFiscal"
              >
                <q-tooltip
                  :content-class="
                    openOpcoesAvancadasNotaFiscal
                      ? 'bg-negative text-white text-center'
                      : 'bg-secondary text-white text-center'
                  "
                  >{{
                    openOpcoesAvancadasNotaFiscal ? "Fechar Opções Avançadas" : "Opções Avançadas"
                  }}</q-tooltip
                >
              </q-btn>
            </q-page-sticky>

            <q-drawer
              side="right"
              v-model="openOpcoesAvancadasNotaFiscal"
              bordered
              :width="300"
              :breakpoint="500"
              behavior="default"
              content-class="bg-white"
            >
              <q-scroll-area class="fit">
                <div class="q-pa-sm">
                  <div class="q-table__title">Opções Avançadas</div>
                  <div>
                    <q-list
                      class="rounded-borders"
                      style="max-width: 350px; margin-left: -10px;width: 300px;"
                    >
                      <q-item-label header></q-item-label>

                      <q-item clickable v-ripple @click.exact="marcarRemessa()">
                        <q-item-section>
                          <q-item-label lines="1">Marcar para Remessa</q-item-label>
                          <q-item-label caption>Marcar notas fiscais para geração de remessa</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple @click.exact="desmarcarRemessa()">
                        <q-item-section>
                          <q-item-label lines="1">Desmarcar para Remessa</q-item-label>
                          <q-item-label caption>Desmarcar notas fiscais para geração de remessa</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple @click.exact="gerarRemessa()">
                        <q-item-section>
                          <q-item-label lines="1">Registrar notas</q-item-label>
                          <q-item-label caption>Realiza o registro das notas na prefeitura</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple @click.exact="atualizarRemessa()">
                        <q-item-section>
                          <q-item-label lines="1">Atualizar notas</q-item-label>
                          <q-item-label caption>Procura por novas atualizações da nota na prefeitura</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple @click.exact="abrirOpcoes()">
                        <q-item-section>
                          <q-item-label lines="1">Opções</q-item-label>
                          <q-item-label caption>Configurações de nota fiscal</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple @click.exact="notificarCliente()">
                        <q-item-section>
                          <q-item-label lines="1">Enviar por email</q-item-label>
                          <q-item-label caption>Enviar nota fiscal para o email do cliente</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple @click.exact="openAlterarStatusDialog()">
                        <q-item-section>
                          <q-item-label lines="1">Alterar Status em Massa</q-item-label>
                          <q-item-label caption>Alterar manualmente o status das notas fiscais selecionadas</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-ripple @click.exact="openModalExportarXml()">
                        <q-item-section>
                          <q-item-label lines="1">Exportar XML's</q-item-label>
                          <q-item-label caption>Exportar os XML's das notas</q-item-label>
                        </q-item-section>
                      </q-item>

                    </q-list>
                  </div>
                </div>
              </q-scroll-area>
            </q-drawer>
          </div>

        </div>

      </div>
    </div>

    <modal-notas-fiscais-opcoes></modal-notas-fiscais-opcoes>
    <modal-detalhar-nota-fiscal></modal-detalhar-nota-fiscal>
    <modal-editar-nota-fiscal></modal-editar-nota-fiscal>

    <q-dialog v-model="alterarStatusDialog" persistent>
      <q-card style="max-width: 400px; width: 90%;">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Status em Massa</div>
          <q-space />
          <q-btn
            class="q-ml-lg"
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeAlterarStatusDialog()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="q-pa-md">
            <q-select
              label="Status"
              v-model="statusAlterar"
              :options="optionsStatus"
              map-options
              emit-value
              bottom-slots
              :error="errorsAlterar.status ? true : false"
            >
              <template v-slot:error>
                <span
                  v-for="(error, i) in errorsAlterar.status"
                  :key="error + i"
                  >{{ error }}</span
                >
              </template>
            </q-select>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <div class="q-pa-md">
            <q-btn
              @click="alterarStatus()"
              :loading="loadingAlterarStatus"
              label="Salvar"
              color="positive"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </div>
        </q-card-actions>

      </q-card>
    </q-dialog>

    <q-dialog v-model="modalExportarXml">
      <q-card>
        <q-card-section class="row items-center">
          <div class="text-h6">Exportar XML</div>
          <q-space />
          <q-btn
            class="q-ml-lg"
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeAlterarStatusDialog"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <p>Selecione o mês das notas fiscais que deseja exportar</p>

          <q-input
            label="Selecione um mês"
            maxlength="7"
            :value="dtXmls"
            @click="$refs.monthPicker.show()"
          >
            <template v-slot:append>
              <q-icon name="event">
                <q-popup-proxy ref="monthPicker" transition-show="scale" transition-hide="scale">
                  <q-date
                    minimal
                    emit-immediately
                    default-view="Years"
                    v-model="dtXmls"
                    mask="MM/YYYY"
                    @input="checkValue"
                  />
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-card-section>

        <q-card-section class="flex justify-end">
          <q-btn
            label="Exportar"
            color="green"
            @click="exportarXml"
          />
        </q-card-section>
      </q-card>

      <div>

      </div>
    </q-dialog>

    <card-total-itens
      id="notasTotal"
      tipo="notas_fiscais"
      :total="totalNotasFiscaisCard"
      :selecionado="totalSelecionadoCard"
    />

  </q-page>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from 'vuex';
import { Dialog, Loading, Notify } from 'quasar';

export default {
  name: "notas-fiscais",
  meta: {
    title: "DSB | Nota Fiscal"
  },
  data() {
    return {
      openOpcoesAvancadasNotaFiscal: false,
      visibleColumns: [
        "movconta_id",
        "dtmovimento",
        "dtemissao",
        "status",
        "cadastro_nome",
        "descricao",
        "valor",
        "numero_nfse",
      ],
      columns: [
        {
          name: "movconta_id",
          label: "ID",
          align: "left",
          field: row => row.movconta_id,
          sortable: true
        },
        {
          name: "dtmovimento",
          label: "Data de Movimento",
          align: "left",
          field: row => row.dtmovimento,
          sortable: true,
        },
        {
          name: "dtemissao",
          label: "Data de Emissão",
          align: "left",
          field: row => row.dtemissao,
          sortable: true,
        },
        {
          name: "status",
          label: "Status",
          align: "left",
          field: row => row.status,
          sortable: true,
        },
        {
          name: "cadastro_nome",
          label: "Nome do Cadastro",
          align: "left",
          field: row => row.cadastro.nome,
          sortable: true,
        },
        {
          name: "descricao",
          label: "Descrição",
          align: "left",
          field: row => row.movconta.descricao,
          sortable: true,
        },
        {
          name: "valor",
          label: "Valor",
          align: "right",
          field: row => row.valor_nfse,
          sortable: true,
        },
        {
          name: "numero_nfse",
          label: "Número da Nota",
          align: "right",
          field: row => row.numero_nfse,
          sortable: true,
        },
        {
          name: "tdFora",
        },
      ],
      pagination: {
        sortBy: "id",
        descending: true,
        rowsPerPage: 0,
        page: 1,
      },
      allNotaFiscal: false,
      alterarStatusDialog: false,
      statusAlterar: null,
      loadingAlterarStatus: false,
      errorsAlterar: [],
      hasVideo: false,
      modalExportarXml: false,
      dtXmls: ""
    };
  },
  methods: {
    detalhar(notaFiscal) {
      this.$store.dispatch("detalharNotasFiscais", notaFiscal);
      this.$store.commit("notasFiscaisSelecionadas", []);
    },
    editar(notaFiscal) {
      this.$store.dispatch("editarNotasFiscais", JSON.parse(JSON.stringify(notaFiscal)));
    },
    abrirOpcoes() {
      this.$store.dispatch('loadOpcoesNotaFiscal');
    },
    marcarRemessa() {
      this.requestRemessa('marcar', 'marcar as notas para remessa');
    },
    desmarcarRemessa() {
      this.requestRemessa('desmarcar', 'desmarcar as notas para remessa');
    },
    gerarRemessa() {
      Dialog.create({
        title: "Deseja realmente registrar notas?",
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        const data = {
          tipo: 'gerar',
        };

        axios
          .post("/notas-fiscais/remessa", data)
          .then(res => {
            if (!res.data.success) {
              throw res.data;
            }

            this.$store.dispatch("resetNotasFiscais");
            this.$store.dispatch("loadNotasFiscais");

            Dialog.create({
              title: res.data.message,
              class: "bg-positive text-white",
              ok: {
                "text-color": "green",
                color: "white",
              },
            });
          })
          .catch(() => {
            Dialog.create({
              title: "Houve um erro ao registrar notas!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white",
              },
            });
          });
      });
    },
    atualizarRemessa() {
      axios
        .post("/notas-fiscais/atualizar")
        .then(res => {
          Dialog.create({
            title: res.data.message,
            class: "bg-positive text-white",
            ok: {
              "text-color": "green",
              color: "white",
            },
          });
        })
        .catch(() => {
          Dialog.create({
            title: "Houve um erro ao atualizar notas!",
            class: "bg-negative text-white",
            ok: {
              "text-color": "red",
              color: "white",
            },
          });
        });
    },
    notificarCliente(confirmar = false, message) {
      let dialogMessage = "Deseja enviar o email para os clientes?";

      if (message) {
        dialogMessage = message;
      }

      Dialog.create({
        title: dialogMessage,
        cancel: "Cancelar",
        persistent: true,
      })
      .onOk(() => {
        const data = {
          ids: this.notasFiscaisSelecionadas.map(nota => nota.id),
          all: this.allNotasFiscais,
          dtini: this.filtrosNotasFiscais.dtini,
          dtfim: this.filtrosNotasFiscais.dtfim,
          confirmar: confirmar,
          dtemissao: this.filtrarPorEmissao,
          cadastro_id: this.filtrosNotasFiscais.cadastroId,
        };

        axios
          .post("/notas-fiscais/notificar", data)
          .then(res => {
            if (!res.data.success) {
              Dialog.create({
                title: res.data.message,
                class: "bg-orange text-white",
                ok: {
                  "text-color": "orange",
                  color: "white",
                },
              });
              return;
            }

            if (res.data.confirmar) {
              return this.notificarCliente(true, res.data.message);
            }

            Dialog.create({
              title: res.data.message,
              class: "bg-positive text-white",
              ok: {
                "text-color": "green",
                color: "white",
              },
            });
          })
          .catch(() => {
            Dialog.create({
              title: "Houve um erro ao notificar os clientes!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white",
              },
            });
          });
      });
    },
    exportarXml() {
      if (!this.modalExportarXml) return;

      const dtNotasFiscais = "01/" + this.dtXmls;

      Loading.show();

      axios
        .post('/notas-fiscais/exportar-xml', { dtNotasFiscais }, {timeout: 5000})
        .then(res => {
          Loading.hide();
          if (!res.data.success) {
            this.$q.dialog({
              title: res.data.message,
              class: 'bg-orange text-white',
              ok: {
                'text-color': 'orange',
                color: 'white',
              },
            });
            return;
          }

          this.$q.dialog({
            title: res.data.message,
            class: 'bg-positive text-white',
            ok: {
              'text-color': 'green',
              color: 'white',
            },
          });

          if (res.data.url) {
            window.open(res.data.url, "_blank");
          }

          this.modalExportarXml = false;
        })
        .catch(() => {
          Loading.hide();
          this.modalExportarXml = false;
          this.$q.dialog({
            title: 'Exportação em andamento, logo estarão disponíveis na lista de arquivos.',
            class: 'bg-positive text-white',
            ok: {
              'text-color': 'green',
              color: 'white',
            },
          });
          this.$router.push("/arquivos");
        });
    },
    requestRemessa(tipo, acao, dados = {}) {
      Dialog.create({
        title: `Deseja realmente ${acao}?`,
        message: `${this.quantidadeSelecionados} nota(s) serão afetadas`,
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        const data = {
          ...dados,
          tipo: tipo,
          ids: this.notasFiscaisSelecionadas.map(nota => nota.id),
          all: this.allNotasFiscais,
          dtini: this.filtrosNotasFiscais.dtini,
          dtfim: this.filtrosNotasFiscais.dtfim,
          status: this.filtroStatusNotaFiscal,
          cadastro_id: this.filtrosNotasFiscais.cadastroId,
          dtemissao: this.filtrarPorEmissao,
        };

        axios
          .post("/notas-fiscais/remessa", data)
          .then(res => {
            if (!res.data.success) {
              throw res.data;
            }

            this.$store.dispatch("resetNotasFiscais");
            this.$store.dispatch("loadNotasFiscais");
            this.$store.commit("allNotasFiscais", false);

            Dialog.create({
              title: res.data.message,
              class: "bg-positive text-white",
              ok: {
                "text-color": "green",
                color: "white",
              },
            });

            this.closeAlterarStatusDialog();
          })
          .catch(() => {
            Dialog.create({
              title: `Houve um erro ao ${acao}!`,
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white",
              },
            });
          });
      });
    },
    cancelar(notaFiscal) {
      Dialog.create({
        title: "Digite a justificativa",
        cancel: "Cancelar",
        persistent: true,
        prompt: {
          model: "",
          type: "text",
          isValid: val => val != "" && val.length <= 300,
        },
      })
      .onOk((justificativa) => {
        if (justificativa == "" || justificativa.length > 600) {
          Dialog.create({
            title: "A justificativa é obrigatória e deve ter no máximo 300 caracteres!",
            class: "bg-warning text-white",
            ok: {
              "text-color": "orange",
              color: "white"
            }
          });
          return;
        }

        const data = {
          id: notaFiscal.id,
          justificativa: justificativa,
        };

        axios
          .post("/notas-fiscais/cancelar", data)
          .then(res => {
            if (!res.data.success) {
              throw res.data;
            }

            this.$store.dispatch("resetNotasFiscais");
            this.$store.dispatch("loadNotasFiscais");

            Dialog.create({
              title: res.data.message,
              class: "bg-positive text-white",
              ok: {
                "text-color": "green",
                color: "white",
              },
            });
          })
          .catch(() => {
            Dialog.create({
              title: "Houve um erro ao cancelar a nota!",
              class: "bg-negative text-white",
              ok: {
                "text-color": "red",
                color: "white",
              },
            });
          });
      });
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.$store.dispatch("loadNotasFiscais");
      }
    },
    setAllNotasFiscais() {
      this.$store.dispatch("setAllNotasFiscais");
    },
    changeFilterStatus(status, valor) {
      if (parseInt(status) === 7) { // Registrado
        this.$store.commit("setBuscarPorEmissaoNotasFiscais", 1);
      }

      this.$store.dispatch("changeFilterStatusNotaFiscal", {
        status: status,
      });
      this.allNotaFiscal = false;
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "notas_fiscais");
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais || this.buscandoUser) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "notas_fiscais");

      if (!this.videosManuais.video_notas_fiscais) {
        this.modalIntro();
      }
    },
    openAlterarStatusDialog() {
      this.alterarStatusDialog = true;
    },
    openModalExportarXml() {
      this.modalExportarXml = true;
    },
    closeAlterarStatusDialog() {
      this.alterarStatusDialog = false;
      this.statusAlterar = null;
    },
    closeModalExportarXml() {
      this.modalExportarXml = false;
    },
    alterarStatus() {
      if (!this.statusAlterar) {
        this.errorsAlterar.status = [["O status é obrigatório"]];
        return;
      }

      this.requestRemessa('atualizar-status', 'atualizar status das notas', {
        novo_status: this.statusAlterar,
      });
    },
    loadVisibleColumns() {
      const visibleColumns = JSON.parse(localStorage.getItem('notas_fiscais__visibleColumns'));

      if (visibleColumns) {
        this.visibleColumns = visibleColumns;
      }
    },
    resetFiltrosNotasFiscais() {
      this.$store.commit("filtrosNotasFiscais", {
        ...JSON.parse(JSON.stringify(this.filtrosNotasFiscais)),
        busca: "",
      });
    },
    checkValue(val, reason, details) {
      if (reason === 'month') {
        this.$refs.monthPicker.hide();
      }
    }
  },
  computed: {
    ...mapGetters([
      "isPermissoNotaFiscal",
      "notasFiscais",
      "loadingNotasFiscais",
      "statusNotaList",
      "allNotasFiscais",
      "counterNotasFiscais",
      "filtroStatusNotaFiscal",
      "pagingNotasFiscais",
      "filtrosNotasFiscais",
      "videosManuais",
      "buscandoUser",
      "metadadosNotasFiscais",
      "totaisStatusNotaList",
      "buscarPorEmissaoNotasFiscais",
      "manuaisDisponiveis"
    ]),
    notasFiscaisSelecionadas: {
      get() {
        return this.$store.getters.notasFiscaisSelecionadas;
      },
      set(value) {
        this.$store.dispatch("novaNotaSelecionada", value);
      }
    },
    optionsStatus() {
      return Object.keys(this.statusNotaList)
        .map(statusKey => ({
          label: this.statusNotaList[statusKey],
          value: statusKey,
        }))
        .filter(status => status.value !== 'all');
    },
    quantidadeSelecionados() {
      if (!this.allNotasFiscais) {
        return this.notasFiscaisSelecionadas.length;
      }

      return this.pagingNotasFiscais.count;
    },
    optionsVisibleColumns() {
      return this.columns.filter(column => column.name !== 'tdFora');
    },
    columnsTable() {
      return this.columns.filter(column => this.visibleColumns.includes(column.name));
    },
    totalNotasFiscaisCard() {
      const total = {
        valor: this.metadadosNotasFiscais.valor_total || 0,
        quantidade: this.pagingNotasFiscais.count || 0,
      };

      return total;
    },
    totalSelecionadoCard() {
      const selecionado = {
        valor: 0.00,
        quantidade: 0,
      };

      if (this.allNotasFiscais) {
        selecionado.valor = this.metadadosNotasFiscais.valor_total || 0;
        selecionado.quantidade = this.pagingNotasFiscais.count || 0;

        return selecionado;
      }

      const campoValor = this.filtrosDrp === "Liquidado" ? "valor_liquidado" : "valor";

      selecionado.valor = this.notasFiscaisSelecionadas.reduce((total, notaFiscal) => total + notaFiscal.valor_nfse, 0.00);
      selecionado.quantidade = this.notasFiscaisSelecionadas.length;

      return selecionado;
    },
    filtrarPorEmissao: {
      get() {
        return String(this.buscarPorEmissaoNotasFiscais);
      },
      set(value) {
        this.$store.commit("setBuscarPorEmissaoNotasFiscais", value);
        this.$store.dispatch("resetNotasFiscais");
        this.$store.dispatch("loadNotasFiscais");
      },
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(query) {
        const filtros = this.filtrosNotasFiscais;
        filtros.cadastroId = query.cadastro_id;
        this.$store.commit("filtrosNotasFiscais", filtros);
      },
    },
    "notasFiscaisSelecionadas"(value) {
      if (value.length === 0) {
        this.allNotaFiscal = false;
      }

      if (value.length >= 10) {
        this.allNotaFiscal = true;
      }
    },
    visibleColumns() {
      localStorage.setItem('notas_fiscais__visibleColumns', JSON.stringify(this.visibleColumns));
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.resetFiltrosNotasFiscais();
    this.$store.dispatch("resetNotasFiscais");
    this.$store.dispatch("loadNotasFiscais");

    this.verificarPrimeiroVideo();
    this.loadVisibleColumns();
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    this.$store.dispatch("resetNotasFiscais");
    next();
  },
};
</script>

<style lang="stylus" scoped></style>
