<template>
  <q-page id="recorrencias">
    <div class="q-pa-md">
      <q-table
        row-key="id"
        selection="multiple"
        no-data-label="Nenhum dado"
        loading-label="Carregando dados"
        no-results-label="Nenhum resultado obtido"
        dense
        :bottom="false"
        :columns="colunas"
        :loading="buscando"
        :data="recorrencias"
        :selected.sync="selecionadas"
        :pagination.sync="pagination"
        :visible-columns="colunasVisiveis"
      >
        <template v-slot:bottom></template>

        <template v-slot:body="props">
          <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
            <q-td auto-width>
              <q-checkbox v-model="props.selected" color="secondary" />
            </q-td>

            <q-td key="cadastro">
              <router-link
                style="text-decoration: none; color: black"
                :to="`/cadastros/${props.row.cadastro.id}`"
                v-if="props.row.cadastro"
              >
                {{
                  props.row.cadastro.nome.length > 40
                    ? props.row.cadastro.nome.substr(0, 40) + '...'
                    : props.row.cadastro.nome
                }}
                <q-tooltip v-if="props.row.cadastro.nome && props.row.cadastro.nome.length > 40">
                  {{ props.row.cadastro.nome }}
                </q-tooltip>
                <div v-if="props.row.cadastro.codigo">
                  {{ props.row.cadastro.codigo }}
                </div>
                <div v-if="props.row.cadastro.cpfcnpj">
                  {{ props.row.cadastro.cpfcnpj }}
                </div>
              </router-link>
              <span v-else>Sem Cadastro</span>
            </q-td>

            <q-td key="descricao" v-if="colunasVisiveis.indexOf('descricao') !== -1">
              <template v-slot:default>
                <div class="flex items-center">
                  <span class="q-mr-sm">
                    {{
                      props.row.descricao && props.row.descricao.length > 40
                        ? props.row.descricao.substr(0, 40) + '...'
                        : props.row.descricao
                    }}
                  </span>
                  <q-tooltip v-if="props.row.descricao && props.row.descricao.length > 40">
                    {{ props.row.descricao }}
                  </q-tooltip>

                  <q-badge class="q-mr-sm" color="orange-6" v-if="props.row.congelado">
                    <span style="line-height: 1.2em">
                      Congelada
                      <span v-if="props.row.congelado_dtfim">
                        <div>até {{ props.row.congelado_dtfim | formatDate }}</div>
                      </span>
                    </span>
                  </q-badge>

                  <q-badge color="orange-9" v-if="props.row.is_trial === 1">
                    <span style="line-height: 1.2em">
                      Trial
                      <div>De {{ props.row.dtini_trial | formatDate }}</div>
                      <div>até {{ props.row.dtfim_trial | formatDate }}</div>
                    </span>
                  </q-badge>
                </div>

                <q-badge
                  class="q-ml-xs"
                  outline
                  color="primary"
                  v-for="tag in props.row.cadastro.tags"
                  :key="tag.id"
                >
                    {{ tag.title }}
                </q-badge>
              </template>
            </q-td>

            <q-td v-if="colunasVisiveis.indexOf('valor') !== -1" key="valor" class="text-right">
              <span>
                R$ {{ (props.row.valor / props.row.periodo) | currency }}
                <q-tooltip v-if="props.row.periodo > 1">
                  {{ getPeriodoDescricao(props.row.periodo) }}
                  R$ {{ props.row.valor | currency }}
                </q-tooltip>
              </span>
            </q-td>

            <q-td v-if="colunasVisiveis.indexOf('valor_ativacao') !== -1" key="valor_ativacao" class="text-right">
                R$ {{ (props.row.valor_ativacao) | currency }}
            </q-td>

            <q-td v-if="colunasVisiveis.indexOf('total_contratacao') !== -1" key="total_contratacao" class="text-right">
                R$ {{ getTotalContratacao(props.row) | currency }}
            </q-td>

            <q-td v-if="colunasVisiveis.indexOf('dtini') !== -1" key="dtini" class="text-center">
              <span v-if="props.row.dtini">{{ new Date(props.row.dtini) | formatDate }}</span>
            </q-td>

            <q-td v-if="colunasVisiveis.indexOf('dtfim') !== -1" key="dtfim" class="text-center">
              <span v-if="props.row.dtfim">{{ new Date(props.row.dtfim) | formatDate }}</span>
            </q-td>

            <q-td
              v-if="colunasVisiveis.indexOf('ultimo_faturamento') !== -1"
              key="ultimo_faturamento"
              class="text-center"
            >
              <span v-if="props.row.ultimo_faturamento">
                {{ new Date(props.row.ultimo_faturamento) | formatDate }}
              </span>
            </q-td>

            <q-td
              v-if="colunasVisiveis.indexOf('cancelamento') !== -1 && filtros.status === 'cancelado'"
              key="cancelamento"
              class="text-center"
            >
              <span v-if="props.row.deactivated">
                {{ new Date(props.row.deactivated) | formatDate }}
              </span>
            </q-td>

            <q-td v-if="$q.platform.is.desktop" class="tdfora">
              <div class="divfora">
                <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                  <q-btn
                    v-if="!props.row.aprovadoem"
                    @click="aprovar(props.row)"
                    :loading="props.row.id === aprovando"
                    size="12px"
                    color="positive"
                    text-color="white"
                    icon="thumb_up_alt"
                  >
                    <q-tooltip>Aprovar</q-tooltip>
                  </q-btn>
                  <q-btn
                    size="12px"
                    color="orange"
                    text-color="white"
                    :icon="props.row.congelado ? 'reply' : 'stop'"
                    :loading="props.row.id === aprovando"
                    v-if="props.row.aprovadoem"
                    @click="congelar(props.row)"
                  >
                    <q-tooltip>{{ props.row.congelado ? 'Descongelar' : 'Congelar' }}</q-tooltip>
                  </q-btn>
                  <q-btn
                    size="12px"
                    icon="pending_actions"
                    class="bg-positive text-white"
                    @click="modalEditarTrial(props.row)"
                    v-if="utilizarTrial"
                  >
                    <q-tooltip>Período de Teste</q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="abrirModalComissoes(props.row)"
                    size="12px"
                    icon="redeem"
                    class="bg-secondary text-white"
                    v-if="utilizarComissoes"
                  >
                    <q-tooltip>Comissões</q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="modalAdicionarEditar(props.row)"
                    size="12px"
                    class="bg-primary text-white"
                    icon="edit"
                  >
                    <q-tooltip>Editar</q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="excluir(props.row)"
                    class="bg-negative text-white"
                    icon="block"
                    size="12px"
                  >
                    <q-tooltip>{{ props.row.ativo ? 'Inativar' : 'Excluir' }}</q-tooltip>
                  </q-btn>
                </div>
              </div>
            </q-td>
          </q-tr>
          <q-tr v-show="props.expand" :props="props">
            <q-td colspan="100%">
              <div class="q-py-sm q-gutter-sm">
                <q-btn
                  v-if="!props.row.aprovadoem"
                  @click="aprovar(props.row)"
                  :loading="props.row.id === aprovando"
                  size="12px"
                  color="positive"
                  text-color="white"
                  icon="thumb_up_alt"
                >
                  <q-tooltip>Aprovar</q-tooltip>
                </q-btn>
                <q-btn
                  size="12px"
                  color="orange"
                  text-color="white"
                  :icon="props.row.congelado ? 'reply' : 'stop'"
                  :loading="props.row.id === aprovando"
                  v-if="props.row.aprovadoem"
                  @click="congelar(props.row)"
                >
                  <q-tooltip>{{ props.row.congelado ? 'Descongelar' : 'Congelar' }}</q-tooltip>
                </q-btn>
                <q-btn
                  size="12px"
                  icon="pending_actions"
                  class="bg-positive text-white"
                  @click="modalEditarTrial(props.row)"
                  v-if="utilizarTrial"
                >
                  <q-tooltip>Período de Teste</q-tooltip>
                </q-btn>
                <q-btn
                  @click="abrirModalComissoes(props.row)"
                  size="12px"
                  icon="redeem"
                  class="bg-secondary text-white"
                  v-if="utilizarComissoes"
                >
                  <q-tooltip>Comissões</q-tooltip>
                </q-btn>
                <q-btn
                  @click="modalAdicionarEditar(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                >
                  <q-tooltip>Editar</q-tooltip>
                </q-btn>
                <q-btn
                  @click="excluir(props.row)"
                  class="bg-negative text-white"
                  icon="block"
                  size="12px"
                >
                  <q-tooltip>{{ props.row.ativo ? 'Inativar' : 'Excluir' }}</q-tooltip>
                </q-btn>
              </div>
            </q-td>
          </q-tr>
        </template>

        <template v-slot:top>
          <div class="col-2 q-table__title">Assinaturas</div>

          <q-btn
            v-if="todasListadasSelecionadas"
            flat
            dense
            no-wrap
            v-ripple
            color="secondary"
            no-caps
            :label="`Selecionar todas as ${paginacao.count} recorrências`"
            class="q-ml-sm q-px-md"
            :class="{
              'bg-white text-blue-7': !selecionarTodas,
              'bg-green-7 text-white': selecionarTodas,
            }"
            clickable
            @click="() => (selecionarTodas = !selecionarTodas)"
          />

          <q-space />

          <q-btn
            v-if="$q.screen.gt.xs"
            flat
            dense
            no-wrap
            v-ripple
            color="primary"
            no-caps
            :label="filtrosTipoData[filtros.tipo_data]"
            class="q-ml-sm q-px-md bg-white text-blue-7"
            style="margin-right: 10px"
          >
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">
                    Data de
                  </q-item-section>
                </q-item>
                <q-item
                  :class="{ active_status: filtros.tipo_data === key }"
                  v-for="(label, key) in filtrosTipoData"
                  :key="label"
                  clickable
                  v-close-popup
                  aria-hidden="true"
                  @click="alterarFiltroTipoData(key)"
                >
                  <q-item-section>
                    {{ label }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn-group id="assinaturasDataFiltro" rounded flat no-wrap no-caps>
            <q-btn
              class="white text-blue-7"
              clickable
              @click="changeFilterPeriodo('prev')"
              rounded
              icon="chevron_left"
              :disable="filtros.tipo_data === 'todas'"
            />
            <q-btn
              class="white text-blue-7"
              rounded
              :label="dtini"
              :disable="filtros.tipo_data === 'todas'"
            >
              <template v-slot:default>
                <q-popup-proxy ref="qDateDtini" transition-show="scale" transition-hide="scale">
                  <q-date
                    mask="DD/MM/YYYY"
                    :locale="langDate"
                    minimal
                    today-btn
                    v-model="dtini"
                    @input="value => alterarDataInicio(value)"
                  />
                </q-popup-proxy>
              </template>
            </q-btn>
            <q-btn
              class="white text-blue-7"
              rounded
              :label="dtfim"
              :disable="filtros.tipo_data === 'todas'"
            >
              <template v-slot:default>
                <q-popup-proxy ref="qDateDtfim" transition-show="scale" transition-hide="scale">
                  <q-date
                    mask="DD/MM/YYYY"
                    :locale="langDate"
                    minimal
                    today-btn
                    v-model="dtfim"
                    @input="value => alterarDataFim(value)"
                  />
                </q-popup-proxy>
              </template>
            </q-btn>
            <q-btn
              class="white text-blue-7"
              clickable
              @click="changeFilterPeriodo('next')"
              rounded
              icon="chevron_right"
              :disable="filtros.tipo_data === 'todas'"
            />
          </q-btn-group>

          <q-btn
            v-if="$q.screen.gt.xs"
            flat
            dense
            no-wrap
            v-ripple
            color="primary"
            no-caps
            :label="filtrosStatus[filtros.status]"
            class="q-ml-sm q-px-md bg-white text-blue-7"
            style="margin-right: 10px"
          >
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">
                    Status
                  </q-item-section>
                </q-item>
                <q-item
                  :class="{ active_status: filtros.status === key }"
                  v-for="(label, key) in filtrosStatus"
                  :key="label"
                  clickable
                  v-close-popup
                  aria-hidden="true"
                  @click="alterarFiltroStatus(key)"
                >
                  <q-item-section>
                    {{ label }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-select
            v-model="tagFiltro"
            @input="setTag()"
            :options="tags"
            label="Tags"
            use-chips
            multiple
            borderless
            dense
            option-value="id"
            option-label="title"
            options-selected-class="active_status"
            style="min-width: 100px;"
          />

          <q-select
            dense
            multiple
            borderless
            emit-value
            map-options
            options-dense
            display-value="Colunas"
            style="min-width: 100px"
            class="q-ml-md"
            :options="colunasVisiveisOpcoes"
            v-model="colunasVisiveis"
          />

          <q-btn
            v-if="hasVideo"
            dense
            flat
            color="bg-primary text-blue-7"
            icon="ondemand_video"
            class="q-ml-md"
            @click="modalIntro"
          >
            <template v-slot:default>
              <q-tooltip>Introdução as Assinaturas</q-tooltip>
            </template>
          </q-btn>

          <q-btn
            v-if="$q.screen.gt.xs"
            flat
            dense
            rounded
            no-wrap
            v-ripple
            class="q-ml-sm q-px-md bg-white text-black"
            no-caps
            icon="more_vert"
            style="margin-right: 10px"
          >
            <q-menu anchor="top right" self="top right">
              <q-list class="text-grey-8" style="min-width: 100px">
                <q-item aria-hidden="true">
                  <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">
                    Mais Opções
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup aria-hidden="true" @click="atualizar()">
                  <q-item-section>Atualizar</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </template>
      </q-table>

      <div class="col-12 col-md-12 text-center" v-if="buscando">
        <br />
        <q-spinner color="blue-7" size="3em" />
      </div>

      <q-page-sticky position="top-right" :offset="[0, 90]">
        <q-btn
          id="assinaturasOpcoesAvancadas"
          :color="drawerRight ? 'red-7' : 'blue-7'"
          :icon="drawerRight ? 'close' : 'settings'"
          class="rotate-0"
          style="border-top-left-radius: 50px; border-bottom-left-radius: 50px"
          @click="drawerRight = !drawerRight"
        >
          <q-tooltip
            :content-class="
              drawerRight
                ? 'bg-negative text-white text-center'
                : 'bg-secondary text-white text-center'
            "
            >{{ drawerRight ? 'Fechar Opções Avançadas' : 'Opções Avançadas' }}</q-tooltip
          >
        </q-btn>
      </q-page-sticky>

      <q-drawer
        side="right"
        v-model="drawerRight"
        bordered
        :width="280"
        :breakpoint="500"
        behavior="default"
        content-class="bg-white"
      >
        <q-scroll-area class="fit">
          <div class="q-pa-sm">
            <div class="q-table__title">Opções Avançadas</div>

            <div>
              <q-list
                class="rounded-borders"
                style="max-width: 350px; margin-left: -10px; width: 300px"
              >
                <q-item-label header></q-item-label>

                <q-item
                  clickable
                  v-ripple
                  :disable="selecionadas.length === 0"
                  @click.exact="faturar"
                >
                  <q-item-section>
                    <q-item-label lines="1">Faturamento múltiplo</q-item-label>
                    <q-item-label caption>Faturar múltiplas recorrências</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item
                  clickable
                  v-ripple
                  :disable="selecionadas.length === 0"
                  @click.exact="abrirModalCongelarMassa"
                >
                  <q-item-section>
                    <q-item-label lines="1">Congelar</q-item-label>
                    <q-item-label caption>Congelar múltiplas recorrências</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item
                  clickable
                  v-ripple
                  :disable="selecionadas.length === 0"
                  @click.exact="descongelarMassa"
                >
                  <q-item-section>
                    <q-item-label lines="1">Descongelar</q-item-label>
                    <q-item-label caption>Descongelar múltiplas recorrências</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item
                  clickable
                  v-ripple
                  :disable="selecionadas.length === 0"
                  @click.exact="abrirModalReajusteMassa"
                >
                  <q-item-section>
                    <q-item-label lines="1">Reajuste</q-item-label>
                    <q-item-label caption>Realizar o ajuste do valor</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-scroll-area>
      </q-drawer>
    </div>

    <!-- Opções Recorrências -->
    <modal-nova-recorrencia :opcoes="opcoes" @atualizar="atualizar" @atualizar-recorrencia="modalAdicionarEditar" />
    <modal-comissoes-recorrencia @atualizar="atualizar" @abrirModal="abrirModalComissoes" />
    <modal-congelar-recorrencia @atualizar="atualizar" />
    <modal-trial-recorrencia @atualizar="atualizar" />
    <!-- Opções Avançadas -->
    <modal-faturar />
    <modal-congelar-recorrencias-massa @atualizar="atualizar" />
    <modal-reajuste-recorrencias-massa @atualizar="atualizar" />
    <!-- Outros componentes -->
    <card-total-itens
      tipo="recorrencias"
      :total="totalRecorrenciasCard"
      :selecionado="totalSelecionadoCard"
    />
  </q-page>
</template>

<script>
import moment from 'moment';
import { Loading } from 'quasar';
import { mapGetters } from 'vuex';

import axios from '@/axios-auth';
import { bus } from '@/main';

export default {
  name: 'recorrencias',
  meta: {
    title: 'DSB | Assinaturas',
  },
  data() {
    return {
      // Selecionadas
      selecionadas: [],
      todasListadasSelecionadas: false,
      selecionarTodas: false,
      // Tabela
      buscando: false,
      recorrencias: [],
      pagination: {
        sortBy: 'cadastro',
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      colunasVisiveis: JSON.parse(localStorage.getItem('recorrencias__colunasVisiveis')) || [
        'cadastro',
        'descricao',
        'dtini',
        'dtfim',
        'ultimo_faturamento',
        'valor',
        'valor_ativacao',
        'total_contratacao',
        'cancelamento'
      ],
      paginacao: {
        page: 0,
        nextPage: true,
      },
      filtros: {
        busca: '',
        tipo_data: 'todas',
        status: 'ativo',
        dtini: '',
        dtfim: '',
        tags: ''
      },
      metadados: {
        quantidade_total: 0,
        valor_total: 0,
      },
      // Opções
      filtrosStatus: {
        ativo: 'Ativos',
        trial: 'Trial',
        congelado: 'Congelado',
        cancelado: 'Cancelado'
      },
      filtrosTipoData: {
        todas: 'Todas as datas',
        inicio: 'Início do Contrato',
        fim: 'Fim do Contrato',
        ultimo_faturamento: 'Último Faturamento',
        deactivated: 'Cancelamento'
      },
      // Opções Avançadas
      drawerRight: false,
      aprovando: null,
      errors: {},
      opcoes: {},
      hasVideo: false,
      tags: [],
      tagFiltro: [],
      arquivos: []
    };
  },
  methods: {
    // Tabela
    listar() {
      if (this.buscando) return;
      if (!this.paginacao.nextPage) return;

      this.buscando = true;

      let params = JSON.parse(JSON.stringify(this.filtros));
      params.page = this.paginacao.page + 1;

      axios
        .get('/recorrencias', { params })
        .then(res => {
          this.buscando = false;

          if (!res.data.success) {
            throw new Error();
          }

          this.filtros = res.data.data.filtros;
          this.paginacao = res.data.data.paging;
          this.metadados = res.data.data.metadados;
          this.recorrencias = this.recorrencias.concat(res.data.data.recorrencias);
          this.opcoes = res.data.data.opcoes;

          this.atualizarFiltrosUrl();
        })
        .catch(() => {
          this.buscando = false;
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    busca(busca) {
      this.filtros.busca = busca;
      this.atualizar();
    },
    atualizar() {
      this.selecionadas = [];
      this.selecionarTodas = false;

      this.resetarPaginacao();
      this.listar();
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.listar();
      }
    },
    resetarPaginacao() {
      this.recorrencias = [];
      this.paginacao = {
        page: 0,
        nextPage: true,
      };
    },
    // Filtros
    carregarFiltros() {
      const query = this.$route.query;
      this.filtros = { ...this.filtros, query };
    },
    atualizarFiltrosUrl() {
      let newQueryParams = [];

      for (const filtro in this.filtros) {
        newQueryParams.push(`${filtro}=${this.filtros[filtro]}`);
      }

      const newUrl = '?' + newQueryParams.join('&');

      window.history.replaceState(this.filtros, '', newUrl);
    },
    alterarFiltroStatus(status) {
      this.filtros.status = status;

      this.resetarPaginacao();
      this.listar();
    },
    alterarFiltroTipoData(tipoData) {
      this.filtros.tipo_data = tipoData;

      this.resetarPaginacao();
      this.listar();
    },
    resetarFiltros() {
      this.filtros = {
        busca: '',
        tipo_data: 'todas',
        status: 'ativo',
        dtini: '',
        dtfim: '',
      };
    },
    alterarDataInicio(value) {
      this.filtros.dtini = value.replace(/\//g, '-');
      this.$refs.qDateDtini.hide();

      this.atualizar();
    },
    alterarDataFim(value) {
      this.filtros.dtfim = value.replace(/\//g, '-');
      this.$refs.qDateDtfim.hide();

      this.atualizar();
    },
    changeFilterPeriodo(periodo) {
      if (this.buscando) return;

      let dtini = moment(this.filtros.dtini, 'DD-MM-YYYY');
      let dtfim = moment(this.filtros.dtfim, 'DD-MM-YYYY');

      if (periodo === 'prev') {
        dtini.subtract(1, 'months');
        dtfim.subtract(1, 'months');
      }

      if (periodo === 'next') {
        dtini.add(1, 'months');
        dtfim.add(1, 'months');
      }

      this.filtros.dtini = dtini.startOf('month').format('DD-MM-YYYY');
      this.filtros.dtfim = dtfim.endOf('month').format('DD-MM-YYYY');

      this.atualizar();
    },
    // Opções Recorrências
    async getRecorrenciaParaModal(recorrencia) {
      return new Promise((resolve, reject) => {
        const { id, cadastro_id } = recorrencia;

        const url = id ? `/recorrencias/edit/${id}` : `/recorrencias/add/${cadastro_id}`;

        axios
          .get(url)
          .then(res => {
            if (res.error && res.error.response.status === 403) {
              this.notificarSemPermissao(res.error.response.data.message);
              reject(res.error);
            }

            const recorrenciaAtualizada = this.tratarRecorrencia(res.data.data.recorrencia);
            const { convenios, dependentes } = res.data.data;

            resolve({
              recorrencia: recorrenciaAtualizada,
              convenios,
              dependentes,
              arquivos: res.data.data.arquivos
            });
          })
          .catch(e => {
            this.$q.notify({
              message: 'Não foi possível contactar, ou você não possui permissão!',
              color: 'red',
              position: 'top-right',
            });
            reject(e);
          });
      });
    },
    aprovar(recorrencia) {
      this.$q
        .dialog({
          title: recorrencia.descricao,
          message: 'Tem certeza que deseja aprovar a recorrência?',
          persistent: true,
          cancel: true,
        })
        .onOk(() => {
          this.aprovando = recorrencia.id;

          axios
            .post(`/recorrencias/aprovar/${recorrencia.id}`)
            .then(res => {
              this.aprovando = null;

              this.$q.notify({
                color: res.data.success ? 'green' : 'orange',
                message: res.data.message,
                position: 'top-right',
              });

              if (!res.data.success) {
                return;
              }

              const index = this.recorrencias.indexOf(recorrencia);
              this.recorrencias.splice(index, 1, res.data.recorrencia);
            })
            .catch(() => {
              this.aprovando = null;

              this.$q.notify({
                color: 'red',
                message: 'Houve um erro ao aprovar a recorrência',
                position: 'top-right',
              });
            });
        });
    },
    congelar(recorrencia) {
      bus.$emit('abrirModalCongelarRecorrencia', { recorrencia });
    },
    modalEditarTrial(recorrencia) {
      this.getRecorrenciaParaModal(recorrencia).then(({ recorrencia }) => {
        bus.$emit('abrirModalTrialRecorrencia', {
          recorrencia,
        });
      });
    },
    abrirModalComissoes(recorrencia) {
      this.getRecorrenciaParaModal(recorrencia).then(({ recorrencia }) => {
        bus.$emit('abrirModalComissoesRecorrencia', {
          recorrencia,
        });
      });
    },
    modalAdicionarEditar(recorrencia) {
      this.getRecorrenciaParaModal(recorrencia).then(({ recorrencia, convenios, dependentes, arquivos }) => {
        bus.$emit('abrirModalNovaRecorrencia', {
          recorrencia,
          convenios,
          dependentes,
          arquivos
        });
      });
    },
    excluir(recorrencia) {
      let acao = 'excluir';
      let acaoAlt = 'exclusão';
      let mensagemSucesso = 'removida';
      let url = `/recorrencias/delete/${recorrencia.id}`;
      let data = {};

      if (recorrencia.ativo) {
        acao = 'inativar';
        acaoAlt = 'inativação';
        mensagemSucesso = 'inativada';
        url = `/recorrencias/edit/${recorrencia.id}`;
      }

      this.$q
        .dialog({
          title: `Tem certeza que deseja ${acao} a recorrência?`,
          message: `Descreva o motivo da ${acaoAlt} `,
          cancel: 'Cancelar',
          persistent: true,
          prompt: {
            model: '',
            isValid: val => val.length > 2,
          },
        })
        .onOk(() => {
          Loading.show();
        })
        .onOk((motivo) => {
          data = {
            ativo: false,
            descricao: recorrencia.descricao,
            periodo: recorrencia.periodo,
            valor: recorrencia.valor,
            motivo: motivo
          };
          axios
            .post(url, data)
            .then(res => {
              if (res.error && res.error.response.status === 403) {
                this.notificarSemPermissao(res.error.response.data.message);
                return;
              }

              if (res.data.success) {
                this.$q.dialog({
                  title: `Recorrencia ${mensagemSucesso}!`,
                  class: 'bg-positive text-white',
                  ok: {
                    'text-color': 'green',
                    color: 'white',
                  },
                });
                this.atualizar();
              } else {
                this.$q.dialog({
                  title: res.data.message,
                  class: 'bg-warning text-white',
                  ok: {
                    'text-color': 'orange',
                    color: 'white',
                  },
                });
              }
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    // Opções Avançadas
    async faturar() {
      try {
        const data = {
          acao: 'get-ids',
          ids: this.idsSelecionadas,
          all: this.selecionarTodas,
          confirmar: true,
        };

        const response = await axios.post('/recorrencias/edit-multiple', data, {
          params: this.filtros,
        });

        if (!response.data.success) {
          throw new Error();
        }

        this.$store.commit('saveFaturamento', {
          ...this.saveFaturamento,
          recorrencias_ids: response.data.ids,
        });

        this.$store.dispatch('faturar');
      } catch (e) {
        this.$q.dialog({
          title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
          class: 'bg-negative text-white',
          ok: {
            'text-color': 'red',
            color: 'white',
          },
        });
      }
    },
    abrirModalCongelarMassa() {
      bus.$emit('abrirModalCongelarRecorrenciasMassa', {
        ids: this.idsSelecionadas,
        filtros: this.filtros,
        selecionarTodas: this.selecionarTodas,
      });
    },
    descongelarMassa() {
      const data = {
        acao: 'descongelar',
        ids: this.idsSelecionadas,
        all: this.selecionarTodas,
      };

      axios
        .post('/recorrencias/edit-multiple', data, { params: this.filtros })
        .then(res => {
          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          if (!res.data.success) {
            this.$q.dialog({
              title: res.data.message,
              class: 'bg-warning text-white',
              ok: {
                'text-color': 'orange',
                color: 'white',
              },
            });
            return;
          }

          this.$q
            .dialog({
              title: 'Deseja realmente descongelar as recorrências?',
              message: res.data.message,
              cancel: 'Cancelar',
              persistent: true,
            })
            .onOk(() => {
              data.confirmar = true;

              axios
                .post('/recorrencias/edit-multiple', data, { params: this.filtros })
                .then(res => {
                  if (res.error && res.error.response.status === 403) {
                    this.notificarSemPermissao(res.error.response.data.message);
                    return;
                  }

                  this.$q.dialog({
                    title: res.data.message,
                    class: `bg-${res.data.success ? 'positive' : 'warning'} text-white`,
                    ok: {
                      'text-color': res.data.success ? 'green' : 'orange',
                      color: 'white',
                    },
                  });

                  this.atualizar();
                });
            });
        })
        .catch(() => {
          this.$q.dialog({
            title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
            class: 'bg-negative text-white',
            ok: {
              'text-color': 'red',
              color: 'white',
            },
          });
        });
    },
    abrirModalReajusteMassa() {
      bus.$emit('abrirModalReajusteRecorrenciasMassa', {
        ids: this.idsSelecionadas,
        filtros: this.filtros,
        selecionarTodas: this.selecionarTodas,
      });
    },
    // Utilidade
    tratarRecorrencia(recorrencia) {
      recorrencia.valor = recorrencia.valor.toFixed(2);

      if (recorrencia.dtini) {
        recorrencia.dtini = moment(recorrencia.dtini).format('DD/MM/YYYY');
      }
      if (recorrencia.dtfim) {
        recorrencia.dtfim = moment(recorrencia.dtfim).format('DD/MM/YYYY');
      }
      if (recorrencia.data_bloqueio) {
        recorrencia.data_bloqueio = moment(recorrencia.data_bloqueio).format('DD/MM/YYYY');
      }
      if (recorrencia.ultimo_faturamento) {
        recorrencia.ultimo_faturamento = moment(recorrencia.ultimo_faturamento).format(
          'DD/MM/YYYY'
        );
      }
      if (recorrencia.congeladoem) {
        recorrencia.congeladoem = moment(recorrencia.congeladoem).format(
          'DD/MM/YYYY'
        );
      }
      if (recorrencia.data_primeiro_pagamento) {
        recorrencia.data_primeiro_pagamento = moment(recorrencia.data_primeiro_pagamento).format(
          'DD/MM/YYYY'
        );
      }
      if (recorrencia.dtini_trial) {
        recorrencia.dtini_trial = moment(recorrencia.dtini_trial).format('DD/MM/YYYY');
      }
      if (recorrencia.dtfim_trial) {
        recorrencia.dtfim_trial = moment(recorrencia.dtfim_trial).format('DD/MM/YYYY');
      }

      return recorrencia;
    },
    notificarSemPermissao(message) {
      this.$q.notify({
        message: message,
        color: 'red',
        position: 'top-right',
      });
    },
    getPeriodoDescricao(intervalo) {
      switch (parseInt(intervalo)) {
        case 2:
          return 'Bimestral';
        case 3:
          return 'Trimestral';
        case 6:
          return 'Semestral';
        case 12:
          return 'Anual';
        default:
          return `${intervalo} meses`;
      }
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "assinaturas");

      if (!this.videosManuais.video_assinaturas) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "assinaturas");
    },
    getTags() {
      axios
        .get('/tags')
        .then(res => {

          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "orange",
              position: "top-right"
            });
          }

          this.tags = res.data.tags;
        });
    },
    setTag() {
      this.filtros.tags = this.tagFiltro.map(tag => tag.id).join(',');
      this.atualizar();
    },
    getTotalContratacao(recorrencia) {
      return recorrencia.valor / recorrencia.periodo + recorrencia.valor_ativacao;
    },
    checkUrlEditModal() {
      const urlParams = new URLSearchParams(window.location.search);
      const recorrenciaId = urlParams.get('recorrencia_id');
      if (recorrenciaId && urlParams.get('modal_recorrencia') === '1') {
        this.modalAdicionarEditar({ id: recorrenciaId });
      }
    }
  },
  computed: {
    ...mapGetters([
      "saveFaturamento",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    colunas() {
      let colunas = [
        {
          name: "cadastro",
          required: true,
          align: "center",
          label: "Cadastro",
          field: row => (row.cadastro ? row.cadastro.nome : null),
          sortable: true,
        },
        {
          name: "descricao",
          required: true,
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true,
        },
        {
          name: "valor",
          required: true,
          align: "center",
          label: "Mensalidade",
          field: row => row.valor / row.periodo,
          sortable: true,
        },
        {
          name: "valor_ativacao",
          required: true,
          align: "center",
          label: "Adesão",
          field: row => row.valor_ativacao,
        },
        {
          name: "total_contratacao",
          required: true,
          align: "center",
          label: "Total Contratação",
          field: row => row.valor / row.periodo + row.valor_ativacao,
        },
        {
          name: "dtini",
          required: true,
          align: "center",
          label: "Início",
          field: row => row.dtini,
          sortable: true,
        },
        {
          name: "dtfim",
          required: true,
          align: "center",
          label: "Fim",
          field: row => row.dtfim,
          sortable: true,
        },
        {
          name: "ultimo_faturamento",
          required: true,
          align: "center",
          label: "Último faturamento",
          field: row => row.ultimo_faturamento,
          sortable: true,
        },
        {
          name: "tdfora",
          required: true,
        },
      ];

      if (this.filtros.status === "cancelado") {
        colunas.splice(8, 0, {
          name: "cancelamento",
          required: true,
          align: "center",
          label: "Cancelamento",
          field: row => row.deactivated,
          sortable: true
        });
      }

      return colunas;
    },
    utilizarTrial() {
      return (
        this.opcoes.integracoes_piperun_utilizar_trial &&
        parseInt(this.opcoes.integracoes_piperun_utilizar_trial)
      );
    },
    utilizarComissoes() {
      return (
        this.opcoes.integracoes_piperun_utilizar_comissoes &&
        parseInt(this.opcoes.integracoes_piperun_utilizar_comissoes)
      );
    },
    colunasVisiveisOpcoes() {
      return this.colunas
        .filter(coluna => coluna.name !== 'tdfora')
        .map(coluna => ({
          label: coluna.label,
          value: coluna.name,
        }));
    },
    totalRecorrenciasCard() {
      const total = {
        valor: this.metadados.valor_total || 0,
        quantidade: this.paginacao.count || 0,
      };

      return total;
    },
    totalSelecionadoCard() {
      const selecionado = {
        valor: 0.0,
        quantidade: 0,
      };

      if (this.selecionarTodas) {
        selecionado.valor = this.metadados.valor_total || 0;
        selecionado.quantidade = this.paginacao.count || 0;

        return selecionado;
      }

      selecionado.valor = this.selecionadas.reduce(
        (total, recorrencia) => total + recorrencia.valor / recorrencia.periodo,
        0.0
      );
      selecionado.quantidade = this.selecionadas.length;

      return selecionado;
    },
    dtini: {
      get() {
        let date = this.filtros.dtini.replace(/-/g, '/');
        return date;
      },
      set() {},
    },
    dtfim: {
      get() {
        let date = this.filtros.dtfim.replace(/-/g, '/');
        return date;
      },
      set() {},
    },
    idsSelecionadas() {
      return this.selecionadas.map(recorrencia => recorrencia.id);
    },
  },
  watch: {
    selecionadas(value) {
      if (value.length === 0) {
        this.todasListadasSelecionadas = false;
      }

      if (value.length >= 10) {
        this.todasListadasSelecionadas = true;
      }
    },
    colunasVisiveis() {
      localStorage.setItem('recorrencias__colunasVisiveis', JSON.stringify(this.colunasVisiveis));
    },
  },
  beforeMount() {
    bus.$off('buscaAssinaturas');
  },
  mounted() {
    this.carregarFiltros();
    this.listar();
    this.verificarPrimeiroVideo();
    this.getTags();
    this.checkUrlEditModal();

    window.addEventListener('scroll', this.handleScroll);

    bus.$on('atualizarRecorrencias', this.atualizar);
    bus.$on('buscaAssinaturas', this.busca);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('scroll', this.handleScroll);
    next();
  },
};
</script>
