<template>
  <q-page id="compras">
    <sem-permissao v-if="!isPermissoEstoque"></sem-permissao>
    <div v-else>
      <div class="q-pa-md">
        <q-btn
          dense
          flat
          size="12px"
          style="margin-bottom: 10px; padding: 0 5px 0 5px"
          icon="arrow_back"
          label="Voltar para a página anterior"
          to="estoques"
        ></q-btn>
        <q-table
          title="Compras"
          :columns="columns"
          :data="comprasProdutos"
          :loading="carregamentoCompras"
          row-key="id"
          :pagination.sync="pagination"
          :no-data-label="'Nenhum dado'"
          :no-results-label="'Nenhum resultado obtido'"
          :loading-label="'Carregando dados'"
          :bottom="true"
        >
          <template v-slot:bottom>
            <div></div>
          </template>
          <template v-slot:body="props">
            <q-tr @click="props.expand = !props.expand && $q.platform.is.mobile">
              <q-td key="id" class="text-center">{{ props.row.id }}</q-td>
              <q-td key="nome" class="text-left">
                {{ props.row.fornecedor.nome }}

                <div v-if="props.row.observacao">
                  {{ props.row.observacao | truncate(40) }}
                  <q-tooltip v-if="props.row.observacao && props.row.observacao.length > 40">
                    {{ props.row.observacao }}
                  </q-tooltip>
                </div>
              </q-td>
              <q-td key="dtmovimento" class="text-center">{{
                props.row.dtmovimento | formatDate
              }}</q-td>
              <q-td key="status" class="text-left">
                {{ props.row.status }}

                <div v-if="props.row.movcaixa">
                  <router-link :to="`/caixas/${props.row.movcaixa_id}`" style="text-decoration: none; color: black">
                    {{ props.row.movcaixa.caixa_nome }}
                     - Sessão
                    {{ props.row.movcaixa.sessao }}
                  </router-link>
                </div>
              </q-td>
              <q-td key="Realizado por" class="text-center">{{
                props.row.cadastro.nome
              }}</q-td>
              <q-td key="total" class="text-right"
                >R$ {{ props.row.total | currency }}</q-td
              >
              <q-td v-if="$q.platform.is.desktop" class="tdfora">
                <div class="divfora">
                  <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                    <q-btn
                      size="12px"
                      class="bg-green text-white"
                      icon="list"
                      @click="openDialogCompraEstoque(props.row.id, props.row.dtmovimento)"
                    />
                    <q-btn
                      size="12px"
                      class="bg-red text-white"
                      icon="delete"
                      @click="cancelarCompra(props.row.id)"
                      v-if="props.row.status != 'Cancelado'"
                    />
                  </div>
                </div>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand" :props="props">
              <q-td colspan="100%">
                <div class="q-py-sm q-gutter-sm">
                  <q-btn
                    size="12px"
                    class="bg-green text-white"
                    icon="list"
                    @click="openDialogCompraEstoque(props.row.id, props.row.dtmovimento)"
                  />
                  <q-btn
                    size="12px"
                    class="bg-red text-white"
                    icon="delete"
                    @click="cancelarCompra(props.row.id)"
                    v-if="props.row.status != 'Cancelado'"
                  />
                </div>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:top="props">
            <q-btn
              id="addCompras"
              flat
              round
              dense
              :icon="'add'"
              class="q-ml-md text-primary"
              style="margin-right: 10px;margin-left: 0"
              @click="openDialogCompraEstoque"
            />
            <div class="col-2 q-table__title">Compras</div>
            <q-space />
            
            <div id="comprasFiltros" class="relative-position row items-center">
              <date-picker></date-picker>

              <q-btn
                  v-if="$q.screen.gt.xs"
                  flat
                  dense
                  no-wrap
                  v-ripple
                  color="primary"
                  no-caps
                  :label="produtoNome? produtoNome == 'Todos' ? 'Produtos' : produtoNome : 'Produtos'"
                  class="q-ml-sm q-px-md bg-white text-blue-7"
                  style="margin-right: 10px"
                >
                  <q-menu anchor="top right" self="top right">
                    <q-list class="text-grey-8" style="min-width: 100px">
                      <q-item aria-hidden="true">
                        <q-item-section
                          class="text-uppercase text-grey-7"
                          style="font-size: 0.7rem"
                        >Produto</q-item-section>
                      </q-item>
                      <q-item
                        :class="{'active_status': produtoNome === p.produto.nome}"
                        v-for="p in produtos"
                        :key="p.produto_id"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        @click="changeFilterProduto(p)"
                      >
                        <q-item-section>{{ p.produto.nome }}</q-item-section>
                      </q-item>
                      <q-item
                        :class="{'active_status': produtoNome === 'Todos'}"
                        @click="changeFilterProduto()"
                        v-close-popup
                        clickable
                        aria-hidden="true"
                      >
                        <q-item-section>Todos</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
              </q-btn>

              <q-btn
                  v-if="$q.screen.gt.xs"
                  flat
                  dense
                  no-wrap
                  v-ripple
                  color="primary"
                  no-caps
                  :label="filtrosCompras.status"
                  class="q-ml-sm q-px-md bg-white text-blue-7"
                  style="margin-right: 10px"
                >
                  <q-menu anchor="top right" self="top right">
                    <q-list class="text-grey-8" style="min-width: 100px">
                      <q-item aria-hidden="true">
                        <q-item-section
                          class="text-uppercase text-grey-7"
                          style="font-size: 0.7rem"
                        >Status</q-item-section>
                      </q-item>
                      <q-item
                        :class="{'active_status': filtrosCompras.status === key}"
                        v-for="key in statusCompras"
                        :key="key"
                        clickable
                        v-close-popup
                        aria-hidden="true"
                        @click="changeFilterStatusCompras(key)"
                      >
                        <q-item-section>{{ key }}</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
              </q-btn>
              
            </div>
            <q-btn
              v-if="$q.screen.gt.xs"
              flat
              dense
              rounded
              no-wrap
              v-ripple
              class="q-ml-sm q-px-md bg-white text-black"
              no-caps
              icon="more_vert"
              style="margin-right: 10px"
            >
              <q-menu anchor="top right" self="top right">
                <q-list class="text-grey-8" style="min-width: 100px">
                  <q-item aria-hidden="true">
                    <q-item-section
                      class="text-uppercase text-grey-7"
                      style="font-size: 0.7rem"
                      >Mais Opções</q-item-section
                    >
                  </q-item>
                  <q-item
                    clickable
                    v-close-popup
                    aria-hidden="true"
                    @click="listarCompras()"
                  >
                    <q-item-section>Atualizar</q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    v-close-popup
                    aria-hidden="true"
                    @click="props.toggleFullscreen"
                  >
                    <q-item-section>{{
                      props.inFullscreen ? "Minimizar" : "Maximizar Tela"
                    }}</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </template>
        </q-table>
      </div>
    </div>
    <modal-compra-estoque :compra-id="compra_id" :dtmovimento="dtmovimentoDetalhar" />
  </q-page>
</template>
<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Notify, Dialog, Loading } from "quasar";

export default {
  name: "compras",
  meta: {
    title: "DSB | Compras Estoque"
  },
  data() {
    return {
      compra_id: null,
      dtmovimentoDetalhar: null,
      columns: [
        {
          name: "id",
          label: "ID",
          align: "center",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "Fornecedores",
          label: "Fornecedor",
          align: "center",
          field: row => row.cadastro.nome,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "dtmovimento",
          label: "Data de movimento",
          align: "center",
          field: row => row.dtmovimento,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "status",
          label: "Status",
          align: "center",
          field: row => row.status,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "cadastro",
          label: "Realizado por",
          align: "center",
          field: row => row.status,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "total",
          label: "Total",
          align: "center",
          field: row => row.total,
          format: val => `${val}`,
          sortable: true
        },
        { name: 'tdfora' },
      ],
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 0
      },
      statusCompras: [
        'Aberto',
        'Finalizado',
        'Cancelado',
        'Todos'
      ]
    };
  },
  computed: {
    ...mapGetters([
      "comprasProdutos",
      "isPermissoEstoque",
      "carregamentoCompras",
      "produtoNome",
      "produtos"
    ]),
    filtrosCompras: {
      get() {
        return this.$store.getters.filtrosCompras;
      },
      set(value) {
        this.$store.commit('filtrosCompras', value);
      }
    }
  },
  methods: {
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.$store.dispatch("getCompras", true);
      }
    },
    listarCompras(atualizar = false) {
      this.$store.dispatch("getCompras", atualizar);
    },
    changeFilterStatusCompras(key) {
      this.filtrosCompras.status = key;

      this.$store.dispatch("filtrosCompras", this.filtrosCompras);
    },
    changeFilterProduto(p) {
      if(p) {
        this.filtrosCompras.produto_id = p.produto_id;
        this.$store.dispatch("filtrosCompras", this.filtrosCompras);
        this.$store.commit("produtoNome", p.produto.nome);
      } else {
        this.filtrosCompras.produto_id = 'Todos';
        this.$store.dispatch("filtrosCompras", this.filtrosCompras);
        this.$store.commit("produtoNome", 'Todos');
      }
    },
    openDialogCompraEstoque(compra_id = null, dtmovimento = null) {
      this.compra_id = compra_id;
      this.dtmovimentoDetalhar = dtmovimento;
      this.$store.dispatch("resetModal");
      this.$store.dispatch("comprasProdutosItems", compra_id);
      this.$store.dispatch("getFornecedores");
      this.$store.dispatch("produtosEstoque", {
        tipo: "P",
        all: 1,
        ativo: 1
      });
    },
    cancelarCompra(id) {
      Dialog.create({
        title: "Deseja cancelar compra ?",
        message: "Sua compra será cancelada",
        cancel: "Cancelar",
        persistent: true
      })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          axios
            .post("produtos/cancelar-compra", {
              id: id
            })
            .then(res => {
              if (res.data.success) {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-green text-white",
                  ok: {
                    "text-color": "black",
                    color: "white"
                  }
                });
              } else {
                Dialog.create({
                  title: res.data.message,
                  class: "bg-warning text-white",
                  ok: {
                    "text-color": "orange",
                    color: "white"
                  }
                });
              }
              Loading.hide();
              this.$store.dispatch("getCompras");
            })
            .catch(err => {
              Dialog.create({
                title:
                  "Não foi Possível realizar a ação, ou você não possui permissão isto!",
                class: "bg-negative text-white",
                ok: {
                  "text-color": "red",
                  color: "white"
                }
              });
              Loading.hide();
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    }
  },
  mounted() {
    this.listarCompras();
    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit("openDialogCompraEstoque", false);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>
