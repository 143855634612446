import { render, staticRenderFns } from "./resumo_tfl.vue?vue&type=template&id=a74d3920&scoped=true&"
import script from "./resumo_tfl.vue?vue&type=script&lang=js&"
export * from "./resumo_tfl.vue?vue&type=script&lang=js&"
import style0 from "./resumo_tfl.vue?vue&type=style&index=0&id=a74d3920&prod&lang=stylus&scoped=true&"
import style1 from "./resumo_tfl.vue?vue&type=style&index=1&id=a74d3920&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a74d3920",
  null
  
)

export default component.exports