<template>
  <q-page id="parametros">
    <div class="q-pa-md">
      <q-list>
        <q-expansion-item
          id="menuParametros1"
          group="parametrosGrupo"
          :header-class="{'bg-primary text-white': expanded1}"
          :dark=expanded1
          popup
          v-model="expanded1"
          label="Cadastros - Produtos e Serviços"
          caption="Novo, Loterias, Produtos, Serviços, Categorias"
        >
          <q-card>
            <q-card-section style="padding: 0 !important">
              <q-tabs
                v-model="tab1"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="left"
                narrow-indicator
              >
                <q-tab
                  name="pr"
                  style="padding-right: 0; padding-left: 0"
                  icon="add"
                  @click="abreNovoProduto()"
                />
                <q-tab id="menuParametros1Sub1" name="pr" style="padding-left: 0" label="Produtos" />
                <q-tab
                  name="lt"
                  style="padding-right: 0; padding-left: 0"
                  icon="add"
                  @click="abreNovaLoteria()"
                />
                <q-tab id="menuParametros1Sub2" name="lt" style="padding-left: 0" label="Loterias" />
                <q-tab
                  name="sr"
                  style="padding-right: 0; padding-left: 0"
                  icon="add"
                  @click="abreNovoServico()"
                />
                <q-tab id="menuParametros1Sub3" name="sr" style="padding-left: 0" label="Serviços" />
                <q-tab
                  name="ct"
                  style="padding-right: 0; padding-left: 0"
                  icon="add"
                  @click="abreNovaCategoria()"
                />
                <q-tab id="menuParametros1Sub4" name="ct" style="padding-left: 0" label="Categorias" />
              </q-tabs>
              <q-separator />
              <q-tab-panels v-model="tab1" animated>
                <q-tab-panel name="pr">
                  <produtos-parametros></produtos-parametros>
                  <modal-novo-produto></modal-novo-produto>
                  <modal-produtos-tags section="produtos"></modal-produtos-tags>
                  <!-- <modal-novo-regra-produto></modal-novo-regra-produto> -->
                </q-tab-panel>

                <q-tab-panel name="lt">
                  <loterias-parametros></loterias-parametros>
                  <modal-nova-loteria></modal-nova-loteria>
                  <modal-produtos-tags section="loterias"></modal-produtos-tags>
                  <!-- <modal-nova-regra-loteria></modal-nova-regra-loteria> -->
                </q-tab-panel>

                <q-tab-panel name="sr">
                  <servicos-parametros></servicos-parametros>
                  <modal-novo-servico></modal-novo-servico>
                  <modal-produtos-tags section="servicos"></modal-produtos-tags>
                  <!-- <modal-novo-regra-servico></modal-novo-regra-servico> -->
                </q-tab-panel>

                <q-tab-panel name="ct">
                  <categorias-parametros></categorias-parametros>
                  <modal-nova-categoria></modal-nova-categoria>
                </q-tab-panel>
              </q-tab-panels>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-expansion-item
          id="menuParametros2"
          group="parametrosGrupo"
          :header-class="{'bg-primary text-white': expanded2}"
          :dark=expanded2
          popup
          v-model="expanded2"
          label="Cadastros Financeiros"
          caption="Novo, Plano de Contas, Formas de Cobrança, Formas de Pagamento, Régua de Mensagens, Templates, Contas, Caixas, Centros de Custos, Régua de Faturamento"
        >
          <q-card>
            <q-card-section style="padding: 0 !important">
              <q-tabs
                v-model="tab2"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="left"
                narrow-indicator
              >
                <q-tab
                  name="pc"
                  icon="add"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovoPlanoContas()"
                />
                <q-tab id="menuParametros2Sub1" name="pc" label="Plano de Contas" style="padding-left: 0" />
                <q-tab
                  name="fr"
                  icon="add"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovaFormaCobranca()"
                />
                <q-tab id="menuParametros2Sub2" style="padding-left: 0" name="fr" label="Formas de Cobrança" />
                <q-tab
                  name="fp"
                  icon="add"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovaFormaRecebimento()"
                />
                <q-tab id="menuParametros2Sub3" style="padding-left: 0" name="fp" label="Formas de Pagamento" />
                <q-tab
                  icon="add"
                  name="tm"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovoTemplate()"
                />
                <q-tab id="menuParametros2Sub4" name="tm" label="Templates" style="padding-left: 0" />
                <q-tab
                  icon="add"
                  name="rc"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovaRegua()"
                />
                <q-tab id="menuParametros2Sub5" name="rc" label="Régua de Mensagens" style="padding-left: 0" />
                <q-tab
                  icon="add"
                  name="cn"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovaConta()"
                  id="tabContasAdd"
                />
                <q-tab id="menuParametros2Sub6" name="cn" label="Contas" style="padding-left: 0" />
                <q-tab
                  icon="add"
                  id="abreNovoCaixa"
                  name="cx"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovoCaixa()"
                />
                <q-tab id="menuParametros2Sub7" name="cx" label="Caixas" style="padding-left: 0" />
                <q-tab
                  icon="add"
                  name="cc"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovoCentroCusto()"
                />
                <q-tab id="menuParametros2Sub8" name="cc" label="Centros de Custos" style="padding-left: 0" />
                <q-tab
                  icon="add"
                  name="fa"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovoFaturamentoAutomatico()"
                />
                <q-tab id="menuParametros2Sub9" name="fa" label="Régua de Faturamento" style="padding-left: 0" />
              </q-tabs>
              <q-separator />
              <q-tab-panels v-model="tab2" animated>
                <q-tab-panel name="pc">
                  <plano-contas-parametros></plano-contas-parametros>
                  <modal-novo-plano-contas></modal-novo-plano-contas>
                </q-tab-panel>

                <q-tab-panel name="fr">
                  <formas-recebimento-parametros tipo="receita" />

                  <modal-nova-forma-cobranca
                    v-if="openDialogNovaFormaCobranca"
                    :openDialogNovaFormaCobranca="openDialogNovaFormaCobranca"
                    @closeModal="openDialogNovaFormaCobranca = false"
                  />

                </q-tab-panel>

                <q-tab-panel name="fp">
                  <formas-recebimento-parametros tipo="despesa"></formas-recebimento-parametros>
                  <modal-nova-forma-pagamento tipo="despesa"></modal-nova-forma-pagamento>
                </q-tab-panel>

                <q-tab-panel name="tm">
                  <templates-parametros></templates-parametros>
                  <modal-novo-template></modal-novo-template>
                </q-tab-panel>
                <q-tab-panel name="rc">
                  <regua-cobrancas-parametros></regua-cobrancas-parametros>
                  <modal-nova-regua></modal-nova-regua>
                </q-tab-panel>

                <q-tab-panel name="cn">
                  <contas-parametros></contas-parametros>
                  <modal-nova-conta></modal-nova-conta>
                </q-tab-panel>

                <q-tab-panel name="cx">
                  <caixas-parametros></caixas-parametros>
                  <modal-novo-caixa></modal-novo-caixa>
                </q-tab-panel>

                <q-tab-panel name="cc">
                  <centro-custos-parametros></centro-custos-parametros>
                  <modal-novo-centro-custo></modal-novo-centro-custo>
                </q-tab-panel>

                <q-tab-panel name="fa">
                  <faturamento-automatico-parametros></faturamento-automatico-parametros>
                  <modal-novo-faturamento-automatico></modal-novo-faturamento-automatico>
                </q-tab-panel>
              </q-tab-panels>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-expansion-item
          id="menuParametros3"
          group="parametrosGrupo"
          :header-class="{'bg-primary text-white': expanded3}"
          :dark=expanded3
          popup
          v-model="expanded3"
          label="Usuários"
          caption="Novo, Usuários, Editar, Remover"
        >
          <q-card>
            <q-card-section style="padding: 0 !important">
              <q-tabs
                v-model="tab3"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="left"
                narrow-indicator
              >
                <q-tab
                  name="usuarios"
                  icon="add"
                  style="paddin-left: 0; padding-right: 0"
                  @click="abreNovoUsuario()"
                />
                <q-tab id="menuParametros3Sub1" name="usuarios" label="Usuários" style="padding-left: 0" />
                <q-tab id="menuParametros3Sub2" name="controle-horarios" label="Horário de expediente" style="padding-left: 0" />
              </q-tabs>
              <q-separator />
              <q-tab-panels v-model="tab3" animated>
                <q-tab-panel name="usuarios">
                  <usuarios-parametros ref="usuariosParametros"></usuarios-parametros>
                  <modal-novo-usuario></modal-novo-usuario>
                </q-tab-panel>
                <q-tab-panel name="controle-horarios">
                  <controle-horarios />
                </q-tab-panel>
              </q-tab-panels>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-expansion-item
          id="menuParametros4"
          group="parametrosGrupo"
          :header-class="{'bg-primary text-white': expandedIntegracoes}"
          :dark=expandedIntegracoes
          popup
          v-model="expandedIntegracoes"
          label="Integrações"
          caption="Assinaturas / Recorrências, Piperun, Hubspot, Movidesk, Zenvia, Webhooks e Importações"
        >
          <q-card>
            <q-card-section style="padding: 0 !important">
              <q-tabs
                v-model="tabIntegracoes"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="left"
                narrow-indicator
              >
                <q-tab id="menuParametros4Sub1" name="recorrencias" label="Assinaturas / Recorrências" style="padding-left: 0" />
                <q-tab id="menuParametros4Sub2" name="piperun" label="Piperun" style="padding-left: 0" />
                <q-tab id="menuParametros4Sub3" name="hubspot" label="Hubspot" style="padding-left: 0" />
                <q-tab id="menuParametros4Sub4" name="movidesk" label="Movidesk" style="padding-left: 0" />
                <q-tab id="menuParametros4Sub5" name="zenvia" label="Zenvia" style="padding-left: 0" />
                <q-tab
                  icon="add"
                  name="webhook"
                  style="padding-right: 0; padding-left: 0"
                  @click="abreNovoWebhook()"
                />
                <q-tab id="menuParametros4Sub6" name="webhook" label="Webhooks" style="padding-left: 0" />
                <q-tab id="menuParametros4Sub7" name="importacoes" label="Importações" style="padding-left: 0" @click="goToArquivos"/>
              </q-tabs>

              <q-separator />

              <q-tab-panels v-model="tabIntegracoes" animated>

                <q-tab-panel name="recorrencias">
                  <integracao-recorrencias-parametros />
                </q-tab-panel>

                <q-tab-panel name="piperun">
                  <integracao-piperun-parametros />
                </q-tab-panel>

                <q-tab-panel name="hubspot">
                  <integracao-hubspot-parametros />
                </q-tab-panel>

                <q-tab-panel name="movidesk">
                  <integracao-movidesk-parametros />
                </q-tab-panel>

                <q-tab-panel name="zenvia">
                  <integracao-zenvia-parametros />
                </q-tab-panel>

                <q-tab-panel name="webhook">
                  <integracao-webhook-parametros />
                  <modal-novo-webhook />
                </q-tab-panel>

              </q-tab-panels>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-expansion-item
          id="menuParametros5"
          group="parametrosGrupo"
          :header-class="{'bg-primary text-white': expanded4}"
          :dark=expanded4
          popup
          v-model="expanded4"
          label="Dados da Empresa"
          caption="Novo, Visualizar"
        >
          <q-card>
            <q-card-section style="padding: 0 !important">
              <q-tabs
                v-model="tab4"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="left"
                narrow-indicator
              >
                <q-tab
                  name="dados"
                  icon="add"
                  style="padding-left: 0; padding-right: 0"
                  @click="abreNovaEmpresa()"
                />
                <q-tab id="menuParametros5Sub1" name="dados" label="Dados da Empresa" style="padding-left: 0" />
              </q-tabs>
              <q-separator />
              <q-tab-panels v-model="tab4" animated>
                <q-tab-panel name="dados">
                  <dados-da-empresa ref="dadosEmpresa"></dados-da-empresa>
                  <modal-nova-empresa ref="modalNovaEmpresa"></modal-nova-empresa>
                </q-tab-panel>
              </q-tab-panels>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-list>
    </div>
  </q-page>
</template>

<script>
export default {
  name: "parametros",
  meta: {
    title: 'DSB | Parâmetros',
  },
  data() {
    return {
      expanded1: false,
      expanded2: false,
      expanded3: false,
      expanded4: false,
      expandedIntegracoes: false,

      tab1: "pr",
      tab2: "pc",
      tab3: "usuarios",
      tab4: "dados",
      tabIntegracoes: "recorrencias",
      openDialogNovaFormaCobranca: false,
    };
  },
  methods: {
    abreNovoWebhook() {
      this.$store.dispatch('getAcoesWebhook');
      this.$store.commit('webhook', {
        acao: '',
        headers: {},
        url: null,
        ativo: true,
        descricao: '',
      });
      this.$store.commit("isNewWebhook", true);
      this.$store.commit("OpenDialogNovoWebhook", true);
    },
    abreNovaEmpresa() {
      this.$store.commit("OpenDialogNovaEmpresa", true);
    },
    abreNovoUsuario() {
      this.$store.commit("OpenDialogNovoUsuario", true);
    },
    abreNovoCaixa() {
      this.$store.commit("OpenDialogNovoCaixa", true);
    },
    abreNovoTemplate() {
      this.$store.commit("openDialogNovoTemplate", true);
    },
    abreNovoCentroCusto() {
      this.$store.commit("openDialogNovoCentroCusto", true);
    },
    abreNovoFaturamentoAutomatico() {
      this.$store.dispatch("abrirModalAdicionarFaturamento", null);
    },
    abreNovaFormaCobranca() {
      this.$store.commit("gateway", {});
      this.openDialogNovaFormaCobranca = true;
    },
    abreNovaFormaRecebimento() {
      this.$store.commit("gateway", {});
      this.$store.commit("openDialogNovaFormaRecebimento", true);
      this.$store.commit("isNewFormaPagar", true);
    },
    abreNovaRegua() {
      this.$store.commit("valueQuando", { isNew: true });
      this.$store.dispatch("listarTemplate");
      this.$store.commit("OpenDialogNovaRegua", true);
    },
    abreNovaConta() {
      this.$store.commit("OpenDialogNovaConta", true);
      this.$store.commit("newConta", {
        agencia: "",
        operacao: "",
        conta: "",
        tipo: "",
        isNew: true,
        id: null,
        titulo: "",
        ativo: true,
        padrao_sangria: false,
        padrao_reforco: false
      });
    },
    abreNovaLoteria() {
      this.$store.dispatch("listarCategorias");
      this.$store.commit("errorsLoterias", []);
      this.$store.commit("isNewLoteria", true);
      this.$store.commit("OpenDialogNovaLoteria", true);
    },
    abreNovoServico() {
      this.$store.dispatch("listarCategorias");
      this.$store.commit("errorsServicos", []);
      this.$store.commit("isNewServico", true);
      this.$store.commit("OpenDialogNovoServico", true);
    },
    abreNovoProduto() {
      this.$store.dispatch("listarCategorias");
      this.$store.commit("errorsProdutos", []);
      this.$store.commit("isNewProduto", true);
      this.$store.commit("OpenDialogNovoProduto", true);
    },
    abreNovaCategoria() {
      this.$store.commit("errorsCategorias", []);
      this.$store.commit("isNewCategoria", true);
      this.$store.commit("OpenDialogNovaCategoria", true);
    },
    abreNovoPlanoContas() {
      this.$store.commit("OpenDialogNovoPlanoContas", true);
    },
    goToArquivos() {
      this.$router.push({ name: "arquivos" });
    },
  }
};
</script>

<style lang="stylus">
.q-tab__label {
  font-size: 14px;
  line-height: 1.718em;
  font-weight: 500;
  margin-left: 13px;
}
</style>
