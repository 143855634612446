<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated primary class="text-white" id="headerPrincipal">
      <div class="toolbar" v-if="hasCobranca">
        <q-toolbar :class="avisoCobrancaClasses">
          <q-toolbar-title class="text-center q-mb-md">
            <a :href="cobrancaVencida.urlBoleto" target="_blank" class="text-body1" @click="atualizaAviso">
              {{ avisoCobrancaTexto }}
              <q-btn
                icon="fa fa-external-link"
                size="sm"
                flat
                round
              />
            </a>
          </q-toolbar-title>
        </q-toolbar>
      </div>
      <div class="toolbar" v-if="!online">
        <q-toolbar class="bg-red">
          <q-toolbar-title class="text-center q-mb-md">
            <q-icon name="wifi_off" size="sm" class="q-mr-sm" />
            <span class="text-body1">Você está offline. Verifique sua conexão com a internet.</span>
          </q-toolbar-title>
        </q-toolbar>
      </div>
      <q-toolbar class="bg-green" v-if="registered && (updatefound || offline)">
        <!-- registered && (updatefound || offline) -->
        <div class="absolute-center">
          <q-btn
            flat
            v-ripple
            no-wrap
            class="text-green bg-white"
            label="Novo conteúdo disponível"
            no-caps
            clickable
            @click="atualizaPagina"
            icon-right="refresh"
            dense
          />
        </div>
      </q-toolbar>
      <q-toolbar class="GNL__toolbar">
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
          class="q-mr-sm"
        />

        <q-toolbar-title shrink class="row items-left no-wrap justify-start">
          <router-link to="/visao360">
            <img :width="!this.$q.platform.is.mobile ? 135 : 100 " src="@/assets/douranovobranco.png" />
          </router-link>

          <!-- <span class="q-ml-sm">AGIL Online</span> -->
        </q-toolbar-title>
        <q-btn
          v-if="$q.screen.gt.xs"
          dense
          no-wrap
          v-ripple
          color="primary"
          icon="add"
          no-caps
          label
          class="q-ml-sm q-px-md bg-white text-blue-7"
          style="margin-right: 20px"
          id="btn-add"
        >
          <q-menu anchor="top right" self="top right" >
            <q-list class="text-grey-8" style="min-width: 100px">
              <q-item
                v-for="menu in createMenu"
                :key="menu.text"
                clickable
                v-close-popup
                aria-hidden="true"
                :id="`btn-add-${menu.text}`"
                @click="abreNovo(menu.text)"
              >
                <q-item-section>{{ menu.text }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>

        <search-global v-if="$q.screen.gt.xs"></search-global>

        <q-space />

        <img v-if="dataEmpresa.logo_url && $q.platform.is.desktop" height="45" :src="dataEmpresa.logo_url" class="q-mr-md" />

        <div class="q-gutter-sm row items-center no-wrap">
          <q-btn flat color="text-grey-8" :size="this.$q.platform.is.mobile ? 'sm' : 'md' " :label="dataEmpresa.fantasia" @click="getAllEmpresas()">
            <q-tooltip>Empresa</q-tooltip>
            <q-menu anchor="top left" self="top left">
              <q-list style="width:300px">
                <q-item>
                    <q-input
                    label="Buscar empresa:"
                    style="width:100%"
                    v-model="search"
                    clearable
                    debounce="500"
                    @input="filterEmpresa"
                     v-on:keyup.enter="filterEmpresa"
                  />
                </q-item>
                <q-item aria-hidden="true">
                  <q-item-section
                    class="text-uppercase text-grey-7"
                    style="font-size: 0.7rem"
                    >Empresas Vinculadas</q-item-section
                  >
                </q-item>
                <q-item class="active_status" v-if="dataEmpresa && dataEmpresa.id">
                  <q-item-section>{{ dataEmpresa.fantasia }}</q-item-section>
                </q-item>
                <q-item
                  v-for="empresa in limitItemEmpresas(empresas)"
                  :key="empresa.id + empresa.fantasia"
                  clickable
                  @click.exact="changeCompany(empresa)"
                  @click.ctrl.exact="changeMultiplo(empresa)"
                >
                  <q-item-section>{{ empresa.fantasia }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

          <q-btn round flat id="btn-menu">
            <q-avatar size="32px" color="grey text-wite" id="menuPrincipal">
              <img v-if="dataUser.img" :src="dataUser.img" />
              <span v-else>{{ sigla() }}</span>
            </q-avatar>
            <q-tooltip>Conta</q-tooltip>
            <q-menu>
              <div class="row no-wrap q-pa-md">
                <!-- <div class="column">
                  <div class="text-h6 q-mb-md">Opções</div>
                  <q-item color="orange-7 text-white" clickable @click="openPerfil()">
                    <q-item-section side>
                      <q-icon name="person" />
                    </q-item-section>
                    <q-item-section>Meu Perfil</q-item-section>
                  </q-item>
                  <q-item color="orange-7 text-white" clickable @click="openParams()">
                    <q-item-section side>
                      <q-icon name="settings" />
                    </q-item-section>
                    <q-item-section>Parâmetros</q-item-section>
                  </q-item>
                </div>

                <q-separator vertical inset class="q-mx-lg" />-->

                <div
                  class="column items-center"
                  style="position: relative; top: -10px"
                >
                  <div class="text-subtitle1 q-mt-md q-mb-xs text-left">
                    {{ dataUser.nome }}
                  </div>
                </div>
              </div>
              <div class="row">
                <q-separator />
              </div>
              <div class="row">
                <q-list style="width: 100%">
                  <q-item clickable @click="openPerfil()" id="btn-perfil">
                    <q-item-section side>
                      <q-icon name="person" />
                    </q-item-section>
                    <q-item-section>Meu Perfil</q-item-section>
                  </q-item>
                  <q-separator />
                  <q-item
                  clickable
                  @click="abrirAreaCliente()"
                  id="btn-area-cliente"
                >
                  <q-item-section avatar v-if="loadingAbrirAreaCliente">
                    <q-spinner color="blue-7" size="1.5em" />
                  </q-item-section>
                  <q-item-section side>
                      <q-icon name="account_circle" />
                  </q-item-section>
                  <q-item-section>
                    Área do Cliente
                  </q-item-section>
                </q-item>

                <q-item
                  to="/loja"
                  clickable                  
                  id="btn-loja"
                >
                  <q-item-section side>
                    <q-icon name="apps" />
                  </q-item-section>
                  <q-item-section>Aplicativos</q-item-section>
                </q-item>

                <q-item
                  @click="abrirAtualizacoes()"
                  clickable
                  id="btn-atualizacoes"
                >
                  <q-item-section side>
                    <q-icon name="notifications" />
                  </q-item-section>
                  <q-item-section>Atualizações</q-item-section>
                </q-item>

                <q-item
                  clickable
                  @click="abrirMovideskChat()"
                  id="btn-chat"
                >
                  <q-item-section side>
                    <q-icon name="chat" />
                  </q-item-section>
                  <q-item-section>Suporte | Chat</q-item-section>
                </q-item>

                <q-item
                  clickable
                  @click="abrirMovideskTicket()"
                  id="btn-ticket"
                >
                  <q-item-section avatar v-if="loadingAbrirMovdesk">
                    <q-spinner color="blue-7" size="1.5em" />
                  </q-item-section>
                  <q-item-section side>
                    <q-icon name="confirmation_number" />
                  </q-item-section>
                  <q-item-section>Suporte | Ticket</q-item-section>
                </q-item>

                <q-item
                  to="/parametros"
                  clickable
                  id="btn-parametros"
                >
                  <q-item-section side>
                    <q-icon name="settings" />
                  </q-item-section>
                  <q-item-section>Parâmetros</q-item-section>
                </q-item>
                  <q-separator />
                  <q-item
                    class="bg-primary text-white"
                    clickable
                    @click="logout()"
                    id="btn-sair"
                  >
                    <q-item-section side class="text-white">
                      <q-icon name="exit_to_app" />
                    </q-item-section>
                    <q-item-section>Sair</q-item-section>
                  </q-item>
                </q-list>
                <!-- <div class="column items-center">
                  <q-btn
                    style="margin-bottom: 10px"
                    flat
                    label="Meu Perfil"
                    size="sm"
                    @click="openPerfil()"
                    v-close-popup
                  />
                  <q-btn color="primary" label="Sair" size="sm" @click="logout" v-close-popup />
                </div>-->
              </div>
            </q-menu>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <!-- bordered -->
    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      content-class="bg-primary text-white shadow-6"
      :width="250"
      :breakpoint="500"
      :mini="miniState && !fixSidebar"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
      :mini-to-overlay="!fixSidebar"
      id="drawerPrincipal"
    >
      <q-scroll-area class="fit">
        <q-list padding class="text-white">
          <q-item
            v-model="expanded"
            :to="link.route !== '/meuslinks' ? link.route : ''"
            :active-class="
              link.enable && link.subitems.length === 0 && link.route !== '/meuslinks' ? 'orange' : ''
            "
            :class="{
              'text-white': true,
              'q-px-none': link.subitems.length > 0,
            }"
            v-ripple
            v-for="link in menu()"
            :key="link.label"
            :disable="link.enable ? false : true"
            :id="getId(link.label)"
            clickable
          >
            <q-item-section avatar v-if="link.label !== 'Meus Links' && link.subitems.length === 0">
              <q-icon :name="link.icon" />
            </q-item-section>
            <q-item-section v-if="link.label !== 'Meus Links' && link.subitems.length === 0">
              <q-item-label>{{ link.label }}</q-item-label>
            </q-item-section>
            <q-item-section v-if="link.isNew">
              <span class="GNL__isNew">Novo</span>
            </q-item-section>
            <q-expansion-item
              v-if="link.label === 'Meus Links'"
              v-model="expanded"
              :icon="link.icon"
              expand-icon-class="text-white on-right"
              :label="link.label"
              dense
              icon-dense
              header-class="accordion"
              style="width: 100%"
            >
              <q-list
                dense
                v-if="link.label === 'Meus Links'"
                class="accordion_list"
              >
                <q-item
                  class="accordion_item"
                  tag="a"
                  :href="sub.url"
                  target="_blank"
                  v-for="(sub, i) in atalhosUser"
                  :key="i + sub"
                >
                  <q-item-section avatar>
                    <q-icon :name="sub.icon" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="accordion_item">{{
                      sub.titulo
                    }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
            <q-expansion-item
              v-if="link.subitems.length > 0"
              v-model="expandedItems[link.label]"
              :icon="link.icon"
              expand-icon-class="text-white on-right"
              :label="link.label"
              dense
              icon-dense
              style="width: 100%"
              header-class="accordion q-px-md"
            >
              <q-list dense class="accordion_list">
                <q-item
                  class="accordion_item q-pl-xl q-py-sm"
                  v-for="(sub, i) in link.subitems"
                  :to="sub.route"
                  :key="i + sub"
                  :id="getId(sub.label)"
                  active-class="orange"
                >
                  <q-item-section avatar>
                    <q-icon :name="sub.icon" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="accordion_item">{{ sub.label }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
          </q-item>
          <!-- Espaçamento do menu fixo da sidebar -->
          <q-item style="height: 75px;"></q-item>
        </q-list>
      </q-scroll-area>
      <div class="q-mini-drawer-hide absolute" style="top: 15px; right: -15px">
        <q-btn
          dense
          round
          unelevated
          :class="{ 'bg-grey rotate-45': !fixSidebar, 'bg-secondary': fixSidebar, }"
          icon="push_pin"
          @click="menuFixedStatus()"
        />
      </div>
      <div
        v-show="!miniState || fixSidebar"
        class="absolute"
        style="bottom: 5px; padding: 5px; width: 100%; overflow: hidden"
      >
        <div class="flex flex-center q-gutter-xs">
          <!-- <a
            class="GNL__drawer-footer-link"
            href="javascript:void(0)"
            aria-label="Privacy"
          >Privacidade</a>
          <span>·</span>-->
          <a
            class="GNL__drawer-footer-link"
            href="https://dourasoft.com.br/termos-de-uso/"
            aria-label="Terms"
            >Termos</a
          >
          <span>·</span>
          <a
            class="GNL__drawer-footer-link"
            href="https://dourasoft.com.br/sobre-a-dourasoft/"
            aria-label="About"
            >Sobre a DouraSoft</a
          >
        </div>
        <q-badge
          color="white"
          style="width: 100%"
          class="shadow-1 text-blue-7"
          text-color="white"
          v-html="versao()"
        ></q-badge>
      </div>
    </q-drawer>

    <q-page-container>
      <router-view />
      <q-page-sticky
        v-if=" $q.screen.lt.sm && $route.name !== 'resumo_tfl' "
        position="bottom-right"
        :offset="[22, 90]"
      >
        <q-fab
          direction="up"
          color="primary"
          icon="keyboard_arrow_up"
          class="add_arrow_btn"
          persistent
          padding="none none sm none"
        >
          <q-fab-action
            color="white text-black"
            icon="search"
            clickable
            @click="OpenDialogBuscaGlobal = true"
          />
          <q-fab-action
            v-for="menu in createMenu"
            :key="menu.text"
            clickable
            @click="abreNovo(menu.text)"
            color="white text-black"
            :icon="menu.icon"
          />
        </q-fab>
      </q-page-sticky>
    </q-page-container>

    <q-dialog
      v-model="OpenDialogBuscaGlobal"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      id="searchGobal"
    >
      <q-card class="bg-white text-black">
        <q-bar class="bg-primary text-white">
          <div>Busca Global</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip content-class="bg-white text-black">Fechar</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section>
          <search-global @searchFinished="OpenDialogBuscaGlobal = false" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <div
    v-if="exibirChatMovidesk"
     style="bottom: 20px; right: 20px;" class="fixed inline-block">
      <q-btn
        id="movideskChat"
        round
        color="green"
        style="width: 60px; height: 60px;"
        @click="abrirMovideskChat()"
      >
        <q-icon
          size="32px"
          name="help"
          color="white"
        />
      </q-btn>
    </div>

    <novo-cadastro ref="novoCadastro"></novo-cadastro>
    <modal-nova-despesa></modal-nova-despesa>
    <modal-nova-receita></modal-nova-receita>
    <modal-novo-link></modal-novo-link>
    <modal-atualizacoes />
    <modal-upgrade-plano/>
    <modal-manuais />
  </q-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify } from "quasar";
import axios from "@/axios-auth";
import { bus } from "@/main";
import modalUpgradePlano from '../components/Utils/modal-upgrade-plano.vue';

export default {
  components: { modalUpgradePlano },
  name: "Layout_Default",
  // components: {
  //   parametros
  // },
  data() {
    return {
      OpenDialogBuscaGlobal: false,
      maximizedToggle: true,
      leftDrawerOpen: false,
      search: "",
      miniState: true,
      dialog: false,
      expanded: false,
      createMenu: [
        { icon: "person_add", text: "Pessoas" },
        { icon: "trending_up", text: "Receitas" },
        { icon: "trending_down", text: "Despesas" },
        { icon: "public", text: "Meus Links" }
      ],
      fixSidebar: false,
      empresas: [],
      limitEmpresas: 6,
      expandedItems: {
        Financeiro: false,
      },
      loadingAbrirAreaCliente: false,
      loadingAbrirMovdesk: false,
      popupMovdesk: null,
      online: navigator.onLine,
    };
  },
  methods: {
    limitItemEmpresas(empresas){
      // Garantir que não repitada a empresa selecionada durante a listagem do filtro
      if(this.dataEmpresa && this.dataEmpresa.id){
        let pos = empresas.find((item)=> {
          if(item.id === this.dataEmpresa.id){
            return item;
          }
        });

        if(pos){
          empresas.splice(empresas.indexOf(pos),1);
        }
      }
      return empresas.slice(0, this.limitEmpresas);
    },
    filterEmpresa(){
      const params = {
        q: this.search,
      };

      axios
        .get('/empresas',{ params })
        .then(res => {
          if (res.data.success) {
            this.empresas = res.data.empresas;
          }
        })
        .catch(() => {});
    },
    menuFixedStatus() {
      let convert = String(!this.fixSidebar);
      let menu = {
        action: "menuFixed",
        valor: convert
      };

      localStorage.setItem("menuFixed", convert);
      this.fixSidebar = JSON.parse(localStorage.getItem("menuFixed"));

      axios
        .post("/users/perfil", menu)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: "Não foi possivel fixar/desfixar menu !",
              color: "orange",
              position: "top-right"
            });
          }
        })
        .catch(error => {
          Notify.create({
            message:
              "Não foi possível realizar processo, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    atualizaAviso() {
      axios
        .post("/users/perfil", { action: "viu_cobranca"})
        .then(res => {
          this.$store.commit("setViuCobranca", res.data.success);
        });
    },
    atualizaPagina() {
      location.reload(true);
    },
    changeMultiplo(emp) {
      this.$store.dispatch("changeMultiplo", emp);
    },
    changeCompany(emp) {
      this.$store.dispatch("changeCompany", emp);
    },
    openPerfil() {
      this.$router.push("/perfil");
    },
    logout() {
      this.$store.dispatch("logout");
    },
    alwaysOn() {
      let dados = {
        path: this.$route.path,
        name: this.$route.name
      };
      this.$store.dispatch("alwaysOn", dados);
    },
    versao() {
      if (this.developmentMode) {
        return (
          "dev-mode|<br>" +
          process.env.VUE_APP_URL_API +
          "|<br>" +
          process.env.VUE_APP_VERSION
        );
      }

      return process.env.VUE_APP_VERSION;
    },
    // openParams() {
    //   this.dialog2 = true;
    // },
    abreNovo(opcao) {
      if (opcao === "Pessoas") {
        this.$store.dispatch("cadastro", { noSearch: true, novo: true });
      } else if (opcao === "Despesas") {
        this.$store.commit("callDespesa", true);
        this.$store.dispatch("planoContas");
        this.$store.dispatch("contas");
        this.$store.commit("OpenDialogNovaDespesa", true);
      } else if (opcao === "Receitas") {
        this.$store.dispatch("modalReceitas");
        this.$store.dispatch("contas");
      } else if (opcao === "Meus Links") {
        this.$store.commit("OpenDialogNovoLink", true);
        this.$store.commit("isPerfil", false);
      }
    },
    sigla() {
      let user = JSON.parse(JSON.stringify(this.dataUser));
      let nome = String(user.nome);
      let nomeArray = nome.split(" ");

      return nomeArray[0][0] + nomeArray[nomeArray.length - 1][0];
    },
    menu(){
      let empresa = this.dataEmpresa.id;
      return  !empresa ? [] : JSON.parse(localStorage.getItem(`menu${empresa}`));
    },
    getAllEmpresas(){
      axios
        .get('/empresas')
        .then(res => {
          if (res.data.success) {
            this.empresas = res.data.empresas;
          }
        })
        .catch(() => {});
    },
    openLink(link) {
      const links = {
        consultaCnpj: "https://docs.google.com/forms/d/e/1FAIpQLSd4A6bn4ppx69kJI3TekZvxaIHlt93-xzdQeeI3NC7Dk8lVRA/viewform",
        mensagensSms: "https://docs.google.com/forms/d/e/1FAIpQLSdrbF7q3os9hwWOBD2vNN5-40H9KdzkJACvl-IgfxJNCI7LYw/viewform",
      };

      window.open(links[link], '_blank');
    },
    abrirMenus() {
      const financeiroRotas = ['despesas', 'receitas', 'extrato'];

      if (financeiroRotas.indexOf(this.$route.name) !== -1) {
        this.expandedItems['Financeiro'] = true;
      }
    },
    abrirAtualizacoes() {
      bus.$emit("abrirModalAtualizacoes");
    },
    abrirAreaCliente() {
      if (this.loadingAbrirAreaCliente) return;

      this.loadingAbrirAreaCliente = true;

      axios
        .post('/empresas/area-cliente-dourasoft')
        .then(res => {
          this.loadingAbrirAreaCliente = false;

          if (res.error && res.error.response.status === 403) {
            this.$q.notify({
              message: res.error.response.data.message,
              color: 'red',
              position: 'top-right',
            });
            return;
          }

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.$store.commit("urlAreaCliente", res.data.url);
          this.$router.push("/cliente-dourasoft");
        })
        .catch(() => {
          this.loadingAbrirAreaCliente = false;
          this.$q.notify({
            message: 'Houve um erro ao acessar a área do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    abrirMovideskTicket() {
      this.loadingAbrirMovdesk = true;
      axios
        .post('/empresas/link-movidesk')
        .then(res => {
          this.loadingAbrirMovdesk = false;
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          window.open(res.data.url);
        })
        .catch(() => {
          this.loadingAbrirMovdesk = false;
          this.$q.notify({
            message: 'Houve um erro ao acessar a área do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    async abrirMovideskChat() {
      this.getUrlAjuda();
    },
    getUrlAjuda(){
      axios
      .get('/users/perfil?site-ajuda=1')
      .then((res) => {
        this.openWindow(res.data.url);
        return;
      })
      .catch(() => {
        this.openWindow("https://ajuda.dourasoft.com.br");
      });
    },
    openWindow(url) {
      return window.open(url, '_blank');
    },
    embedProduktly(w, d, f) {
      const a = d.getElementsByTagName('head')[0];
      const s = d.createElement('script');
      s.async = 1;
      s.src = f;
      s.setAttribute('id', 'produktlyScript');
      s.dataset.clientToken = "432bc81132b19cec591b5ad586aa3931dc9f0f0992b9a31e44d6f4467207afa54968a424a1b54b989fdd0eb3ab27cf612120c9e55e53e065e666d166c33b91637c41be9beb576c288a3414cca8e4bd0ef98d8316292613f77f63aee06af9d10182afd271";
      a.appendChild(s);

      this.identifyProduktlyUser();
    },
    identifyProduktlyUser() {
      setTimeout(() => {
        window.Produktly.identifyUser(this.dataUser.id, {
          segmento: this.dataEmpresa.assinatura.segmento,
          plano: this.dataEmpresa.assinatura.plano,
          cnpj: this.dataEmpresa.cnpj,
          empresa_id: this.dataEmpresa.id
        });
      }, 3000);
    },
    getId(label) {
      const withoutAccents = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const cleanedStr = withoutAccents.replace(/[^a-zA-Z\s]/g, '');
      const words = cleanedStr.trim().split(/\s+/);

      return words.map((word, index) => {
        return index === 0 
          ? word.toLowerCase() 
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join('') + 'Menu';
    }
  },
  computed: {
    ...mapGetters([
      "dataUser",
      "atalhosUser",
      "buscandoUser",
      "usersApp",
      "dataEmpresa",
      "registered",
      "updatefound",
      "offline",
      "acessibilidade",
      "viuCobranca",
      "cobrancaVencida",
      "avisoCobrancaTexto",
      "avisoCobrancaClasses"
    ]),
    developmentMode() {
      return process.env.NODE_ENV === "development";
    },
    exibirChatMovidesk(){
      if (!this.$q.screen.lt.sm) return true;

      return (this.$route.name !== "resumo_tfl");
    },
    hasCobranca() {
      return !this.viuCobranca && this.cobrancaVencida && Object.keys(this.cobrancaVencida).length > 0;
    }
  },
  mounted() {
    if (!this.$store.getters.token) {
      this.alwaysOn();
    }

    this.fixSidebar = localStorage.getItem("menuFixed") === "true";
    this.getAllEmpresas();
    this.$store.dispatch("loadAcessibilidade");
    this.embedProduktly(window, document, "https://public.produktly.com/js/main.js");

    window.addEventListener("online", () => this.online = true);
    window.addEventListener("offline", () => this.online = false);
  },
  created() {
    this.abrirMenus();
  },
};
</script>

<style lang="stylus">
$secondary = #FBBC05;

.GNL {
  &__toolbar {
    height: 64px;
  }

  &__isNew{
     background-color: #FBBC05;
     text-align:center;
     border-radius:3px;
     width:60px;
  }

  &__toolbar-input {
    width: 55%;
    background-color: white;
    border-radius: 50px;
  }

  &__drawer-item {
    line-height: 24px;
    border-radius: 0 24px 24px 0;
    margin-right: 12px;

    .q-item__section--avatar {
      .q-icon {
        color: #5f6368;
      }
    }

    .q-item__label {
      color: #3c4043;
      letter-spacing: 0.01785714em;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }

  &__drawer-footer-link {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.75rem;

    &:hover {
      color: #000;
    }
  }
}

.orange {
  background-color: #FBBC05;

  .q-item {
    &__label {
      color: white;
    }

    &__section {
      .material-icons {
        color: white;
      }
    }
  }
}

// .q-expansion-item
// .q-expansion-item__container
// .q-item
// padding 0px
// &:hover
// backgorund-color inherit !important
.accordion {
  background-color: transparent;
  padding: 0px !important;

  &:hover {
    background-color: inherit !important;
  }

  .q-focus-helper {
    opacity: 0 !important;
  }
}

.accordion_list {
  .q-focus-helper {
    opacity: 0 !important;
  }
}

.accordion_item {
  &:hover {
    color: $secondary;
  }
}

.orange {
  .accordion_item {
    &:hover {
      color: #fff;
    }
  }
}

.link-item{
  text-decoration none;
  display:flex;
  color:#000;
}

.accordion.q-px-md  {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.accordion_item.q-pl-xl  {
  padding-left: 32px !important;
}

.accordion_item.q-py-sm  {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.add-action-button,
.add-action-button > button,
.add-action-button > button > .q-btn__wrapper > .q-btn__content {
  width: 60px;
  height: 60px;
}

.add-action-button > button > .q-btn__wrapper > .q-btn__content > i.q-fab__icon  {
  top: 18px;
}

.add-action-button > button > .q-btn__wrapper > .q-btn__content > i.q-fab__active-icon  {
  top: -12px;
}

.aumentar_tamanho_fonte_tabelas {
  .q-table th, .q-table td {
    font-size: 15px;
  }
}

table.q-table th, .q-table td {
  padding: 6px 4px;
}

.q-uploader input[type=file] {
  display: none;
}
</style>

<style>
  .add_arrow_btn {
    width: 60px;
    height: 60px;
    text-align: center;
  }

  .q-fab__icon-holder {
    min-width: 24px;
    min-height: 24px;
    position: relative;
    top: 15px;
    left: -48px;
  }

  .q-fab__icon {
    position: relative !important;
    top: -10px !important;
    left: 48px !important;
  }

  .q-btn--fab .q-icon, .q-btn--fab-mini .q-icon {
    font-size: 24px;
    width: 100%;
    height: 50%;
  }

  .toolbar {
    height: 35px;
    overflow: hidden;
  }

  .toolbar a {
    text-decoration: none;
  }

  .toolbar-text-white a {
    color: #fff;
  }

  .toolbar-text-black a {
    color: #000;
  }

  .toolbar-text-blue a {
    color: #4285F4;
  }
</style>
